import { useEdgeController } from '~hooks/useEdgeControllerList';

import { useControllersNavigation } from '~pages/v2/controllers/hooks/useControllersNavigation';

import { HierarchyController } from '~components/hierarchy/HierarchyController';
import { HierarchyLoading } from '~components/hierarchy/HierarchyLoading';

export function ControllerHierarchy() {
  const { edgeControllerId } = useControllersNavigation();
  const { data: edgeController, isLoading } = useEdgeController(edgeControllerId);

  if (isLoading) return <HierarchyLoading />;
  if (!edgeController) return null;

  return <HierarchyController isRoot edgeController={edgeController} />;
}
