import { Outlet } from 'react-router-dom';
import { useMemo } from 'react';

import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useFleetList } from '~hooks/useFleets';

import { TABS } from '~pages/v2/fleets/constants';
import { useFleetsNavigation } from '~pages/v2/fleets/hooks/useFleetsNavigation';
import { createFleetPath } from '~pages/v2/fleets/utils/createFleetPath';

import { Tabs } from '~components/Tabs';

export function Fleet() {
  const { fleetId, tab } = useFleetsNavigation();
  const { data: fleets } = useFleetList();

  const fleet = useMemo(() => fleets?.find((fleet) => fleet.id === fleetId), [fleets, fleetId]);

  useRegisterBreadcrumbs('fleets', fleet?.name ?? '', createFleetPath(fleetId));

  let visibleTabs: Partial<typeof TABS> = TABS;
  if (fleetId !== 'cff-btm') {
    visibleTabs = {
      dashboard: TABS.dashboard,
      market: TABS.market,
    };
  }

  return (
    <>
      <Tabs tabs={visibleTabs} tab={tab} pathname={createFleetPath(fleetId)} />
      <Outlet />
    </>
  );
}
