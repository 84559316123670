import { useMemo } from 'react';

import { type DateTimeRange } from '~utils/time';

import { createRangeQuery, useTimeseriesRange } from '~hooks/timeseries/queries';
import { type ResourceID } from '~hooks/timeseries/types';

export function useESUSchedule(resourceId: ResourceID | null, range: DateTimeRange) {
  const { data, isLoading, error } = useTimeseriesRange(
    createRangeQuery({
      resources: resourceId != null ? [resourceId] : [],
      columns: {
        schedule_history: ['min_target_soc', 'max_target_soc', 'peak_shaving_threshold'],
      },
      start: range.start,
      end: range.end,
      fillStart: true,
      fillEnd: true,
    }),
  );

  const scheduleHistory = resourceId != null ? data?.[resourceId].schedule_history ?? null : null;

  const extendedHistory = useMemo(() => {
    if (scheduleHistory == null || scheduleHistory.length === 0) {
      return scheduleHistory;
    }
    const now = new Date();
    const lastTime = new Date(scheduleHistory.at(-1)!.time);
    if (lastTime >= range.end || lastTime >= now) {
      return scheduleHistory;
    }
    // assume the last configuration is still valid, add a data point to make the line extend to the end
    const timeStamp = Math.min(range.end.getTime(), now.getTime());
    return [
      ...scheduleHistory,
      {
        ...scheduleHistory.at(-1)!,
        time: new Date(timeStamp).toISOString(),
      },
    ];
  }, [scheduleHistory, range.end]);

  return {
    data: scheduleHistory,
    extendedData: extendedHistory,
    isLoading,
    error,
  };
}
