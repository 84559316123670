import * as Sentry from '@sentry/react';

export function configureSentry() {
  Sentry.init({
    enabled: import.meta.env.PROD,
    environment: import.meta.env.VITE_SENTRY_ENV,
    dsn: 'https://fd62756f8bcf43b26b89d355981a5757@o4503998830477312.ingest.us.sentry.io/4507253981184000',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://app.cactos.fi', 'https://app.cactos.com'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
