import { IconButton, Popover, useTheme } from '@mui/material';
import { type MouseEvent, useCallback, useMemo, useState } from 'react';

import { useBreadcrumbs } from '~hooks/useBreadcrumbs';

import { OrganizationHierarchy } from '~pages/v2/organizations/components/OrganizationHierarchy';
import { SiteHierarchy } from '~pages/v2/sites/components/SiteHierarchy';
import { ControllerHierarchy } from '~pages/v2/controllers/components/ControllerHierarchy';

import { Iconify } from '~components/Iconify';

export function BreadcrumbsNavigation() {
  const theme = useTheme();
  const { breadcrumbs } = useBreadcrumbs();
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const HierarchyComponent = useMemo(() => {
    switch (breadcrumbs[breadcrumbs.length - 1]?.type) {
      case 'organizations':
        return OrganizationHierarchy;
      case 'sites':
        return SiteHierarchy;
      case 'controllers':
        return ControllerHierarchy;
      default:
        return null;
    }
  }, [breadcrumbs]);

  if (!HierarchyComponent) return null;

  return (
    <>
      <IconButton
        size="small"
        title="Hierarchy"
        onClick={handleOpen}
        sx={{ color: theme.palette.text.secondary }}
      >
        <Iconify icon="mdi:dots-horizontal" width={20} height={20} />
      </IconButton>
      <Popover
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <HierarchyComponent />
      </Popover>
    </>
  );
}
