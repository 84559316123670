import { Link } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import type { ESUDetails } from '~types';

import { useIsRoutePermitted } from '~hooks/useIsRoutePermitted';

import { createESUPath } from '~pages/v2/esus/utils/createESUPath';

import { HierarchyIcon } from '~components/hierarchy/HierarchyIcon';

type Props = {
  esu: ESUDetails;
  paddingLeft?: number;
};

export function HierarchyESU({ esu, paddingLeft = 2 }: Props) {
  const isRoutePermitted = useIsRoutePermitted(createESUPath(esu.id));

  if (!isRoutePermitted) return null;

  return (
    <List disablePadding>
      <Link to={createESUPath(esu.id)} style={{ color: 'inherit', textDecoration: 'none' }}>
        <ListItemButton sx={{ padding: 0, paddingLeft, paddingRight: 2 }}>
          <ListItemIcon>
            <HierarchyIcon breadcrumbType="esus" />
          </ListItemIcon>
          <ListItemText primary={esu.name} />
        </ListItemButton>
      </Link>
    </List>
  );
}
