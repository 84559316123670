import useSWR, { preload } from 'swr';
import { useMemo } from 'react';

import type { FetchHookData, Organization } from '~types';

import { fetcher } from '~http';

import { collator } from '~utils/localization';

export async function preloadOrganizationList() {
  try {
    return await preload('/v1/organization', fetcher);
  } catch {
    return null;
  }
}

export function useOrganizationList(): FetchHookData<Organization[]> {
  const { data, error, isLoading, mutate } = useSWR<Organization[]>('/v1/organization', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const sortedData = useMemo(
    () =>
      data != null
        ? [...data].sort((a, b) => collator.compare(a.human_name, b.human_name))
        : undefined,
    [data],
  );

  return {
    data: sortedData,
    error,
    isLoading,
    mutate,
  };
}
