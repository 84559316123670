import { addMinutes, differenceInMinutes, subMinutes } from 'date-fns';

import { type DateTimeRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';

import {
  createAggregateQuery,
  createRangeQuery,
  useTimeseriesAggregate,
  useTimeseriesRange,
} from '~hooks/timeseries/queries';
import type { ResourceID } from '~hooks/timeseries';

export function useEnergyMeterMomentaryData(resourceId: string | null, range: DateTimeRange) {
  const shouldAggregateOverTime = differenceInMinutes(range.end, range.start) > 120;
  const aggregateTimeStepSeconds = getAggregageQueryTimeStepSeconds(range);

  const resources = resourceId != null ? [resourceId as ResourceID] : [];
  const {
    data: aggregateData,
    isLoading: loadingAggregateData,
    error: aggregateDataError,
  } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: shouldAggregateOverTime ? resources : [],
      columns: {
        energy_meter_momentary: ['reactive_power:mean', 'reactive_power:min', 'reactive_power:max'],
      },
      start: range.start,
      end: range.end,
      step: `${aggregateTimeStepSeconds}s`,
    }),
  );

  const {
    data: rawData,
    isLoading: loadingRawData,
    error: rawDataError,
  } = useTimeseriesRange(
    createRangeQuery({
      resources: shouldAggregateOverTime ? [] : resources,
      columns: {
        energy_meter_momentary: ['reactive_power'],
      },
      start: range.start,
      end: range.end,
      fillStart: subMinutes(range.start, 1),
      fillEnd: addMinutes(range.end, 1),
    }),
  );

  if (shouldAggregateOverTime) {
    return {
      data: {
        isAggregatedOverTime: true,
        energy_meter_momentary:
          resourceId != null ? aggregateData?.[resourceId]?.energy_meter_momentary : null,
      },
      isLoading: loadingAggregateData,
      error: aggregateDataError,
    };
  } else {
    return {
      data: {
        isAggregatedOverTime: false,
        energy_meter_momentary:
          resourceId != null ? rawData?.[resourceId]?.energy_meter_momentary : null,
      },
      isLoading: loadingRawData,
      error: rawDataError,
    };
  }
}
