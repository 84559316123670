import type { PropsWithChildren, ReactNode } from 'react';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';

import { useDocumentTitle } from '~hooks/useDocumentTitle';

import {
  SIDEBAR_LOGO_HEIGHT,
  SIDEBAR_MOBILE_HEIGHT,
  SIDEBAR_WIDTH,
} from '~layouts/AppLayout/Sidebar';

import { Breadcrumbs } from '~components/breadcrumbs/Breadcrumbs';

type Props = PropsWithChildren<{
  title: string;
  actions?: ReactNode;
}>;

const Container = styled('div')(({ theme }) => ({
  maxWidth: '100vw',
  height: '100vh',
  overflowY: 'auto',
  [theme.breakpoints.up('lg')]: {
    maxWidth: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
  },
}));

const Header = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  position: 'sticky',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: SIDEBAR_MOBILE_HEIGHT + 1,
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.drawer - 1,
  [theme.breakpoints.up('lg')]: {
    minHeight: SIDEBAR_LOGO_HEIGHT + 1,
  },
}));

export function PageLayout({ title, actions = null, children }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const headerHeight = (isMobile ? SIDEBAR_MOBILE_HEIGHT : SIDEBAR_LOGO_HEIGHT) + 1; // 1px for the divider

  useDocumentTitle(title);

  return (
    <Container>
      <Header>
        <Breadcrumbs />
        {actions}
      </Header>
      <Box height={`calc(100vh - ${headerHeight}px)`}>{children}</Box>
    </Container>
  );
}
