import { type DateTimeRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';

import { createAggregateQuery, useTimeseriesAggregate } from '~hooks/timeseries/queries';

export function useSiteGridMeterData(meteringGroupId: string | null, range: DateTimeRange) {
  const timeStepSeconds = getAggregageQueryTimeStepSeconds(range);
  const { data, isLoading, error } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: meteringGroupId != null ? [`metering_group:${meteringGroupId}`] : [],
      columns: {
        grid_meter: [
          'grid_active_power:mean',
          'grid_active_power:min',
          'grid_active_power:max',
          'current_a:mean',
          'current_a:min',
          'current_a:max',
          'current_b:mean',
          'current_b:min',
          'current_b:max',
          'current_c:mean',
          'current_c:min',
          'current_c:max',
        ],
      },
      start: range.start,
      end: range.end,
      step: `${timeStepSeconds}s`,
    }),
  );
  return {
    data: data?.[`metering_group:${meteringGroupId}`].grid_meter,
    isLoading,
    error,
  };
}
