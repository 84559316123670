import { type CSSProperties, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { addHours } from 'date-fns';
import dayjs from 'dayjs';
import { Decimal } from 'decimal.js';

import type { ProductType } from '~types';

import { asCactosError } from '~http';

import { type DateTimeRange, currentTimeZone, formatDateTimeNoTimeZone } from '~utils/time';

import { useTimeseriesRange } from '~hooks/timeseries';
import { createRangeQuery } from '~hooks/timeseries/queries';
import { useMultiMarketSnapshot } from '~hooks/useMultiMarketSnapshot';

import { getTimeRange } from '~pages/v2/fleets/utils/getTimeRange';
import { parseFiniteFloat } from '~pages/v2/fleets/utils/parseFiniteFloat';
import { ALLOCATION_TZ, BIDDING_DOMAIN } from '~pages/v2/fleets/constants';
import { getNextDeliveryPeriodId } from '~pages/v2/fleets/utils/getNextDeliveryPeriodId';

type Props = {
  fleetId: string;
};

export function BidsAndAllocations({ fleetId }: Props) {
  const theme = useTheme();

  const [now, setNow] = useState<Date>(new Date());
  const [range, setRange] = useState<DateTimeRange>(getTimeRange());

  const rangeHours: Date[] = useMemo(() => {
    const result = [];
    let hour = range.start;

    while (hour < range.end) {
      result.push(hour);
      hour = addHours(hour, 1);
    }

    return result;
  }, [range.start, range.end]);

  const activeHourMs = rangeHours
    .filter((hour) => hour <= now)
    .at(-1)
    ?.getTime();

  const {
    data: rangeQueryResult,
    isLoading: loadingRangeQuery,
    error: rangeQueryError,
  } = useTimeseriesRange(
    createRangeQuery({
      resources: [`region:${BIDDING_DOMAIN}`],
      columns: {
        fingrid_fcr_n_market_price: ['value'],
      },
      start: range.start,
      end: range.end,
    }),
  );

  const [deliveryPeriodIdToday] = useState(() =>
    dayjs().tz(ALLOCATION_TZ).startOf('day').format('YYYY-MM-DD'),
  );
  const deliveryPeriodIdTomorrow = getNextDeliveryPeriodId(deliveryPeriodIdToday);
  const [observationTime] = useState(() => dayjs().tz('CET').startOf('second').toISOString());

  const { data: snapshotToday } = useMultiMarketSnapshot(
    deliveryPeriodIdToday,
    observationTime,
    fleetId,
  );
  const { data: snapshotTomorrow } = useMultiMarketSnapshot(
    deliveryPeriodIdTomorrow,
    observationTime,
    fleetId,
  );

  const allocationAndBidRows = useMemo(() => {
    if (snapshotToday == null || snapshotTomorrow == null) return [];
    const productType: ProductType = 'FI FCR-N';
    const bids = [
      ...(snapshotToday.data[productType]?.bids.data ?? []),
      ...(snapshotTomorrow.data[productType]?.bids.data ?? []),
    ];
    const allocations = [
      ...(snapshotToday.data[productType]?.allocations.data ?? []),
      ...(snapshotTomorrow.data[productType]?.allocations.data ?? []),
    ];

    const regionResult = rangeQueryResult?.[`region:${BIDDING_DOMAIN}`];

    const marketPricesByTime = new Map(
      (regionResult?.fingrid_fcr_n_market_price ?? []).map((row) => [
        new Date(row.time).toISOString(),
        row,
      ]),
    );

    return rangeHours.map((hour) => {
      const timestamp = hour.toISOString();
      const bid = bids.find((row) => new Date(row.start_time).toISOString() === timestamp);
      const allocation = allocations.find(
        (row) => new Date(row.start_time).toISOString() === timestamp,
      );

      const bidQuantityInMw = new Decimal(bid?.quantity_in_mw ?? NaN);
      const bidPricePerMwh = new Decimal(bid?.price_per_mwh ?? NaN);
      const allocationInMw = new Decimal(allocation?.allocation_in_kw ?? NaN).dividedBy(1000);
      const allocationPricePerMw = new Decimal(allocation?.price_per_kwh ?? NaN).times(1000);
      return {
        time: hour,
        bidQuantity: bidQuantityInMw,
        bidPrice: bidPricePerMwh,
        bidCurrency: bid?.currency ?? null,
        bidAcknowledgedAt: bid?.acknowledged_at ?? null,
        allocationQuantity: allocationInMw,
        allocationPrice: allocationPricePerMw,
        allocationCurrency: parseFiniteFloat(allocation?.price_per_kwh) ? 'EUR' : null,
        allocationReceivedAt: allocation?.received_at ?? null,
        marketPrice: parseFiniteFloat(marketPricesByTime.get(timestamp)?.value),
      };
    });
  }, [rangeHours, snapshotToday, snapshotTomorrow, rangeQueryResult]);

  const totalAllocationsByDay = allocationAndBidRows.reduce(
    (acc: { [day: string]: Decimal }, row) => {
      const value = row.allocationPrice.times(row.allocationQuantity);
      if (!value.isFinite()) return acc;
      const day = dayjs(row.time).tz(ALLOCATION_TZ).format('MMMM Do');
      const previousTotal = acc[day] ?? new Decimal(0);
      const total = previousTotal.add(value);
      return { ...acc, [day]: total };
    },
    {},
  );

  const tableCellRightBorderStyle: CSSProperties = {
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.grey[800],
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
      setRange(getTimeRange());
    }, 60_000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Card>
      <CardHeader
        title={
          <>
            Bids and allocations from {dayjs(range.start).tz(ALLOCATION_TZ).format('MMMM Do')} to{' '}
            {dayjs(range.end).tz(ALLOCATION_TZ).subtract(1, 'minute').format('MMMM Do')} (
            {ALLOCATION_TZ}){loadingRangeQuery && <CircularProgress size={16} sx={{ ml: 1 }} />}
          </>
        }
      />
      <CardContent sx={{ overflowX: 'auto' }}>
        {!!rangeQueryError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Error loading data: {asCactosError(rangeQueryError).message}
          </Alert>
        )}
        {Object.entries(totalAllocationsByDay).map(([day, value]) => (
          <div key={day}>
            {day} ({ALLOCATION_TZ}) allocations total value: {value.toFixed(2)} EUR
          </div>
        ))}
        <div>Times below are shown in timezone "{currentTimeZone()}".</div>
        <TableContainer sx={{ minWidth: 1100, pt: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={tableCellRightBorderStyle} />
                <TableCell sx={tableCellRightBorderStyle} align="center" colSpan={3}>
                  Bids
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  Allocations
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={tableCellRightBorderStyle}>Start time</TableCell>
                {/* bids */}
                <TableCell>Quantity</TableCell>
                <TableCell>Price</TableCell>
                <TableCell sx={tableCellRightBorderStyle}>Acknowledged at</TableCell>
                {/* allocations */}
                <TableCell>Quantity</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Received at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ minWidth: 1100 }}>
              {allocationAndBidRows.map((row) => (
                <TableRow
                  key={row.time.toISOString()}
                  tabIndex={-1}
                  sx={
                    row.time.getTime() === activeHourMs
                      ? { backgroundColor: theme.palette.action.hover }
                      : undefined
                  }
                >
                  <TableCell>{formatDateTimeNoTimeZone(row.time)}</TableCell>
                  <TableCell>{row.bidQuantity.isFinite() && row.bidQuantity.toString()}</TableCell>
                  <TableCell>
                    {row.bidPrice.isFinite() && `${row.bidPrice.toString()} ${row.bidCurrency}`}
                  </TableCell>
                  <TableCell>
                    {row.bidAcknowledgedAt != null ? (
                      formatDateTimeNoTimeZone(row.bidAcknowledgedAt)
                    ) : row.bidQuantity.isFinite() ? (
                      <span style={{ color: theme.palette.warning.light }}>N/A</span>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {row.allocationQuantity.isFinite() && row.allocationQuantity.toString()}
                  </TableCell>
                  <TableCell>
                    {row.allocationPrice.isFinite() &&
                      `${row.allocationPrice.toString()} ${row.allocationCurrency}`}
                  </TableCell>
                  <TableCell>
                    {formatDateTimeNoTimeZone(row.allocationReceivedAt ?? undefined)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
