import { useCallback, useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { useLocalStorage } from '~hooks/useLocalStorage';

import type { Tab } from '~pages/v2/fleets/types';
import { ROUTER_MATCH_PATH } from '~pages/v2/fleets/constants';
import { createFleetPath } from '~pages/v2/fleets/utils/createFleetPath';

const DEFAULT_TAB: Tab = 'dashboard';

export function useFleetsNavigation() {
  const navigate = useNavigate();
  const { fleetId = '' } = useParams();
  const params = useMatch(ROUTER_MATCH_PATH)?.params;

  const [tabFromStorage, setTabFromStorage] = useLocalStorage<Tab>('fleets.tab', DEFAULT_TAB);
  const activeTab = params?.tab as Tab;
  const tab: Tab = activeTab || tabFromStorage;

  const navigateToFleet = useCallback(
    (fleetId = '', tab: Tab = '') => {
      navigate(createFleetPath(fleetId, tab));
    },
    [navigate],
  );

  useEffect(() => {
    setTabFromStorage(tab);
  }, [tab, setTabFromStorage]);

  return {
    fleetId,
    activeTab,
    tab,
    navigateToFleet,
  };
}
