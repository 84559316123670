import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

import type { MeteringGroup } from '~types';

import { useEdgeControllerList } from '~hooks/useEdgeControllerList';
import { useIsRoutePermitted } from '~hooks/useIsRoutePermitted';

import { createSitePath } from '~pages/v2/sites/utils/createSitePath';

import { HierarchyIcon } from '~components/hierarchy/HierarchyIcon';
import { HierarchyController } from '~components/hierarchy/HierarchyController';
import { HierarchyLoading } from '~components/hierarchy/HierarchyLoading';

type Props = {
  meteringGroup: MeteringGroup;
  paddingLeft?: number;
  isRoot?: boolean;
};

export function HierarchySite({ meteringGroup, paddingLeft = 2, isRoot = false }: Props) {
  const { data: edgeControllers = [], isLoading } = useEdgeControllerList();
  const isRoutePermitted = useIsRoutePermitted(createSitePath(meteringGroup.id));

  const meteringGroupEdgeControllers = useMemo(
    () =>
      edgeControllers.filter(
        (edgeController) => edgeController.metering_group.id === meteringGroup.id,
      ),
    [edgeControllers, meteringGroup.id],
  );

  if (isLoading) return <HierarchyLoading />;

  if (isRoot || !isRoutePermitted) {
    if (isRoot && !meteringGroupEdgeControllers.length) {
      return (
        <Typography variant="body2" py={1} px={2}>
          Nothing to display here
        </Typography>
      );
    }

    return meteringGroupEdgeControllers.map((edgeController) => (
      <HierarchyController
        key={edgeController.id}
        edgeController={edgeController}
        paddingLeft={paddingLeft}
      />
    ));
  }

  return (
    <List disablePadding>
      <Link
        to={createSitePath(meteringGroup.id)}
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        <ListItemButton sx={{ padding: 0, paddingLeft, paddingRight: 2 }}>
          <ListItemIcon>
            <HierarchyIcon breadcrumbType="sites" />
          </ListItemIcon>
          <ListItemText primary={meteringGroup.name} />
        </ListItemButton>
      </Link>
      {meteringGroupEdgeControllers.map((edgeController) => (
        <HierarchyController
          key={edgeController.id}
          edgeController={edgeController}
          paddingLeft={paddingLeft + 2}
        />
      ))}
    </List>
  );
}
