import { Box, Grid, IconButton, Tooltip, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { addMinutes, differenceInMinutes, subMinutes } from 'date-fns';

import type { User } from '~types';

import { USER_ROLES } from '~constants/auth';

import { type DateTimeRange, formatRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';
import { transformAbsoluteValue } from '~utils/number';

import { useCurrentESUId } from '~hooks/useCurrentESUId';
import {
  createAggregateQuery,
  createRangeQuery,
  useTimeseriesAggregate,
  useTimeseriesRange,
} from '~hooks/timeseries/queries';
import { type ResourceID } from '~hooks/timeseries/types';
import { useURLRange } from '~hooks/useURLRange';
import { useLocalStorage } from '~hooks/useLocalStorage';
import { useESU } from '~hooks/useESUList';
import { useESUSchedule } from '~hooks/useESUSchedule';

import { CopyLinkButton } from '~components/CopyLinkButton';
import { TimeSeriesMultiLineChart } from '~components/charts';
import { Iconify } from '~components/Iconify';
import { DateTimeRangePicker } from '~components/DateTimeRangePicker';

export function DashboardCharts() {
  const esuId = useCurrentESUId();
  const { data: esu } = useESU(esuId);
  const meteringGroupId = esu?.edge_controller.metering_group?.id;
  const resourceId: ResourceID | null = esu?.resource_id ?? null;
  const meteringGroupResourceId: ResourceID | null =
    meteringGroupId != null ? `metering_group:${meteringGroupId}` : null;

  const theme = useTheme();

  const [range, setRange] = useURLRange(6 * 3600);

  const [userData] = useLocalStorage<User | null>('user', null);

  const {
    data: chartData,
    isLoading: isLoadingChartData,
    error: chartDataError,
  } = useChartData(resourceId, range);
  const isAggregatedOverTime = chartData.isAggregatedOverTime;
  const {
    data: scheduleData,
    extendedData: extendedScheduleData,
    isLoading: isLoadingSchedule,
    error: scheduleError,
  } = useESUSchedule(resourceId, range);
  const {
    data: gridMeterData,
    isLoading: isLoadingGridMeter,
    error: gridMeterError,
  } = useGridMeterData(meteringGroupResourceId, range);

  const esuName = esu?.name;
  const chartExportFilename = useCallback(
    (startTime: Date, endTime: Date, chartTitle: string) =>
      `${chartTitle} ${esuName ?? esuId} at ${startTime.toISOString()}`,
    [esuName, esuId],
  );

  const rangeString = formatRange(range);

  // don't draw over gap when 5 pings dropped
  const rawDataLongestGapMs = 60_000;
  // don't draw over gap when 5 data points are missing
  const aggregateLongestGapMs = getAggregageQueryTimeStepSeconds(range) * 5_000;

  const annotationLineColor = theme.palette.grey[500];
  const annotationStrokeDasharray = '4,2';

  const timeAxis = {
    clipMin: range.start,
    include: [range.start, range.end],
    clipMax: range.end,
  };

  return (
    <Grid container spacing={3} paddingBottom={3}>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <DateTimeRangePicker value={range} onChange={setRange} />
          <Box>
            <CopyLinkButton
              link={
                `${window.location.origin}/dashboard/${esuId}/app` +
                `?start=${range.start.toISOString()}&end=${range.end.toISOString()}`
              }
            />
            {(userData?.roles['/'] ?? []).includes(USER_ROLES.DEBUG_EMS) && (
              <Tooltip title="View pings" placement="top">
                <IconButton
                  href={`/api/v1/edge_controller/${esu?.edge_controller
                    .id}/pings?start=${range.start.toISOString()}&limit=10`}
                  target="_blank"
                >
                  <Iconify icon="material-symbols:network-ping" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="State of charge"
          subtitle={rangeString}
          height={280}
          data={{
            esu_status: chartData.esu_status,
            schedule_history: extendedScheduleData,
            'schedule_history#export': scheduleData,
          }}
          isLoading={isLoadingChartData || isLoadingSchedule}
          error={chartDataError ?? scheduleError}
          units="%"
          tooltipDecimalDigits={0}
          timeAxis={timeAxis}
          valueAxis={{ include: [0, 100] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            esu_status: {
              variables: [
                {
                  name: isAggregatedOverTime ? 'SoC (mean)' : 'SoC',
                  sourceColumn: isAggregatedOverTime ? 'soc:mean' : 'soc',
                  color: theme.palette.chart.single,
                },
              ],
              longestDrawnGapMs: isAggregatedOverTime ? aggregateLongestGapMs : rawDataLongestGapMs,
            },
            schedule_history: {
              variables: [
                {
                  name: 'SoC Target Max',
                  sourceColumn: 'max_target_soc',
                  color: theme.palette.chart.gray,
                  interpolation: 'step-after',
                  strokeDasharray: '6,3',
                },
                {
                  name: 'SoC Target Min',
                  sourceColumn: 'min_target_soc',
                  color: theme.palette.chart.gray,
                  interpolation: 'step-after',
                  strokeDasharray: '6,3',
                  lowerBoundOfArea: {
                    upperBoundSeriesName: 'SoC Target Max',
                    areaColor: theme.palette.chart.gray,
                    areaOpacity: 0.2,
                  },
                },
              ],
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="ESU active power"
          subtitle={rangeString}
          height={280}
          data={{ esu_power_control: chartData.esu_power_control }}
          isLoading={isLoadingChartData}
          error={chartDataError}
          units="kW"
          timeAxis={timeAxis}
          valueAxis={{ include: [-1, 1] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            esu_power_control: {
              variables: isAggregatedOverTime
                ? [
                    {
                      name: 'Power (max)',
                      sourceColumn: 'esu_realized_power:max',
                      denominator: 1000,
                      color: theme.palette.chart.aggregate.max,
                    },
                    {
                      name: 'Power (mean)',
                      sourceColumn: 'esu_realized_power:mean',
                      denominator: 1000,
                      color: theme.palette.chart.aggregate.mean,
                    },
                    {
                      name: 'Power (min)',
                      sourceColumn: 'esu_realized_power:min',
                      denominator: 1000,
                      color: theme.palette.chart.aggregate.min,
                      lowerBoundOfArea: {
                        upperBoundSeriesName: 'Power (max)',
                        areaColor: theme.palette.chart.aggregate.area,
                        areaOpacity: 0.2,
                      },
                    },
                  ]
                : [
                    {
                      name: 'Power',
                      sourceColumn: 'esu_realized_power',
                      denominator: 1000,
                      color: theme.palette.chart.single,
                    },
                  ],
              longestDrawnGapMs: isAggregatedOverTime ? aggregateLongestGapMs : rawDataLongestGapMs,
              snapTooltip: true,
            },
          }}
          chartAnnotations={[
            {
              type: 'line',
              height: 0,
              color: annotationLineColor,
              strokeDasharray: annotationStrokeDasharray,
            },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="Total grid power"
          subtitle={rangeString}
          height={280}
          data={{ grid_meter: gridMeterData }}
          isLoading={isLoadingGridMeter}
          error={gridMeterError}
          units="kW"
          timeAxis={timeAxis}
          valueAxis={{ include: [-1, 1] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            grid_meter: {
              variables: [
                {
                  name: 'Power (max)',
                  sourceColumn: 'grid_active_power:max',
                  denominator: 1000,
                  color: theme.palette.chart.aggregate.max,
                },
                {
                  name: 'Power (mean)',
                  sourceColumn: 'grid_active_power:mean',
                  denominator: 1000,
                  color: theme.palette.chart.aggregate.mean,
                },
                {
                  name: 'Power (min)',
                  sourceColumn: 'grid_active_power:min',
                  denominator: 1000,
                  color: theme.palette.chart.aggregate.min,
                  lowerBoundOfArea: {
                    upperBoundSeriesName: 'Power (max)',
                    areaColor: theme.palette.chart.aggregate.area,
                    areaOpacity: 0.2,
                  },
                },
              ],
              longestDrawnGapMs: aggregateLongestGapMs,
              snapTooltip: true,
            },
          }}
          chartAnnotations={[
            {
              type: 'line',
              height: 0,
              color: annotationLineColor,
              strokeDasharray: annotationStrokeDasharray,
            },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="Grid frequency"
          subtitle={rangeString}
          height={400}
          data={{ esu_power_control: chartData.esu_power_control }}
          isLoading={isLoadingChartData}
          error={chartDataError}
          units="Hz"
          tooltipDecimalDigits={3}
          timeAxis={timeAxis}
          valueAxis={{
            clipMin: 47.5,
            include: [49.8, 50.2],
          }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            esu_power_control: {
              variables: isAggregatedOverTime
                ? [
                    {
                      name: 'Frequency (max)',
                      sourceColumn: 'control_grid_f:max',
                      denominator: 1000,
                      color: theme.palette.chart.aggregate.max,
                    },
                    {
                      name: 'Frequency (mean)',
                      sourceColumn: 'control_grid_f:mean',
                      denominator: 1000,
                      color: theme.palette.chart.aggregate.mean,
                    },
                    {
                      name: 'Frequency (min)',
                      sourceColumn: 'control_grid_f:min',
                      denominator: 1000,
                      color: theme.palette.chart.aggregate.min,
                      lowerBoundOfArea: {
                        upperBoundSeriesName: 'Frequency (max)',
                        areaColor: theme.palette.chart.aggregate.area,
                        areaOpacity: 0.2,
                      },
                    },
                  ]
                : [
                    {
                      name: 'Frequency',
                      sourceColumn: 'control_grid_f',
                      denominator: 1000,
                      color: theme.palette.chart.single,
                    },
                  ],
              longestDrawnGapMs: isAggregatedOverTime ? aggregateLongestGapMs : rawDataLongestGapMs,
              snapTooltip: true,
            },
          }}
          chartAnnotations={[
            {
              type: 'area',
              y: { from: 49.9, to: 50.1 },
              color: theme.palette.chart.validity.good,
              opacity: 0.15,
            },
            {
              type: 'line',
              height: 50,
              color: annotationLineColor,
              strokeDasharray: annotationStrokeDasharray,
            },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="Grid current"
          subtitle={rangeString}
          height={280}
          data={{
            grid_meter: gridMeterData,
            schedule_history: extendedScheduleData,
            'schedule_history#export': scheduleData,
          }}
          isLoading={isLoadingGridMeter || isLoadingSchedule}
          error={gridMeterError ?? scheduleError}
          units="A"
          tooltipDecimalDigits={0}
          timeAxis={timeAxis}
          valueAxis={{ include: [0, 100] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            grid_meter: {
              variables: [
                {
                  name: 'L1 (max)',
                  sourceColumn: 'current_a:max',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[0],
                  opacity: 0.25,
                  includeInLegend: false,
                },
                {
                  name: 'L1 (mean)',
                  sourceColumn: 'current_a:mean',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[0],
                  centerOfArea: {
                    name: 'L1',
                    lowerBoundSeriesName: 'L1 (min)',
                    upperBoundSeriesName: 'L1 (max)',
                    areaOpacity: 0.2,
                  },
                },
                {
                  name: 'L1 (min)',
                  sourceColumn: 'current_a:min',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[0],
                  opacity: 0.25,
                  includeInLegend: false,
                },
                {
                  name: 'L2 (max)',
                  sourceColumn: 'current_b:max',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[1],
                  opacity: 0.25,
                  includeInLegend: false,
                },
                {
                  name: 'L2 (mean)',
                  sourceColumn: 'current_b:mean',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[1],
                  centerOfArea: {
                    name: 'L2',
                    lowerBoundSeriesName: 'L2 (min)',
                    upperBoundSeriesName: 'L2 (max)',
                    areaOpacity: 0.2,
                  },
                },
                {
                  name: 'L2 (min)',
                  sourceColumn: 'current_b:min',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[1],
                  opacity: 0.25,
                  includeInLegend: false,
                },
                {
                  name: 'L3 (max)',
                  sourceColumn: 'current_c:max',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[2],
                  opacity: 0.25,
                  includeInLegend: false,
                },
                {
                  name: 'L3 (mean)',
                  sourceColumn: 'current_c:mean',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[2],
                  centerOfArea: {
                    name: 'L3',
                    lowerBoundSeriesName: 'L3 (min)',
                    upperBoundSeriesName: 'L3 (max)',
                    areaOpacity: 0.2,
                  },
                },
                {
                  name: 'L3 (min)',
                  sourceColumn: 'current_c:min',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[2],
                  opacity: 0.25,
                  includeInLegend: false,
                },
              ],
              longestDrawnGapMs: aggregateLongestGapMs,
              snapTooltip: true,
            },
            schedule_history: {
              variables: [
                {
                  name: 'Peak shaving threshold',
                  sourceColumn: 'peak_shaving_threshold',
                  color: theme.palette.chart.gray,
                  interpolation: 'step-after',
                  strokeDasharray: '6,3',
                },
              ],
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="Cell temperature"
          subtitle={rangeString}
          height={280}
          data={{ bms_temperatures: chartData.bms_temperatures }}
          isLoading={isLoadingChartData}
          error={chartDataError}
          units="°C"
          tooltipDecimalDigits={0}
          timeAxis={timeAxis}
          valueAxis={{ include: [10, 50] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            bms_temperatures: {
              variables: [
                {
                  name: 'Temperature (max)',
                  sourceColumn: isAggregatedOverTime ? '*:max' : 'max(*)',
                  color: theme.palette.chart.coldHot.hot,
                },
                {
                  name: 'Temperature (min)',
                  sourceColumn: isAggregatedOverTime ? '*:min' : 'min(*)',
                  color: theme.palette.chart.coldHot.cold,
                  lowerBoundOfArea: {
                    upperBoundSeriesName: 'Temperature (max)',
                    areaColor: theme.palette.chart.coldHot.area,
                    areaOpacity: 0.2,
                  },
                },
              ],
              longestDrawnGapMs: isAggregatedOverTime ? aggregateLongestGapMs : rawDataLongestGapMs,
              snapTooltip: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

function useChartData(resourceId: ResourceID | null, range: DateTimeRange) {
  const shouldAggregateOverTime = differenceInMinutes(range.end, range.start) > 120;
  const aggregateTimeStepSeconds = getAggregageQueryTimeStepSeconds(range);
  const resources = resourceId != null ? [resourceId] : [];
  const {
    data: aggregateData,
    isLoading: loadingAggregateData,
    error: aggregateDataError,
  } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: shouldAggregateOverTime ? resources : [],
      columns: {
        bms_temperatures: ['*:max', '*:min'],
        esu_status: ['soc:mean'],
        esu_power_control: [
          'esu_realized_power:mean',
          'esu_realized_power:min',
          'esu_realized_power:max',
          'control_grid_f:mean',
          'control_grid_f:min',
          'control_grid_f:max',
        ],
      },
      start: range.start,
      end: range.end,
      step: `${aggregateTimeStepSeconds}s`,
    }),
  );

  const {
    data: rawData,
    isLoading: loadingRawData,
    error: rawDataError,
  } = useTimeseriesRange(
    createRangeQuery({
      resources: shouldAggregateOverTime ? [] : resources,
      columns: {
        bms_temperatures: ['max(*)', 'min(*)'],
        esu_status: ['soc'],
        esu_power_control: ['esu_realized_power', 'control_grid_f'],
      },
      start: range.start,
      end: range.end,
      fillStart: subMinutes(range.start, 1),
      fillEnd: addMinutes(range.end, 1),
    }),
  );

  if (shouldAggregateOverTime) {
    const data = resourceId != null ? aggregateData?.[resourceId] : null;
    return {
      data: {
        isAggregatedOverTime: true,
        bms_temperatures: data?.bms_temperatures ?? null,
        esu_status: data?.esu_status ?? null,
        esu_power_control: data?.esu_power_control ?? null,
      },
      isLoading: loadingAggregateData,
      error: aggregateDataError,
    };
  } else {
    const data = resourceId != null ? rawData?.[resourceId] : null;
    return {
      data: {
        isAggregatedOverTime: false,
        bms_temperatures: data?.bms_temperatures ?? null,
        esu_status: data?.esu_status ?? null,
        esu_power_control: data?.esu_power_control ?? null,
      },
      isLoading: loadingRawData,
      error: rawDataError,
    };
  }
}

function useGridMeterData(resourceId: ResourceID | null, range: DateTimeRange) {
  const timeStepSeconds = getAggregageQueryTimeStepSeconds(range);
  const { data, isLoading, error } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: resourceId != null ? [resourceId] : [],
      columns: {
        grid_meter: [
          'grid_active_power:mean',
          'grid_active_power:min',
          'grid_active_power:max',
          'current_a:mean',
          'current_a:min',
          'current_a:max',
          'current_b:mean',
          'current_b:min',
          'current_b:max',
          'current_c:mean',
          'current_c:min',
          'current_c:max',
        ],
      },
      start: range.start,
      end: range.end,
      step: `${timeStepSeconds}s`,
    }),
  );
  return {
    data: resourceId != null ? data?.[resourceId].grid_meter ?? null : null,
    isLoading,
    error,
  };
}
