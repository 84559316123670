import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { COMMAND_PALETTE_Z_INDEX } from '~theme/index';

import { CommandPalette } from '~components/command-palette/CommandPalette';

const IS_MAC_OR_IOS =
  typeof navigator === 'object' && /Mac|iPod|iPhone|iPad/.test(navigator.platform);

export function CommandPaletteLayout() {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Open command palette with Cmd+K on macOS, Ctrl+K on other platforms
      if (event.key === 'k' && (IS_MAC_OR_IOS ? event.metaKey : event.ctrlKey)) {
        event.preventDefault();
        setOpen((open) => !open);
      }
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClose]);

  if (!open) return null;

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bgcolor="rgba(0, 0, 0, 0.5)"
      zIndex={COMMAND_PALETTE_Z_INDEX}
      display="flex"
      flexDirection="column"
      alignItems="center"
      pt="72px"
      onClick={(event) => {
        if (event.target !== event.currentTarget) return;
        handleClose();
      }}
    >
      <Box
        width="fit-content"
        bgcolor="Background"
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
      >
        <CommandPalette onClose={handleClose} />
      </Box>
    </Box>
  );
}
