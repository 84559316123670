import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { formatDateTime } from '~utils/time';

import { useTimeseriesLatest } from '~hooks/timeseries';
import { createLatestQuery } from '~hooks/timeseries/queries';

import { DashboardCard, DashboardCardHeader } from '~components/DashboardCard';
import { RelativeTime } from '~components/RelativeTime';

export const FINGRID_CONTROL_AREA = '10YFI-1--------U';

export function FingridStatusCard() {
  const { data } = useTimeseriesLatest(
    createLatestQuery({
      resources: [`region:${FINGRID_CONTROL_AREA}`],
      columns: {
        fingrid_power_system_state: ['value'],
        fingrid_electricity_shortage_status: ['value'],
      },
      start: { hours: 2 },
    }),
    { refreshInterval: 60_000 },
  );

  const powerSystemState = data?.[`region:${FINGRID_CONTROL_AREA}`].fingrid_power_system_state;
  const shortageStatus =
    data?.[`region:${FINGRID_CONTROL_AREA}`].fingrid_electricity_shortage_status;

  const theme = useTheme();
  const goodSx = {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  };
  const warnSx = {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  };
  const errorSx = {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  };
  const errorDarkSx = {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
  };

  const [powerSystemText, powerSystemTooltip, powerSystemSx] = (() => {
    const value = powerSystemState?.value;
    if (value == null) return ['N/A', 'Data unavailable', warnSx];
    switch (value) {
      case 1:
        return ['1: Normal', 'Green: Power system is in normal secure state', goodSx];
      case 2:
        return [
          '2: Endangered',
          "Yellow: Power system is in endangered state. The adequacy of the electricity is endangered or the power system doesn't fulfill the security standards",
          warnSx,
        ];
      case 3:
        return [
          '3: Disturbed',
          'Red: Power system is in disturbed state. Load shedding has happened in order to keep the adequacy and security of the power system or there is a remarkable risk to a wide black out',
          errorSx,
        ];
      case 4:
        return [
          '4: Serious disturbance',
          'Black: An extremely serious disturbance or a wide black out in Finland',
          { backgroundColor: '#000000', color: '#ffffff' },
        ];
      case 5:
        return [
          '5: Being restored',
          'Blue: The network is being restored after an extremely serious disturbance or a wide blackout',
          { backgroundColor: '#0328fc', color: '#ffffff' },
        ];
      default:
        return [`${value}: unexpeceted`, 'Unexpected status', warnSx];
    }
  })();

  const [shortageText, shortageTooltip, shortageSx] = (() => {
    const value = shortageStatus?.value;
    if (value == null) return ['N/A', 'Data unavailable', warnSx];
    switch (value) {
      case 0:
        return ['0: Normal', 'Status: Normal', goodSx];
      case 1:
        return ['1: Possible', 'Status: Electricity shortage possible', warnSx];
      case 2:
        return ['2: High risk', 'Status: High risk of electricity shortage', errorSx];
      case 3:
        return ['3: Ongoing', 'Status: Ongoing electricity shortage', errorDarkSx];
      default:
        return [`${value}: unexpeceted`, 'Unexpected status', warnSx];
    }
  })();

  return (
    <DashboardCard size="small">
      <DashboardCardHeader>Main Grid</DashboardCardHeader>
      <Table
        size="small"
        sx={{
          '& td, & th': { lineHeight: '1.5rem' },
          '& td:first-of-type, & th:first-of-type': { paddingLeft: 0 },
          '& tr:last-child td': { borderBottom: 'none' },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell>Power System State</TableCell>
            <TableCell align="center" sx={powerSystemSx}>
              <Tooltip
                title={
                  <>
                    {powerSystemTooltip}
                    {powerSystemState && (
                      <>
                        {' '}
                        ({formatDateTime(powerSystemState.time)} -{' '}
                        <RelativeTime date={powerSystemState.time} />)
                      </>
                    )}
                  </>
                }
              >
                <Typography variant="data">{powerSystemText}</Typography>
              </Tooltip>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Electricity Shortage Status</TableCell>
            <TableCell align="center" sx={shortageSx}>
              <Tooltip
                title={
                  <>
                    {shortageTooltip}
                    {shortageStatus && (
                      <>
                        {' '}
                        ({formatDateTime(shortageStatus.time)} -{' '}
                        <RelativeTime date={shortageStatus.time} />)
                      </>
                    )}
                  </>
                }
              >
                <Typography variant="data">{shortageText}</Typography>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </DashboardCard>
  );
}
