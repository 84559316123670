import { useMemo } from 'react';
import {
  type MRT_ColumnDef,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Box } from '@mui/material';

import { type MeteringGroup } from '~types';

import { collator } from '~utils/localization';

import { useMeteringGroupList } from '~hooks/useMeteringGroupList';
import { MeteringGroupContextProvider } from '~hooks/useMeteringGroupContext';
import { ResultListContextProvider } from '~hooks/useResultListContext';
import { useMaterialTableColumnState } from '~hooks/useMaterialTableColumnState';

import { SIDEBAR_USER_HEIGHT } from '~layouts/AppLayout/Sidebar';

import { useSitesNavigation } from '~pages/v2/sites/hooks/useSitesNavigation';

import { BreadcrumbsProvider } from '~components/breadcrumbs/BreadcrumbsProvider';
import { Breadcrumbs } from '~components/breadcrumbs/Breadcrumbs';
import { SplitLayout } from '~components/SplitLayout';

const columns: MRT_ColumnDef<MeteringGroup>[] = [
  {
    header: 'Name',
    id: 'name',
    accessorFn: (row) => row.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    Footer: SiteNameFooter,
  },
  {
    header: 'Organization',
    id: 'organization_name',
    accessorFn: (row) => row.organization_name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    filterVariant: 'autocomplete',
  },
];

export function SitesList() {
  const { data: meteringGroups = [] } = useMeteringGroupList();
  const { meteringGroupId, tab, navigateToSite } = useSitesNavigation();

  const currentMeteringGroup = meteringGroups?.find((mg) => mg.id === meteringGroupId);

  const rowSelection = useMemo(
    () => (meteringGroupId ? { [meteringGroupId]: true } : {}),
    [meteringGroupId],
  );
  const {
    columnSizing,
    columnFilters,
    columnVisibility,
    setColumnSizing,
    setColumnFilters,
    setColumnVisibility,
  } = useMaterialTableColumnState('sites');

  const table = useMaterialReactTable<MeteringGroup>({
    data: meteringGroups,
    columns,
    initialState: {
      density: 'compact',
    },
    state: {
      rowSelection,
      columnSizing,
      columnFilters,
      columnVisibility,
    },
    onColumnSizingChange: setColumnSizing,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableBottomToolbar: false,
    enableFacetedValues: true,
    positionToolbarAlertBanner: 'none',
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigateToSite(row.id, tab);
      },
      sx: { cursor: 'pointer' },
    }),
    muiTableContainerProps: {
      sx: { maxHeight: 'calc(100vh - 128px)' },
    },
    muiTablePaperProps: {
      sx: { borderRadius: 0 },
    },
    muiTableFooterCellProps: {
      sx: { height: SIDEBAR_USER_HEIGHT + 1 },
    },
  });

  const rows = useMRT_Rows(table);

  const resultListContext = useMemo(() => {
    const currentRowIndex = meteringGroupId
      ? rows.findIndex((row) => row.id === meteringGroupId)
      : -1;
    const totalRowCount = rows.length;

    return {
      currentRowIndex,
      totalRowCount,
      goToNextResult: () => {
        if (currentRowIndex + 1 >= totalRowCount) return;

        navigateToSite(rows[currentRowIndex + 1].id, tab);
      },
      goToPreviousResult: () => {
        if (currentRowIndex <= 0) return;

        navigateToSite(rows[currentRowIndex - 1].id, tab);
      },
      goToResultList: () => navigateToSite(),
    };
  }, [rows, meteringGroupId, tab, navigateToSite]);

  const meteringGroupContextValue = useMemo(
    () =>
      meteringGroupId
        ? {
            id: meteringGroupId,
            name: currentMeteringGroup?.name ?? '',
          }
        : null,
    [meteringGroupId, currentMeteringGroup],
  );

  return (
    <MeteringGroupContextProvider value={meteringGroupContextValue}>
      <ResultListContextProvider value={resultListContext}>
        <BreadcrumbsProvider>
          <SplitLayout
            showDetails={!!meteringGroupId}
            renderTitle={() => <Breadcrumbs hasIcon hasNavigation />}
          >
            <MaterialReactTable table={table} />
          </SplitLayout>
        </BreadcrumbsProvider>
      </ResultListContextProvider>
    </MeteringGroupContextProvider>
  );
}

function SiteNameFooter({ table }: { table: MRT_TableInstance<MeteringGroup> }) {
  const rows = useMRT_Rows(table);

  return (
    <Box display="flex" alignItems="center">
      {rows.length} groups
    </Box>
  );
}
