import { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Alert,
  Avatar,
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { asCactosError } from '~http';

import { USER_ROLES } from '~constants/auth';

import { useCurrentEdgeControllerId } from '~hooks/useCurrentEdgeControllerId';
import { useEdgeController } from '~hooks/useEdgeController';
import { useOrganizationList } from '~hooks/useOrganizationList';
import { useUserHasRootRole } from '~hooks/useUserHasRootRole';
import { useUiV2 } from '~hooks/useUiV2';

import { EdgeControllerTransferModal } from '~pages/fleet/controllers/EdgeControllerTransferModal';
import { filterValidControllerTransferOrganizations } from '~pages/fleet/controllers/utils';
import { MapESUModal } from '~pages/fleet/controllers/MapESUModal';

import { Iconify } from '~components/Iconify';
import { CopyableID } from '~components/CopyableID';
import { Page } from '~components/Page';

export function EdgeControllerDetailPage() {
  const id = useCurrentEdgeControllerId();
  const { data: edgeController, error } = useEdgeController(id);
  const { data: organizations = [] } = useOrganizationList();
  const shouldUseUiV2Link = useUiV2();

  const userCanTransfer = useUserHasRootRole(USER_ROLES.EDIT_EMS);
  const userCanRegisterESU = useUserHasRootRole(USER_ROLES.CREATE_EMS);

  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [mapESUModalLocalId, setMapESUModalLocalId] = useState<string | null>(null);

  const validOrganizations = useMemo(
    () => filterValidControllerTransferOrganizations(organizations),
    [organizations],
  );

  return (
    <Page
      title={edgeController != null ? `Edge controller ${edgeController.name}` : 'Edge controller'}
    >
      {error != null && <Alert severity="error">{asCactosError(error).message}</Alert>}
      <List sx={{ background: '#1d1d1d', p: 0 }}>
        <ListItem>
          <ListItemText
            primary="Edge controller ID"
            secondary={
              <Box mt={1}>
                <CopyableID id={id} />
              </Box>
            }
            sx={{ marginLeft: 2 }}
            primaryTypographyProps={{ fontWeight: '600' }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Type"
            secondary={edgeController?.type}
            sx={{ marginLeft: 2 }}
            primaryTypographyProps={{ fontWeight: '600' }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Metering group"
            secondary={edgeController?.metering_group.name || '(none)'}
            sx={{ marginLeft: 2 }}
            primaryTypographyProps={{ fontWeight: '600' }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <>
                Organization
                {userCanTransfer && !!validOrganizations.length && (
                  <Link ml={1} variant="body2" onClick={() => setIsTransferModalOpen(true)}>
                    Transfer
                  </Link>
                )}
              </>
            }
            secondary={
              edgeController?.organization != null ? (
                <>
                  {edgeController.organization.human_name}{' '}
                  <code>({edgeController.organization.name})</code>
                </>
              ) : null
            }
            sx={{ marginLeft: 2 }}
            primaryTypographyProps={{ fontWeight: '600' }}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="ESUs"
            sx={{ marginLeft: 2 }}
            primaryTypographyProps={{ fontWeight: '600' }}
          />
        </ListItem>
        {(edgeController?.esus ?? []).map((esu) => (
          <ListItem key={esu.id}>
            <ListItemButton
              component={RouterLink}
              to={
                shouldUseUiV2Link
                  ? `/v2/esus/${esu.id}/diagnostics`
                  : `/dashboard/esus/${esu.id}/diagnostics`
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <Iconify icon="mdi:battery-charging-50" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={esu.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <ListItem>
          <ListItemText
            primary="Unregistered ESUs"
            sx={{ marginLeft: 2 }}
            primaryTypographyProps={{ fontWeight: '600' }}
          />
        </ListItem>
        {(edgeController?.unmapped_esus ?? []).map((esuId) => (
          <ListItem key={esuId}>
            <ListItemButton sx={{ cursor: 'default' }}>
              <ListItemAvatar>
                <Avatar>
                  <Iconify icon="mdi:battery-charging-50" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={esuId} sx={{ flexGrow: 0 }} />
              {userCanRegisterESU && (
                <Link ml={2} variant="body2" onClick={() => setMapESUModalLocalId(esuId)}>
                  Register
                </Link>
              )}
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
      </List>
      <EdgeControllerTransferModal
        open={isTransferModalOpen}
        onClose={() => setIsTransferModalOpen(false)}
      />
      <MapESUModal localId={mapESUModalLocalId} onClose={() => setMapESUModalLocalId(null)} />
    </Page>
  );
}
