import { styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';

import { DASHBOARD_SIDEBAR_Z_INDEX } from '~theme/index';

import { useResponsive } from '~hooks/useResponsive';

import { sidebarConfig } from '~layouts/dashboard/sidebarConfig';
import { APPBAR_DESKTOP, APPBAR_MOBILE } from '~layouts/dashboard/DashboardNavbar';

import { NavSection } from '~components/NavSection';

const DRAWER_WIDTH = 200;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

export function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}) {
  const isDesktop = useResponsive('up', 'lg');

  const content = (
    <>
      <NavSection navConfig={sidebarConfig} />
      <Box sx={{ flexGrow: 1 }} />
    </>
  );

  return (
    <RootStyle data-test-id="DashboardSidebar">
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: 'calc(100vw - 72px)',
              paddingTop: `${APPBAR_MOBILE}px`,
            },
          }}
          sx={{ zIndex: DASHBOARD_SIDEBAR_Z_INDEX }}
        >
          {content}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              paddingTop: `${APPBAR_DESKTOP}px`,
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {content}
        </Drawer>
      )}
    </RootStyle>
  );
}
