import type React from 'react';
import { useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { addSeconds, subSeconds } from 'date-fns';

import { asCactosError } from '~http';

import { formatDateTime } from '~utils/time';
import { collator } from '~utils/localization';

import { useMeteringGroupList } from '~hooks/useMeteringGroupList';
import { type ResourceID, type Row, useTimeseriesLatestForecast } from '~hooks/timeseries';
import { createLatestForecastQuery } from '~hooks/timeseries/queries';

import { OptimizerResultGraph } from '~components/charts/OptimizerResultGraph';

import { FCRNDetails } from './FCRNDetails';
import { type RunDetails, type RunDetailsGroupResult } from './types';

export function RunView({ run }: { run: RunDetails }) {
  const {
    data: groupList,
    isLoading: loadingGroupList,
    error: groupListError,
  } = useMeteringGroupList();

  const [groupsByID, esusByID] = useMemo(
    () => [
      new Map((groupList ?? []).map((group) => [group.id, group])),
      new Map((groupList ?? []).flatMap((group) => group.esus.map((esu) => [esu.id, esu]))),
    ],
    [groupList],
  );

  // wrap mutable set in object to re-render on change
  const [openGroups, setOpenGroups] = useState({ set: new Set<string>() });

  const handleChange = (id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setOpenGroups(({ set }) => {
      if (isExpanded) set.add(id);
      else set.delete(id);
      // return new object containing the mutated set
      return { set };
    });
  };

  return (
    <Box>
      <Box>
        <Card sx={{ p: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
            <Typography variant="h4" color="primary">
              Optimizer result at {formatDateTime(run.run_time)}
              {loadingGroupList && <CircularProgress size={14} sx={{ ml: 1.5 }} />}
            </Typography>
            <Button
              onClick={() =>
                setOpenGroups(
                  openGroups.set.size > 0 ? { set: new Set() } : { set: new Set(run.groups) },
                )
              }
            >
              {openGroups.set.size > 0 ? 'Collapse All' : 'Expand All'}
            </Button>
          </Stack>
          Run ID: <Typography variant="data">{run.id}</Typography>
          {groupListError != null && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              Error loading group and ESU names: {asCactosError(groupListError).message}
            </Alert>
          )}
          <Typography>
            Optimizer disabled for {run.disabled_units.length} unit
            {run.disabled_units.length !== 1 && 's'}
            {run.disabled_units.length > 0 && (
              <>
                {': '}
                {run.disabled_units
                  .slice(0, 10)
                  .map((id) => esusByID.get(id)?.name ?? id)
                  .join(', ')}
                {run.disabled_units.length > 10 && ', ..'}
              </>
            )}
            .
          </Typography>
          {Object.keys(run.init_errors).length > 0 && (
            <Box mt={2}>
              <h4>Optimizer failed for the following groups/units:</h4>
              <List>
                {Object.entries(run.init_errors).map(([groupOrEsuId, error]) => (
                  <ListItem key={groupOrEsuId}>
                    {groupsByID.get(groupOrEsuId)?.name ??
                      esusByID.get(groupOrEsuId)?.name ??
                      groupOrEsuId}
                    : {error}
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Card>
        {[...run.groups]
          .sort((a, b) =>
            collator.compare(groupsByID.get(a)?.name ?? '', groupsByID.get(b)?.name ?? ''),
          )
          .map((groupID) => (
            <Accordion
              key={groupID}
              expanded={openGroups.set.has(groupID)}
              onChange={handleChange(groupID)}
              slotProps={{ transition: { unmountOnExit: true } }}
            >
              <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                {groupsByID.get(groupID)?.name ?? groupID}
              </AccordionSummary>
              <AccordionDetails>
                <GroupView group={run.group_results[groupID]} run={run} esusByID={esusByID} />
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
      <FCRNDetails runID={run.id} esusByID={esusByID} />
    </Box>
  );
}

type GroupViewProps = {
  group: RunDetailsGroupResult;
  run: RunDetails;
  esusByID: Map<string, { name: string }>;
};

function GroupView({ group, run, esusByID }: GroupViewProps) {
  // instead of fetching data for each group and ESU separately,
  // it's better to have the hook fetch data for the whole run and trust SWR to cache it
  const {
    groupData: groupTimeseriesResult,
    groupDataLoading,
    groupDataError,
    esuData: esuTimeseriesResult,
    esuDataLoading,
    esuDataError,
  } = useOptimizerResultTimeseries(run);

  const esus = Object.values(group.esu_results);
  esus.sort((a, b) =>
    collator.compare(esusByID.get(a.esu_id)?.name ?? '', esusByID.get(b.esu_id)?.name ?? ''),
  );

  const groupResultRows =
    groupTimeseriesResult?.[`metering_group:${group.group_id}`]?.group_optimizer_result ?? null;

  const loadShiftingProfit = useMemo(
    () => calculateLoadShiftingProfit(groupResultRows),
    [groupResultRows],
  );

  return (
    <Box>
      {group.init_error && (
        <Alert severity="error" sx={{ mb: 1 }}>
          Optimizer had init error for group: {group.init_error}
        </Alert>
      )}
      {loadShiftingProfit != null && (
        <Typography variant="body2">
          Group load shifting profit: {loadShiftingProfit.toFixed(2)} €
        </Typography>
      )}
      <OptimizerResultGraph
        variant="group"
        groupData={groupResultRows}
        isLoading={groupDataLoading}
        error={groupDataError}
      />
      {esus.map((esu) => {
        const esuResultRows =
          esuTimeseriesResult?.[`esu:${esu.esu_id}`]?.esu_optimizer_result ?? null;
        return (
          <Box key={esu.esu_id} mt={2}>
            <Typography variant="body1" color="primary">
              {esusByID.get(esu.esu_id)?.name ?? esu.esu_id}
            </Typography>
            {esu.init_error && (
              <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                Optimizer had init error for ESU: {esu.init_error}
              </Alert>
            )}
            {esu.warnings.length > 0 &&
              esu.warnings.map((warning, index) => (
                <Alert severity="warning" sx={{ mt: 1, mb: 1 }} key={index}>
                  {warning}
                </Alert>
              ))}
            <Typography variant="body1" mt={1}>
              Optimizer comment: {esu.comment}
            </Typography>
            <OptimizerResultGraph
              variant="esu"
              groupData={groupResultRows}
              esuData={esuResultRows}
              isLoading={groupDataLoading || esuDataLoading}
              error={groupDataError ?? esuDataError}
            />
          </Box>
        );
      })}
    </Box>
  );
}

function useOptimizerResultTimeseries(run: RunDetails) {
  const runTime = new Date(run.run_time);
  const atTime = addSeconds(runTime, 1);
  const start = subSeconds(runTime, 1);

  const {
    data: groupData,
    isLoading: groupDataLoading,
    error: groupDataError,
  } = useTimeseriesLatestForecast(
    createLatestForecastQuery({
      resources: run.groups.map((group_id) => `metering_group:${group_id}` as ResourceID),
      columns: {
        group_optimizer_result: [
          'duration',
          'import_price',
          'export_price',
          'start_energy_total',
          'end_energy_total',
          'end_energy_low_limit',
          'end_energy_high_limit',
          'energy_capacity_total',
          'fcrn_allocated_cap_total',
          'esu_total_net_energy',
          'constant_power_net',
          'esu_import_energy_max',
          'esu_export_energy_max',
          'facility_total_net_energy',
          'grid_total_net_energy',
          'esu_energy_profit',
        ],
      },
      atTime,
      start,
    }),
  );

  const {
    data: esuData,
    isLoading: esuDataLoading,
    error: esuDataError,
  } = useTimeseriesLatestForecast(
    createLatestForecastQuery({
      resources: run.units.map((esu_id) => `esu:${esu_id}` as ResourceID),
      columns: {
        esu_optimizer_result: [
          'start_energy',
          'end_energy',
          'end_energy_low_limit',
          'end_energy_high_limit',
          'energy_capacity',
          'fcrn_allocated_cap',
          'constant_power',
          'esu_import_energy_max',
          'esu_export_energy_max',
        ],
      },
      atTime,
      start,
    }),
  );

  return {
    groupData,
    groupDataLoading,
    groupDataError,
    esuData,
    esuDataLoading,
    esuDataError,
  };
}

const calculateLoadShiftingProfit = (
  groupResultRows: Row<'group_optimizer_result'>[] | null,
): number | null => {
  if (!groupResultRows) return null;
  const startsWithPartialBalanceInterval =
    groupResultRows[0] && !Number.isFinite(groupResultRows[0].start_energy_total);
  // if the first two rows are for partial balance intervals, they should not be included
  const profits = (
    startsWithPartialBalanceInterval ? groupResultRows.slice(2) : groupResultRows
  ).flatMap((row) =>
    Number.isFinite(row.esu_energy_profit) ? (row.esu_energy_profit as number) : [],
  );
  return profits.reduce((a, b) => a + b, 0);
};
