import { useMemo } from 'react';
import useSWR, { type KeyedMutator, type SWRConfiguration } from 'swr';
import { type Duration } from 'date-fns';

import {
  type AggregateColumns,
  type AggregateQueryResult,
  type Columns,
  type DateOffset,
  type LatestQueryResult,
  type QueryResult,
  type RawQueryResult,
  type ResourceID,
  type TimeSeriesAggregateQuery,
  type TimeSeriesLatestForecastQuery,
  type TimeSeriesLatestQuery,
  type TimeSeriesName,
  type TimeSeriesQuery,
  type TimeSeriesRangeQuery,
} from './types';
import { fetchQuery, fetchQuerySerially } from './fetcher';

export function createLatestQuery(spec: {
  resources: ResourceID[];
  columns: Columns;
  atTime?: Date | 'now';
  start?: Date | Duration;
  notNull?: boolean;
}): TimeSeriesLatestQuery {
  return Object.freeze({
    type: 'latest',
    ...spec,
    atTime: spec.atTime ?? 'now',
    notNull: spec.notNull ?? false,
  });
}

export function createRangeQuery(spec: {
  resources: ResourceID[];
  columns: Columns;
  /**
   * Start date of the range (inclusive)
   * If a `Duration` is given, the start date is
   * obtained by subtracting it from end date.
   */
  start: Date | Duration;
  /**
   * End date of the range (exclusive)
   */
  end: Date | 'now';
  fillStart?: boolean | Date;
  fillEnd?: boolean | Date;
  allowPartialResult?: boolean;
}): TimeSeriesRangeQuery {
  return Object.freeze({
    type: 'range',
    ...spec,
    fillStart: spec.fillStart ?? false,
    fillEnd: spec.fillEnd ?? false,
    allowPartialResult: spec.allowPartialResult ?? false,
  });
}

export function createAggregateQuery(spec: {
  resources: ResourceID[];
  columns: AggregateColumns;
  /**
   * Start date of the range (inclusive)
   * If a `Duration` is given, the start date is
   * obtained by subtracting it from end date.
   */
  start: Date | Duration;
  /**
   * End date of the range (exclusive)
   */
  end: Date | 'now';
  step: DateOffset;
  fillNaMethod?: 'ffill' | 'bfill' | 'none';
}): TimeSeriesAggregateQuery {
  return Object.freeze({
    type: 'aggregate',
    ...spec,
    fillNaMethod: spec.fillNaMethod ?? 'none',
  });
}

export function createLatestForecastQuery(spec: {
  resources: ResourceID[];
  columns: Columns;
  atTime?: Date | 'now';
  start?: Date | Duration;
}): TimeSeriesLatestForecastQuery {
  return Object.freeze({
    type: 'latest_forecast',
    ...spec,
    atTime: spec.atTime ?? 'now',
  });
}

function mergeLatestQueryResult(data: RawQueryResult | null) {
  if (data == null) {
    return null;
  }
  const result: LatestQueryResult = {};
  for (const item of data.results) {
    const rowsBySeries = result[item.resource] ?? {};
    rowsBySeries[item.series as TimeSeriesName] = item.data[0] as any; // TODO: validate result
    result[item.resource] = rowsBySeries;
  }
  return result;
}

export function useTimeseriesLatest(query: TimeSeriesLatestQuery, options?: SWRConfiguration) {
  const key = query.resources.length ? query : null;
  const response = useSWR(key, { fetcher: fetchQuery, ...options });
  const mergedData = useMemo(() => mergeLatestQueryResult(response.data), [response.data]);
  return { ...response, data: mergedData };
}

function mergeQueryResult(data: RawQueryResult | null): QueryResult | AggregateQueryResult | null {
  if (data == null) {
    return null;
  }
  const result: any = {};
  for (const item of data.results) {
    const bySeries = result[item.resource] ?? {};
    bySeries[item.series as TimeSeriesName] = item.data as any; // TODO: validate result
    result[item.resource] = bySeries;
  }
  return result;
}

type TimeSeriesResponse<T extends TimeSeriesQuery> = {
  data: (T extends TimeSeriesAggregateQuery ? AggregateQueryResult : QueryResult) | null;
  error: any;
  mutate: KeyedMutator<any>;
  isValidating: boolean;
  isLoading: boolean;
};

function useQuery<T extends TimeSeriesQuery>(
  query: T,
  options?: TimeSeriesQueryOptions,
): TimeSeriesResponse<T> {
  const key = query.resources.length ? query : null;
  const { fetchSerially, ...swrOptions } = options ?? {};
  const fetcher = fetchSerially ? fetchQuerySerially : fetchQuery;
  const response = useSWR(key, { fetcher, ...swrOptions });
  const mergedData = useMemo(() => mergeQueryResult(response.data), [response.data]);
  return { ...response, data: mergedData as TimeSeriesResponse<T>['data'] };
}

export type TimeSeriesQueryOptions = {
  refreshInterval?: number;
  revalidateOnFocus?: boolean;
  fetchSerially?: boolean;
};

export function useTimeseriesRange(query: TimeSeriesRangeQuery, options?: TimeSeriesQueryOptions) {
  return useQuery(query, options);
}

export function useTimeseriesAggregate(query: TimeSeriesAggregateQuery) {
  return useQuery(query);
}

export function useTimeseriesLatestForecast(query: TimeSeriesLatestForecastQuery) {
  return useQuery(query);
}

/**
 * Executes a TimeSeriesQuery of any type, and returns the result
 * received from the server as-is, without any post-processing applied.
 * Does not not provide the same level of type safety as the other timeseries hooks.
 * This hook should only be used when the query type may dynamically change,
 * and the result is required to have the same structure regardless of the query type.
 */
export function useTimeseriesRaw(query: TimeSeriesQuery | null) {
  const key = query?.resources.length ? query : null;
  return useSWR<RawQueryResult>(key, { fetcher: fetchQuery });
}
