import useSWR, { preload } from 'swr';
import { useMemo } from 'react';

import { type FetchHookData, type MeteringGroup } from '~types';

import { fetcher } from '~http';

import { collator } from '~utils/localization';

export async function preloadMeteringGroupList() {
  try {
    return await preload('/v1/metering_group', fetcher);
  } catch {
    return null;
  }
}

export function useMeteringGroupList(): FetchHookData<MeteringGroup[]> {
  const { data, error, isLoading, mutate } = useSWR<MeteringGroup[]>('/v1/metering_group', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const sortedData = useMemo(
    () => (data != null ? [...data].sort((a, b) => collator.compare(a.name, b.name)) : undefined),
    [data],
  );

  return {
    data: sortedData,
    error,
    isLoading,
    mutate,
  };
}

export function useMeteringGroup(meteringGroupId: string | null) {
  const { data, error, isLoading } = useSWR<MeteringGroup[]>('/v1/metering_group', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const group = useMemo(() => {
    if (data == null) return undefined;
    if (meteringGroupId == null) return null;
    return data.find((group) => group.id === meteringGroupId) ?? null;
  }, [data, meteringGroupId]);

  return { data: group, error, isLoading };
}
