import useSWR, { preload } from 'swr';

import type { FetchHookData, Region } from '~types';

import { fetcher } from '~http';

type FleetRegions = {
  entsoe_ca: Region[];
  fcr: Region[];
};

export async function preloadFleetRegions(fleetId: string): Promise<FleetRegions | null> {
  try {
    return await preload(`/v1/fleet/${fleetId}/region?region_types=entsoe_ca,fcr`, fetcher);
  } catch {
    return null;
  }
}

export function useFleetRegions(fleetId: string): FetchHookData<FleetRegions> {
  return useSWR<FleetRegions>(`/v1/fleet/${fleetId}/region?region_types=entsoe_ca,fcr`, {
    fetcher,
  });
}
