import { type KeyboardEvent, useCallback, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import createFuzzySearch from '@nozbe/microfuzz';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  useTheme,
} from '@mui/material';

import type { BreadcrumbType } from '~types';

import { useOrganizationList } from '~hooks/useOrganizationList';
import { useEdgeControllerList } from '~hooks/useEdgeControllerList';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';
import { useESUList } from '~hooks/useESUList';
import { useFleetList } from '~hooks/useFleets';
import { useIsRoutePermitted } from '~hooks/useIsRoutePermitted';

import { HierarchyIcon } from '~components/hierarchy/HierarchyIcon';

type CommandPaletteEntry = {
  id: string;
  name: string;
  type: BreadcrumbType;
};

type Props = {
  onClose: () => void;
};

export function CommandPalette({ onClose }: Props) {
  const listRef = useRef<HTMLUListElement>(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState(0);

  const { data: fleets = [] } = useFleetList();
  const { data: organizations = [] } = useOrganizationList();
  const { data: meteringGroups = [] } = useMeteringGroupList();
  const { data: edgeControllers = [] } = useEdgeControllerList();
  const { data: esus = [] } = useESUList();

  const canViewFleets = useIsRoutePermitted('/v2/fleets');
  const canViewOrganizations = useIsRoutePermitted('/v2/organizations');
  const canViewSites = useIsRoutePermitted('/v2/sites');
  const canViewControllers = useIsRoutePermitted('/v2/controllers');
  const canViewESUs = useIsRoutePermitted('/v2/esus');

  const entries = useMemo<CommandPaletteEntry[]>(
    () => [
      ...(canViewFleets
        ? fleets
            .map<CommandPaletteEntry>((fleet) => ({
              id: fleet.id,
              name: fleet.name,
              type: 'fleets',
            }))
            .concat({
              id: 'fleets',
              type: 'other',
              name: 'Fleets',
            })
        : []),
      ...(canViewOrganizations
        ? organizations
            .map<CommandPaletteEntry>((organization) => ({
              id: organization.id,
              name: organization.human_name,
              type: 'organizations',
            }))
            .concat({
              id: 'organizations',
              type: 'other',
              name: 'Organizations',
            })
        : []),
      ...(canViewSites
        ? meteringGroups
            .map<CommandPaletteEntry>((meteringGroup) => ({
              id: meteringGroup.id,
              name: meteringGroup.name,
              type: 'sites',
            }))
            .concat({
              id: 'sites',
              type: 'other',
              name: 'Sites',
            })
        : []),
      ...(canViewControllers
        ? edgeControllers
            .map<CommandPaletteEntry>((edgeController) => ({
              id: edgeController.id,
              name: edgeController.name,
              type: 'controllers',
            }))
            .concat({
              id: 'controllers',
              type: 'other',
              name: 'Controllers',
            })
        : []),
      ...(canViewESUs
        ? esus
            .map<CommandPaletteEntry>((esu) => ({
              id: esu.id,
              name: esu.name,
              type: 'esus',
            }))
            .concat({
              id: 'esus',
              type: 'other',
              name: 'ESUs',
            })
        : []),
    ],
    [
      canViewFleets,
      fleets,
      canViewOrganizations,
      organizations,
      canViewSites,
      meteringGroups,
      canViewControllers,
      edgeControllers,
      canViewESUs,
      esus,
    ],
  );
  const fuzzySearch = useMemo(
    () =>
      createFuzzySearch(entries, {
        getText: (item) => [item.name, item.id],
      }),
    [entries],
  );
  const results = useMemo(
    () => sortEntries(fuzzySearch(search).map((x) => x.item)),
    [search, fuzzySearch],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();

        const nextSelected = (selected + 1) % results.length;

        setSelected(nextSelected);
        scrollIfNotVisible(results[nextSelected].id, listRef.current!, 'end');
      }
      if (event.key === 'ArrowUp') {
        event.preventDefault();

        const nextSelected = (selected - 1 + results.length) % results.length;

        setSelected(nextSelected);
        scrollIfNotVisible(results[nextSelected].id, listRef.current!, 'start');
      }
      if (event.key === 'Enter' && results[selected]) {
        navigate(createEntryLink(results[selected]));
        onClose();
      }
    },
    [results, selected, onClose, navigate],
  );

  return (
    <Box
      mx="auto"
      width="min(90vw, 400px)"
      display="flex"
      flexDirection="column"
      bgcolor="Background"
    >
      <TextField
        fullWidth
        autoFocus
        spellCheck="false"
        value={search}
        onChange={(event) => {
          setSearch(event.target.value);
          setSelected(0);
        }}
        onKeyDown={handleKeyDown}
        placeholder="Search anything.."
      />
      <List
        disablePadding
        ref={listRef}
        sx={{
          maxHeight: 'min(528px, calc(100vh - 128px))',
          overflow: 'auto',
        }}
      >
        {results.map((entry, i) => (
          <Link
            key={entry.id + entry.type}
            id={entry.id}
            to={createEntryLink(entry)}
            onClick={onClose}
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <ListItem
              sx={{
                backgroundColor: i === selected ? theme.palette.action.selected : 'transparent',
                ':hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemIcon>
                <HierarchyIcon breadcrumbType={entry.type} />
              </ListItemIcon>
              <ListItemText primary={entry.name} />
              {search === entry.id && <Box fontSize={12}>{entry.id}</Box>}
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );
}

const entryTypesSortingOrder: BreadcrumbType[] = [
  'other',
  'fleets',
  'organizations',
  'sites',
  'controllers',
  'esus',
];

function sortEntries(entries: CommandPaletteEntry[]) {
  return entries.sort((a, b) => {
    if (a.name === b.name) {
      return entryTypesSortingOrder.indexOf(a.type) - entryTypesSortingOrder.indexOf(b.type);
    }

    return a.name.localeCompare(b.name);
  });
}

function createEntryLink(entry: CommandPaletteEntry) {
  if (entry.type === 'other') return `/v2/${entry.id}`;

  return `/v2/${entry.type}/${entry.id}`;
}

function scrollIfNotVisible(
  elementId: string,
  list: HTMLUListElement,
  block: ScrollLogicalPosition,
) {
  if (!list) return;

  const element = document.getElementById(elementId);

  if (!element) return;

  const rect = element.getBoundingClientRect();
  const listRect = list.getBoundingClientRect();
  const isVisible =
    rect.top >= listRect.top &&
    rect.bottom <= listRect.bottom &&
    rect.left >= listRect.left &&
    rect.right <= listRect.right;

  if (isVisible) return;

  element.scrollIntoView({
    block,
    behavior: 'instant',
  });
}
