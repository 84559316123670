import { Box, Breadcrumbs as MuiBreadcrumbs, styled } from '@mui/material';

import { useBreadcrumbs } from '~hooks/useBreadcrumbs';

import { Breadcrumb } from '~components/breadcrumbs/Breadcrumb';
import { BreadcrumbsNavigation } from '~components/breadcrumbs/BreadcrumbsNavigation';

type Props = {
  hasIcon?: boolean;
  hasNavigation?: boolean;
};

const StyledBreadcrumbs = styled(MuiBreadcrumbs)({
  flexShrink: 0,
  '.MuiBreadcrumbs-ol': {
    flexWrap: 'nowrap',
  },
  '.MuiBreadcrumbs-separator': {
    flexShrink: 0,
  },
  '.MuiBreadcrumbs-li': {
    flexShrink: 0,
  },
});

export function Breadcrumbs({ hasIcon = false, hasNavigation = false }: Props) {
  const { breadcrumbs } = useBreadcrumbs();

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      sx={{
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <StyledBreadcrumbs>
        {breadcrumbs.map((breadcrumb) => (
          <Breadcrumb
            key={breadcrumb.path}
            breadcrumb={breadcrumb}
            isLast={breadcrumb === breadcrumbs[breadcrumbs.length - 1]}
            hasIcon={hasIcon}
          />
        ))}
      </StyledBreadcrumbs>
      {hasNavigation && <BreadcrumbsNavigation />}
    </Box>
  );
}
