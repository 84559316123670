import { useMediaQuery } from '@mui/material';
import { DEFAULT_DESKTOP_MODE_MEDIA_QUERY } from '@mui/x-date-pickers';

/**
 * Based on the same media query that the MUI DateTimePicker component uses, returns the viewRenderers prop,
 * such that the time picker is not rendered on desktop devices.
 * On mobile returns undefiend as setting some of the renderers to null for MobileDatePicker will make it impossible to select a time.
 * https://github.com/mui/mui-x/blob/v7.23.5/packages/x-date-pickers/src/DateTimePicker/DateTimePicker.tsx#L43
 */
export function useDateTimePickerViewRenderers() {
  const isDesktop = useMediaQuery(DEFAULT_DESKTOP_MODE_MEDIA_QUERY, { defaultMatches: true });
  return isDesktop ? { hours: null, minutes: null, seconds: null } : undefined;
}
