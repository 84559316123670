import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';

import { PageLayout } from '~layouts/PageLayout';

import { TimeSeriesExplorer } from '~pages/experimental/explorer/TimeSeriesExplorer';

import { ScrollView } from '~components/ScrollView';

export function Explorer() {
  useRegisterBreadcrumbs('other', 'Timeseries Explorer', '/explorer');

  return (
    <PageLayout title="Timeseries Explorer">
      <ScrollView>
        <TimeSeriesExplorer />
      </ScrollView>
    </PageLayout>
  );
}
