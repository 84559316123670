import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { asCactosError, http } from '~http';

import { ESU_MODELS } from '~constants/esuModels';

import { useCurrentEdgeControllerId } from '~hooks/useCurrentEdgeControllerId';
import { useEdgeController } from '~hooks/useEdgeController';

import { ErrorContainer } from '~components/ErrorContainer';

type Props = {
  localId: string | null;
  onClose: () => void;
};

type CreateESUModalProps = {
  name: string;
  localId: string;
  modelName: string;
};

const AVAILABLE_ESU_MODELS = ESU_MODELS.filter((esuModel) => esuModel.version === 2);

const CreateESUSchema = Yup.object().shape({
  name: Yup.string().min(1).max(100).required('Required'),
  modelName: Yup.string()
    .oneOf(AVAILABLE_ESU_MODELS.map((esuModel) => esuModel.name))
    .required('Required'),
});

export function MapESUModal({ localId, onClose }: Props) {
  const id = useCurrentEdgeControllerId();
  const { data: edgeController, mutate } = useEdgeController(id);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(
    async (name: string, modelName: string) => {
      if (isLoading) return;

      setIsLoading(true);
      setError(null);

      try {
        await http.post(`/v1/edge_controller/${id}/esu`, {
          name,
          model_name: modelName,
          local_id: localId,
        });
        await mutate();

        handleClose();
      } catch (error: unknown) {
        setError(asCactosError(error).message);
      }

      setIsLoading(false);
    },
    [localId, isLoading, id, mutate, handleClose],
  );

  return (
    <Dialog open={!!localId} onClose={handleClose}>
      <DialogTitle>
        Register ESU {localId} in controller {edgeController?.name}
      </DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <Formik
            initialValues={{ name: '', modelName: '' }}
            onSubmit={async (values, { setSubmitting }) => {
              await handleSubmit(values.name, values.modelName);
              setSubmitting(false);
            }}
            validationSchema={CreateESUSchema}
          >
            {({ values, handleChange, handleBlur }) => (
              <>
                <Form id="create-esu-form">
                  <FormControl fullWidth>
                    <TextField
                      value={values.name}
                      name="name"
                      label="Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                  <ErrorMessage name="name" component={ErrorContainer} />
                  <Box mt={2}>
                    <FormControl fullWidth>
                      <InputLabel id="model-name-label">Model</InputLabel>
                      <Select
                        name="modelName"
                        labelId="model-name-label"
                        label="Model"
                        value={values.modelName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {AVAILABLE_ESU_MODELS.map((esuModel) => (
                          <MenuItem key={esuModel.name} value={esuModel.name}>
                            {esuModel.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <ErrorMessage name="modelName" component={ErrorContainer} />
                  </Box>
                </Form>
                {!!error && (
                  <Box marginTop={2} color="red">
                    <ErrorContainer>{error}</ErrorContainer>
                  </Box>
                )}
                <MapESUModalPreview
                  name={values.name}
                  modelName={values.modelName}
                  localId={localId ?? ''}
                />
              </>
            )}
          </Formik>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton type="submit" form="create-esu-form" loading={isLoading}>
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function MapESUModalPreview({ name, modelName, localId }: CreateESUModalProps) {
  return (
    <Box
      sx={{
        marginTop: 2,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 2,
        paddingRight: 2,
        borderRadius: 1,
        background: 'rgba(0, 0, 0, 0.2)',
      }}
      display="flex"
      flexDirection="column"
      gap={0.5}
    >
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        ESU name:
      </Typography>
      <Typography variant="body2">{name || <>&nbsp;</>}</Typography>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        Model
      </Typography>
      <Typography variant="body2">
        {AVAILABLE_ESU_MODELS.find((esuModel) => esuModel.name === modelName)?.label || <>&nbsp;</>}
      </Typography>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        Local ID
      </Typography>
      <Typography variant="body2">{localId}</Typography>
    </Box>
  );
}
