import { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Decimal from 'decimal.js';

import { type Fleet, type Region } from '~types';

import { asCactosError } from '~http';

import { currentTimeZone, formatDateTimeNoTimeZone } from '~utils/time';

import { type ResourceID, useTimeseriesLatest } from '~hooks/timeseries';
import { createLatestQuery } from '~hooks/timeseries/queries';
import { useFleetList } from '~hooks/useFleets';
import { useLatestAllocation } from '~hooks/useMultiMarketSnapshot';
import { useFleetRegions } from '~hooks/useFleetRegions';

import { BidsAndAllocations } from '~pages/v2/fleets/components/BidsAndAllocations';
import { UploadBids } from '~pages/v2/fleets/components/UploadBids';
import { MonthlyAllocationsCard } from '~pages/v2/fleets/components/MonthlyAllocationValuesCard';

import { FCRGraph } from '~components/charts/FCRGraph';

import { StyledToaster } from '../../../components/StyledToaster';

const BIDDING_DOMAIN = '10YFI-1--------U';

export function FCR() {
  const [fleetId, setFleetId] = useState<string>('cff-btm');
  const { data: regionsData, error: regionsError } = useFleetRegions(fleetId);
  const reserveObjects = regionsData?.fcr ?? [];

  const { data: fleets } = useFleetList();

  return (
    <Container maxWidth="xl">
      <Typography variant="h3">FCR-N</Typography>
      <Stack spacing={2} mt={2} mb={2} direction="column">
        {regionsError != null && (
          <Alert severity="error">
            Failed to load list of reserve objects: {asCactosError(regionsError).message}
          </Alert>
        )}

        <FCRGraph fleetId={fleetId} reserveObjects={reserveObjects} />

        <CurrentStatusCard fleetId={fleetId} reserveObjects={reserveObjects} />

        <MonthlyAllocationsCard fleetId={fleetId} />

        <Card>
          <CardContent>
            <Box display="flex" gap={1} rowGap={2} my={2} flexWrap="wrap">
              <FormControl>
                <InputLabel id="fleet-select-label">Select Fleet</InputLabel>
                <Select
                  labelId="fleet-select-label"
                  value={fleetId}
                  label="Select Fleet"
                  onChange={(e) => setFleetId(e.target.value)}
                  sx={{ width: 260 }}
                >
                  {fleets?.map((fleet: Fleet) => (
                    <MenuItem key={fleet.id} value={fleet.id}>
                      {fleet.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </CardContent>
        </Card>

        <UploadBids biddingDomain={BIDDING_DOMAIN} fleetId={fleetId} />

        <BidsAndAllocations fleetId={fleetId} />
      </Stack>

      <StyledToaster />
    </Container>
  );
}

export const CurrentStatusCard = ({
  fleetId,
  reserveObjects,
}: {
  fleetId: string;
  reserveObjects: Region[];
}) => {
  const { data: fleetList } = useFleetList();
  const fleet = fleetList?.find((fleet) => fleet.id === fleetId);
  const [atTime, setAtTime] = useState<Date>(() => new Date());
  useEffect(() => {
    const interval = setInterval(() => setAtTime(new Date()), 60_000);
    return () => clearInterval(interval);
  }, []);
  const {
    data: currentAllocation,
    isLoading: loadingAllocation,
    error: allocationError,
  } = useLatestAllocation(fleetId, atTime);

  const {
    data: activationsResult,
    isLoading: loadingActivations,
    error: activationsError,
  } = useTimeseriesLatest(
    createLatestQuery({
      resources: reserveObjects.map(({ id }) => `region:${id}` as ResourceID),
      columns: { fcr_reporting_region: ['maintained_capacity'] },
      atTime: 'now',
      start: { hours: 1 },
    }),
  );

  const allActivations = reserveObjects
    .map(({ id }) => activationsResult?.[`region:${id}`]?.fcr_reporting_region?.maintained_capacity)
    .filter((capacity): capacity is number => Number.isFinite(capacity));
  const totalActivationCapacity =
    allActivations.length > 0 ? allActivations.reduce((acc, num) => acc + num, 0) : null;

  const isLoading = loadingAllocation || loadingActivations;

  return (
    <Card>
      <CardHeader
        title={
          <>Latest operation status {isLoading && <CircularProgress size={16} sx={{ ml: 1 }} />}</>
        }
      />
      <CardContent sx={{ overflowX: 'auto' }}>
        {allocationError != null && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Error loading allocation data: {asCactosError(allocationError).message}
          </Alert>
        )}
        {activationsError != null && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Error loading activation data: {asCactosError(activationsError).message}
          </Alert>
        )}
        <TableContainer sx={{ minWidth: 900 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Associated resource</TableCell>
                <TableCell>
                  <div>Time</div>
                  <div>({currentTimeZone()})</div>
                </TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Latest allocation</TableCell>
                <TableCell>
                  {fleet != null && (
                    <>
                      {fleet.id} ({fleet.name})
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {currentAllocation != null
                    ? formatDateTimeNoTimeZone(currentAllocation.start_time)
                    : 'N/A'}
                </TableCell>
                <TableCell>
                  {currentAllocation != null &&
                  new Decimal(currentAllocation.allocation_in_kw).isFinite()
                    ? `${new Decimal(currentAllocation.allocation_in_kw).div(1000).toFixed(1)} MW`
                    : 'N/A'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Total reported activation capacity</TableCell>
                <TableCell>
                  {fleet != null && (
                    <>
                      {fleet.id} ({fleet.name})
                    </>
                  )}
                </TableCell>
                <TableCell>-</TableCell>
                <TableCell>
                  {totalActivationCapacity != null
                    ? `${totalActivationCapacity / 1_000_000} MW`
                    : 'N/A'}
                </TableCell>
              </TableRow>

              {reserveObjects.map((reserveObject) => {
                const row = activationsResult?.[`region:${reserveObject.id}`]?.fcr_reporting_region;
                return (
                  <TableRow key={reserveObject.id}>
                    <TableCell>Reported activation capacity</TableCell>
                    <TableCell>
                      {reserveObject.id} ({reserveObject.name})
                    </TableCell>
                    <TableCell>
                      {row != null ? formatDateTimeNoTimeZone(row.time) : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {row?.maintained_capacity != null
                        ? `${row.maintained_capacity / 1_000_000} MW`
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
