// TODO
// - [x] separate into more files
// - [x] add to sidebar/navigation, remember to set permissions, should not be visible to customers
// - [x] better time selector -> 2 date selectors, latest query start time should be optional
// - [x] limit max query time range
// - [x] debounce querying
// - [x] add resource types, truncate auto-complete options to around 200 (?)
// - [x] plotting, multiple plots
// - [x] allow hiding chart variable controls
// - [ ] multiple queries
// - [ ] large queries to infrequent series should be allowed
// - [ ] investigate ResultCard lag

import { type HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { differenceInDays, subHours } from 'date-fns';
import useSWR from 'swr';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { type Dayjs } from 'dayjs';

import { fetcher } from '~http';

import { collator } from '~utils/localization';

import { useEdgeControllerList } from '~hooks/useEdgeControllerList';
import { useESUList } from '~hooks/useESUList';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';
import { type ResourceID } from '~hooks/timeseries';
import {
  createAggregateQuery,
  createLatestForecastQuery,
  createLatestQuery,
  createRangeQuery,
  useTimeseriesRaw,
} from '~hooks/timeseries/queries';
import { type TimeSeriesQuery } from '~hooks/timeseries/types';
import { useDateTimePickerViewRenderers } from '~hooks/useDateTimePickerViewRenderers';

import { Page } from '~components/Page';
import { Iconify } from '~components/Iconify';

import { EditableChart } from './EditableChart';
import { ResultCard } from './ResultCard';

export type QueryVariant = 'latest' | 'range' | 'aggregate' | 'latest_forecast';

export type TimeseriesSchemas = {
  [resourceType: string]: {
    [seriesName: string]: {
      columns: {
        [columnName: string]: 'int' | 'float' | 'string' | 'timestamp' | 'decimal';
      };
    };
  };
};

const AUTOCOMPLETE_MAX_OPTIONS = 100;

export function TimeSeriesExplorer() {
  const { data: controllers = [] } = useEdgeControllerList();
  const { data: esus = [] } = useESUList();
  const { data: groups = [] } = useMeteringGroupList();
  const { data: schemas = {} } = useSWR<TimeseriesSchemas>('/v2/timeseries/schemas', {
    fetcher,
    revalidateOnFocus: false,
  });

  const displayNameForResource: Map<string, string> = useMemo(() => {
    const controllerNames = new Map(controllers.map((ec) => [ec.id, ec.name]));
    const energyMeters = groups
      .flatMap((group) =>
        group.grid_meters
          .filter((meter) => meter.series === 'energy_meter')
          .map((meter) => {
            const controllerId = meter.edge_controller_id?.split(':')[1];
            if (controllerId == null)
              return [meter.resource_id, meter.resource_id.replace(/^.*?:/, '')];
            const ecName = controllerNames.get(controllerId) ?? meter.edge_controller_id!;
            const meterName = meter.resource_id.replace(/^.*?\./, '');
            return [meter.resource_id, `${ecName} ${meterName}`];
          }),
      )
      .sort((a, b) => collator.compare(a[1], b[1]));
    const pairs = [
      ...esus.map((esu) => [esu.resource_id, esu.name]),
      ...groups.map((group) => [`metering_group:${group.id}`, group.name]),
      ...energyMeters,
    ];
    return new Map(pairs as [string, string][]);
  }, [esus, groups, controllers]);

  const knownResources: string[] = Array.from(displayNameForResource.keys());

  const { knownColumns, aggregateColumns } = useMemo(() => {
    // don't worry about it
    const columns: string[] = Object.values(schemas).flatMap((type) =>
      Object.entries(type).flatMap(([seriesName, seriesData]) => [
        `${seriesName}.*`,
        ...Object.keys(seriesData.columns).map((column) => `${seriesName}.${column}`),
      ]),
    );

    const knownColumns = Array.from(new Set(columns)).sort();

    const aggregateColumns = knownColumns.flatMap((column) =>
      column.endsWith('.*')
        ? []
        : [`${column}:mean`, `${column}:min`, `${column}:max`, `${column}:sum`],
    );

    return { knownColumns, aggregateColumns };
  }, [schemas]);

  const [endTime, setEndTime] = useState<Date>(new Date());
  const [startTime, setStartTime] = useState<Date>(subHours(endTime, 1));
  const [queryVariant, setQueryVariant] = useState<QueryVariant>('range');
  const [latestQueryStartDefined, setLatestQueryStartDefined] = useState<boolean>(true);
  const [aggregateTimeStep, setAggregateTimeStep] = useState<number | null>(60);
  const [selectedResources, setSelectedResources] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const { query = null, parsingError = null } = useMemo(() => {
    try {
      return {
        query: createQuery(
          selectedResources,
          selectedColumns,
          queryVariant,
          startTime,
          endTime,
          latestQueryStartDefined,
          aggregateTimeStep,
        ),
      };
    } catch (e) {
      return {
        parsingError: e instanceof Error ? e.message : 'Unknown error',
      };
    }
  }, [
    selectedResources,
    selectedColumns,
    queryVariant,
    startTime,
    endTime,
    latestQueryStartDefined,
    aggregateTimeStep,
  ]);

  const [debouncedQuery, setDebouncedQuery] = useState<TimeSeriesQuery | null>(null);
  const lastEditTimeStamp = useRef<number>(0);

  useEffect(() => {
    const now = Date.now();
    const msSinceLastEdit = now - lastEditTimeStamp.current;
    lastEditTimeStamp.current = now;
    // don't debounce if the new query is not valid anyways or
    // if it's been a while since the last edit
    if (query == null || msSinceLastEdit > 2_000) {
      setDebouncedQuery(query);
      return undefined;
    }
    const timeout = setTimeout(() => setDebouncedQuery(query), 2_000);
    return () => clearTimeout(timeout);
  }, [query]);

  const { data, isLoading, error } = useTimeseriesRaw(debouncedQuery);

  // we want give more information about the error than asCactosError lets through
  const errorMessage = (() => {
    if (error == null) return null;
    const message = error?.response?.data?.exception?.message;
    if (typeof message === 'string') return message;
    if (typeof error?.message === 'string') return error.message;
    return 'Unknown error';
  })();

  const createChartKey = () => `chart-${new Date().getTime()}`;
  const [chartKeys, setChartKeys] = useState<string[]>(() => []);
  const queryIsLatest = queryVariant === 'latest' || queryVariant === 'latest_forecast';

  const viewRenderers = useDateTimePickerViewRenderers();

  return (
    <Page title="Time Series Explorer">
      <Container maxWidth="xl">
        <Stack direction="column" spacing={2} marginBottom={2}>
          <Typography variant="h4">Timeseries Explorer</Typography>

          <Card>
            <Stack direction="column" margin={2} spacing={2}>
              <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" gap={2}>
                <Box
                  display="flex"
                  gap={1}
                  alignItems="center"
                  sx={{ minWidth: 250, flexBasis: 250, flexGrow: 1 }}
                >
                  {queryIsLatest && (
                    <Tooltip title="Include start time in query" placement="top">
                      <Checkbox
                        checked={latestQueryStartDefined}
                        onChange={(event) => setLatestQueryStartDefined(event.target.checked)}
                      />
                    </Tooltip>
                  )}

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Start time"
                      value={dayjs(startTime)}
                      onChange={(value: Dayjs | null): void => {
                        if (value != null) setStartTime(value.toDate());
                      }}
                      disabled={queryIsLatest && !latestQueryStartDefined}
                      ampm={false}
                      format="YYYY-MM-DD HH:mm:ss"
                      viewRenderers={viewRenderers}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error:
                            !(queryIsLatest && !latestQueryStartDefined) &&
                            !Number.isFinite(startTime.getTime()),
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>

                <Box
                  display="flex"
                  gap={1}
                  alignItems="center"
                  sx={{ minWidth: 250, flexBasis: 250, flexGrow: 1 }}
                >
                  <Tooltip
                    title={`Set ${queryIsLatest ? 'at time' : 'end time'} to current time`}
                    placement="top"
                  >
                    <IconButton onClick={() => setEndTime(new Date())}>
                      <Iconify icon="mdi:recent" />
                    </IconButton>
                  </Tooltip>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label={queryIsLatest ? 'At time' : 'End time'}
                      value={dayjs(endTime)}
                      onChange={(value: Dayjs | null): void => {
                        if (value != null) setEndTime(value.toDate());
                      }}
                      ampm={false}
                      format="YYYY-MM-DD HH:mm:ss"
                      viewRenderers={viewRenderers}
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  </LocalizationProvider>
                </Box>

                <TextField
                  label="Query Variant"
                  select
                  value={queryVariant}
                  onChange={(event) => {
                    setQueryVariant(event.target.value as QueryVariant);
                  }}
                  sx={{ minWidth: 180, flexBasis: 180, flexGrow: 2 }}
                >
                  <MenuItem value="latest">Latest</MenuItem>
                  <MenuItem value="range">Range</MenuItem>
                  <MenuItem value="aggregate">Aggregate</MenuItem>
                  <MenuItem value="latest_forecast">Latest forecast</MenuItem>
                </TextField>

                {queryVariant === 'aggregate' && (
                  <TextField
                    label="Aggregate Step"
                    InputProps={{ endAdornment: <InputAdornment position="end">s</InputAdornment> }}
                    error={aggregateTimeStep == null}
                    defaultValue="60"
                    onChange={(event) => {
                      const int = parseInt(event.target.value, 10);
                      setAggregateTimeStep(Number.isFinite(int) && int > 0 ? int : null);
                    }}
                    sx={{ minWidth: 180, flexBasis: 180, flexGrow: 2 }}
                  />
                )}

                <CircularProgress
                  style={{
                    visibility: isLoading || debouncedQuery !== query ? 'visible' : 'hidden',
                  }}
                  color={debouncedQuery !== query ? 'info' : 'primary'}
                />
              </Box>

              <Autocomplete
                multiple
                freeSolo
                options={knownResources}
                value={selectedResources}
                onChange={(_, value) => setSelectedResources(value)}
                filterOptions={(options, state) => {
                  const input = state.inputValue.toLowerCase();
                  return options
                    .filter(
                      (option) =>
                        option.includes(input) ||
                        displayNameForResource.get(option)?.toLowerCase().includes(input),
                    )
                    .slice(0, AUTOCOMPLETE_MAX_OPTIONS);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Resources" />
                )}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    const displayName = displayNameForResource.get(option);
                    const label =
                      displayName !== undefined ? `${option.split(':')[0]}:${displayName}` : option;
                    return <Chip variant="outlined" label={label} key={key} {...tagProps} />;
                  })
                }
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props as HTMLAttributes<HTMLLIElement> & {
                    key: string;
                  };
                  return (
                    <Box key={key} component="li" {...optionProps}>
                      <Typography variant="data">{option}</Typography>{' '}
                      <Chip label={displayNameForResource.get(option)} sx={{ ml: 2 }} />
                    </Box>
                  );
                }}
              />

              <Autocomplete
                freeSolo
                multiple
                options={queryVariant === 'aggregate' ? aggregateColumns : knownColumns}
                value={selectedColumns}
                onChange={(_, value) => setSelectedColumns(value)}
                filterOptions={(options, state) => {
                  const input = state.inputValue.toLowerCase();
                  return options
                    .filter((option) => option.toLowerCase().includes(input))
                    .slice(0, AUTOCOMPLETE_MAX_OPTIONS);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Timeseries" />
                )}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        variant="outlined"
                        label={<Typography variant="data">{option}</Typography>}
                        key={key}
                        {...tagProps}
                      />
                    );
                  })
                }
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props as HTMLAttributes<HTMLLIElement> & {
                    key: string;
                  };
                  const [series, column] = option.split('.', 2);
                  return (
                    <Box key={key} component="li" {...optionProps}>
                      <Typography variant="data">
                        {series}.{column}
                      </Typography>
                    </Box>
                  );
                }}
              />
            </Stack>
          </Card>

          {parsingError != null && (
            <Alert severity="error">Error parsing query: {parsingError}</Alert>
          )}

          {errorMessage != null && (
            <Alert severity="error">Error completing query: {errorMessage}</Alert>
          )}

          {chartKeys.map((key) => (
            <EditableChart
              key={key}
              results={data?.results ?? []}
              displayNameForResource={displayNameForResource}
              onRemove={() => setChartKeys((prev) => prev.filter((k) => k !== key))}
            />
          ))}

          <Button
            fullWidth
            variant="outlined"
            onClick={() => setChartKeys((prev) => [...prev, createChartKey()])}
          >
            Add chart
          </Button>

          {(data?.results ?? []).map((result) => (
            <ResultCard
              key={`${result.resource}-${result.series}`}
              result={result}
              query={debouncedQuery}
              schemas={schemas}
              displayNameForResource={displayNameForResource}
            />
          ))}
        </Stack>
      </Container>
    </Page>
  );
}

function createQuery(
  resources: string[],
  columns: string[],
  queryVariant: QueryVariant,
  start: Date,
  end: Date,
  latestQueryStartDefined: boolean,
  aggregateTimeStep: number | null,
): TimeSeriesQuery {
  if (!Number.isFinite(end.getTime())) throw new Error('Invalid at/end time');
  if (
    !(queryVariant === 'latest' || queryVariant === 'latest_forecast') ||
    latestQueryStartDefined
  ) {
    // checks relevant for queries where start time is defined
    if (!Number.isFinite(start.getTime())) throw new Error('Invalid start time');
    if (start.getTime() >= end.getTime()) throw new Error('Start time should be before end time');
    if (
      (queryVariant === 'range' && end.getTime() - start.getTime() > (24 * 60 + 1) * 60_000) ||
      (queryVariant === 'aggregate' && differenceInDays(end, start) > 30)
    )
      throw new Error('Time range too large');
  }

  const resourceIDs: ResourceID[] = resources.map((resource) => {
    const [type, id, ...rest] = resource.split(':');
    if (!type || !id || rest.length > 0) throw new Error(`Invalid resource: '${resource}'`);
    return `${type}:${id}` as ResourceID;
  });

  const columnsBySeries: { [series: string]: string[] } = {};
  for (const column of columns) {
    const [seriesName, columnName, ...rest] = column.split('.');
    if (!seriesName || !columnName || rest.length > 0) throw new Error(`Invalid column: ${column}`);
    columnsBySeries[seriesName] = columnsBySeries[seriesName] ?? [];
    columnsBySeries[seriesName].push(columnName);
  }

  if (resourceIDs.length === 0) throw new Error('No resources selected');
  if (Object.keys(columnsBySeries).length === 0) throw new Error('No columns selected');

  switch (queryVariant) {
    case 'latest':
      return createLatestQuery({
        resources: resourceIDs,
        columns: columnsBySeries,
        start: latestQueryStartDefined ? start : undefined,
        atTime: end,
      });
    case 'range':
      return createRangeQuery({
        resources: resourceIDs,
        columns: columnsBySeries,
        start,
        end,
      });
    case 'aggregate':
      if (aggregateTimeStep == null) throw new Error(`Invalid time step for aggregate query`);
      return createAggregateQuery({
        resources: resourceIDs,
        columns: columnsBySeries,
        start,
        end,
        step: `${aggregateTimeStep}s`,
      });
    case 'latest_forecast':
      return createLatestForecastQuery({
        resources: resourceIDs,
        columns: columnsBySeries,
        start: latestQueryStartDefined ? start : undefined,
        atTime: end,
      });
    default:
      throw new Error(`Unknown query variant: ${queryVariant}`);
  }
}
