import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';

import type { Breadcrumb as BreadcrumbType } from '~types';

import { HierarchyIcon } from '~components/hierarchy/HierarchyIcon';

type Props = {
  breadcrumb: BreadcrumbType;
  hasIcon: boolean;
  isLast: boolean;
};

export function Breadcrumb({ breadcrumb, hasIcon, isLast }: Props) {
  const icon = hasIcon ? <HierarchyIcon breadcrumbType={breadcrumb.type} /> : null;

  // The last breadcrumb should not be a link
  if (isLast) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        {icon}
        <Typography>{breadcrumb.label || '...'}</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {icon}
      <Link
        to={breadcrumb.path}
        component={RouterLink}
        sx={{
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {breadcrumb.label || '...'}
      </Link>
    </Box>
  );
}
