import { Link, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import type React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import {
  type MRT_ColumnDef,
  type MRT_ColumnSizingState,
  type MRT_FilterFn,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Alert,
  Box,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';

import { type FleetFilter } from '~types';

import { asCactosError } from '~http';

import { ESU_MODELS } from '~constants/esuModels';

import { collator } from '~utils/localization';

import { type ESUOperationDataRow, useESUOperationData } from '~hooks/useESUOperationData';
import { CurrentESUIdContextProvider, useCurrentESUId } from '~hooks/useCurrentESUId';
import { useLocalStorage } from '~hooks/useLocalStorage';
import { ResultListContextProvider } from '~hooks/useResultListContext';
import { useESU } from '~hooks/useESUList';
import { CurrentEdgeControllerIdContextProvider } from '~hooks/useCurrentEdgeControllerId';

import { SplitLayout } from '~components/SplitLayout';
import { Iconify } from '~components/Iconify';

import { StatusCell, getSortingSeverity, getStatusText } from './StatusCell';

function NameFooter({ table }: { table: MRT_TableInstance<ESUOperationDataRow> }) {
  const rows = useMRT_Rows(table);

  return <>{rows.length} ESUs</>;
}

const rangeFilterFn: MRT_FilterFn<ESUOperationDataRow> = (row, columnId, filterValue) => {
  const value = row.getValue<number | undefined>(columnId);
  if (value == null) return false;
  const [min, max] = filterValue as [string, string];
  const parsedMin = parseFloat(min);
  if (Number.isFinite(parsedMin) && value < parsedMin) return false;
  const parsedMax = parseFloat(max);
  if (Number.isFinite(parsedMax) && value > parsedMax) return false;
  return true;
};

const columns: MRT_ColumnDef<ESUOperationDataRow>[] = [
  {
    header: 'Name',
    id: 'name',
    accessorFn: (row) => row.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 150,
    Footer: NameFooter,
  },
  {
    header: 'Model',
    id: 'model_name',
    accessorFn: (row) =>
      ESU_MODELS.find((esuModel) => esuModel.name === row.model_name)?.label ?? row.model_name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 150,
  },
  {
    header: 'Status',
    id: '@calculated/esu_mode',
    size: 200,
    minSize: 200,
    filterVariant: 'autocomplete',
    accessorFn: (row) => getStatusText(row),
    sortingFn: (a, b, columnId) => {
      const severityDifference = getSortingSeverity(b.original) - getSortingSeverity(a.original);
      if (severityDifference !== 0) {
        return severityDifference;
      } else {
        return collator.compare(a.getValue(columnId), b.getValue(columnId));
      }
    },
    Cell: StatusCell,
  },
  {
    header: 'SoC',
    columns: [
      {
        header: '%',
        id: 'esu_status.soc',
        accessorFn: (row) => {
          const value = row.esu_status?.soc;
          return value != null ? Math.round(value) : undefined;
        },
        filterVariant: 'range',
        filterFn: rangeFilterFn,
        Cell: ({ renderedCellValue }) =>
          renderedCellValue != null ? <>{renderedCellValue}%</> : '',
        size: 80,
      },
    ],
  },
  {
    header: 'Cell temp (°C)',
    columns: [
      {
        header: 'Min',
        id: 'bms_temperatures_min',
        accessorFn: (row) => row.bms_temperatures?.['min(*)'] ?? undefined,
        sortUndefined: 'last',
        filterVariant: 'range',
        filterFn: rangeFilterFn,
        size: 80,
      },
      {
        header: 'Max',
        id: 'bms_temperatures_max',
        accessorFn: (row) => row.bms_temperatures?.['max(*)'] ?? undefined,
        sortUndefined: 'last',
        filterVariant: 'range',
        filterFn: rangeFilterFn,
        size: 80,
      },
    ],
  },
  {
    header: 'Cell voltage (V)',
    columns: [
      {
        header: 'Min',
        id: 'bms_cell_voltages_min',
        accessorFn: (row) =>
          row.bms_cell_voltages?.['min(*)'] != null
            ? // convert mV to V while rounding to 2 decimal places
              Math.round(row.bms_cell_voltages['min(*)'] / 10) / 100
            : undefined,
        sortUndefined: 'last',
        filterVariant: 'range',
        filterFn: rangeFilterFn,
        size: 80,
      },
      {
        header: 'Max',
        id: 'bms_cell_voltages_max',
        accessorFn: (row) =>
          row.bms_cell_voltages?.['max(*)'] != null
            ? // convert mV to V while rounding to 2 decimal places
              Math.round(row.bms_cell_voltages['max(*)'] / 10) / 100
            : undefined,
        sortUndefined: 'last',
        filterVariant: 'range',
        filterFn: rangeFilterFn,
        size: 80,
      },
    ],
  },
  {
    header: 'FCR-N (kW)',
    columns: [
      {
        header: 'Allocated',
        id: 'esu_power_control.fcrn_allocated_cap',
        accessorFn: (row) =>
          row.esu_power_control?.fcrn_allocated_cap != null
            ? (row.esu_power_control?.fcrn_allocated_cap ?? 0) / 1000
            : undefined,
        sortUndefined: 'last',
        size: 120,
      },
      {
        header: 'Maintained',
        id: 'esu_power_control.fcrn_maintained_cap',
        accessorFn: (row) =>
          row.esu_power_control?.fcrn_maintained_cap != null
            ? (row.esu_power_control?.fcrn_maintained_cap ?? 0) / 1000
            : undefined,
        sortUndefined: 'last',
        size: 120,
      },
    ],
  },
];

export function ESUList() {
  const { esuId } = useParams();
  const { search } = useLocation();

  const { data: esu } = useESU(esuId ?? null);

  const match = useMatch('/dashboard/esus/:esuId/:tab');
  const [defaultTab, setDefaultTab] = useLocalStorage<string>(
    'fleet.esus.defaultTab',
    'diagnostics',
  );
  const currentTab = match?.params.tab || defaultTab;
  useEffect(() => {
    if (currentTab !== defaultTab) {
      setDefaultTab(currentTab);
    }
  }, [setDefaultTab, currentTab, defaultTab]);

  const navigate = useNavigate();
  const rowSelection = useMemo(() => (esuId ? { [esuId]: true } : {}), [esuId]);
  const [columnSizing, setColumnSizing] = useLocalStorage<MRT_ColumnSizingState>(
    'fleet.esus.columnSizing',
    {},
  );
  const [fleetFilter, setFleetFilter] = useLocalStorage<FleetFilter>(
    'fleet.esus.fleetFilter',
    'all',
  );
  const onFleetFilterChange = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const { value } = event.currentTarget as HTMLButtonElement;
      setFleetFilter(value as FleetFilter);
    },
    [setFleetFilter],
  );
  const { data, error } = useESUOperationData(fleetFilter);
  const table = useMaterialReactTable<ESUOperationDataRow>({
    data: data ?? [],
    columns,
    initialState: {
      density: 'compact',
    },
    state: {
      rowSelection,
      columnSizing: columnSizing ?? {},
      columnPinning: {
        left: ['name'],
      },
    },
    onColumnSizingChange: setColumnSizing,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableBottomToolbar: false,
    positionToolbarAlertBanner: 'none',
    getRowId: (row) => row.id,
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 120px)' } },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const esuId = row.id;
        if (esuId) {
          navigate(`/dashboard/esus/${esuId}/${currentTab}${search}`);
        } else {
          navigate('/dashboard/esus');
        }
      },
      sx: { cursor: 'pointer' },
    }),
    muiTablePaperProps: { sx: { borderRadius: 0 } },
    muiTableFooterProps: { sx: { minHeight: 60 } },
    renderTopToolbarCustomActions: () => (
      <ToggleButtonGroup
        color="primary"
        value={fleetFilter}
        onChange={onFleetFilterChange}
        size="small"
        exclusive
      >
        <ToggleButton value="all">All</ToggleButton>
        <ToggleButton value="fleet">Fleet</ToggleButton>
      </ToggleButtonGroup>
    ),
    renderEmptyRowsFallback: () =>
      error != null ? (
        <Alert
          severity="error"
          sx={{ borderRadius: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          Error loading ESU data: {asCactosError(error).message}
        </Alert>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body2">
            <i>No ESUs to display</i>
          </Typography>
        </Box>
      ),
  });
  const rows = useMRT_Rows(table);
  const resultListContext = useMemo(() => {
    const currentRowIndex = esuId ? rows.findIndex((row) => row.id === esuId) : -1;
    const totalRowCount = rows.length;
    return {
      currentRowIndex,
      totalRowCount,
      goToNextResult: () => {
        if (currentRowIndex + 1 < totalRowCount) {
          navigate(`/dashboard/esus/${rows[currentRowIndex + 1].id}/${currentTab}${search}`);
        }
      },
      goToPreviousResult: () => {
        if (currentRowIndex > 0) {
          navigate(`/dashboard/esus/${rows[currentRowIndex - 1].id}/${currentTab}${search}`);
        }
      },
      goToResultList: () => navigate('/dashboard/esus'),
    };
  }, [rows, navigate, currentTab, esuId, search]);
  return (
    <CurrentEdgeControllerIdContextProvider value={esu?.edge_controller.id ?? null}>
      <CurrentESUIdContextProvider value={esuId ?? null}>
        <ResultListContextProvider value={resultListContext}>
          <SplitLayout
            showDetails={!!esuId && !!esu} // don't render the details until both id's have been loaded
            renderTitle={() => <Title />}
          >
            <MaterialReactTable table={table} />
          </SplitLayout>
        </ResultListContextProvider>
      </CurrentESUIdContextProvider>
    </CurrentEdgeControllerIdContextProvider>
  );
}

function Title() {
  const id = useCurrentESUId();
  const name = useESU(id).data?.name;
  return (
    <>
      <Typography variant="subtitle1">{name}</Typography>
      <Tooltip title="Open settings" placement="right">
        <IconButton component={Link} to={`/settings/esus/${id}/config`}>
          <Iconify icon="mdi:cog" />
        </IconButton>
      </Tooltip>
    </>
  );
}
