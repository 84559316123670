import { Box, type SxProps, useTheme } from '@mui/material';

import { useSingleESUCurrentMode } from '~hooks/useESUCurrentMode';

import { useESUsNavigation } from '~pages/v2/esus/hooks/useESUsNavigation';

import { Iconify } from '~components/Iconify';

export function ESUStatus() {
  const { esuId } = useESUsNavigation();
  const { data: esuMode } = useSingleESUCurrentMode(esuId);

  if (esuMode == null || esuMode.mode === 'invalid_data') {
    return (
      <Box p={2} display="flex" gap={2} flexWrap="wrap">
        <ESUStatusChip label="" icon={null} sx={{ minWidth: 100 }} />
        <ESUStatusChip label="" icon={null} sx={{ minWidth: 100 }} />
        <ESUStatusChip label="" icon={null} sx={{ minWidth: 100 }} />
        <ESUStatusChip label="" icon={null} sx={{ minWidth: 100 }} />
      </Box>
    );
  }

  const networkOk = esuMode.mode !== 'no_recent_data';
  const gridOk = esuMode.mode === 'no_recent_data' ? null : esuMode.grid_connected;
  const powerOn = (() => {
    switch (esuMode.mode) {
      case 'operating':
        return true;
      case 'powered_off':
        return false;
      case 'no_recent_data':
      default:
        return null;
    }
  })();
  const [status, statusIcon] = (() => {
    if (esuMode.mode !== 'operating') return [null, null];
    if (esuMode.fcr_n.active) return ['FCR-N', 'mdi:sine-wave'];
    if (esuMode.peak_shaving) return ['Peak Shaving', 'mdi:graph-bell-curve'];
    if (esuMode.load_shifting) return ['Load Shifting', 'mdi:battery-charging-50'];
    return ['Idle', 'mdi:check'];
  })();

  return (
    <Box p={2} display="flex" gap={2} flexWrap="wrap">
      <ESUStatusChip
        isError={!networkOk}
        icon={networkOk ? 'mdi:signal' : 'mdi:signal-off'}
        label={networkOk ? 'Cellular OK' : 'Cellular Disconnected'}
      />
      {gridOk != null && (
        <ESUStatusChip
          isError={!gridOk}
          icon={gridOk ? 'mdi:transmission-tower' : 'mdi:transmission-tower-off'}
          label={gridOk ? 'Grid OK' : 'Grid Down'}
        />
      )}
      {powerOn != null && (
        <ESUStatusChip
          isError={!powerOn}
          icon={powerOn ? 'mdi:electric-switch-closed' : 'mdi:electric-switch'}
          label={powerOn ? 'Power On' : 'Power Off'}
        />
      )}
      {status != null && <ESUStatusChip icon={statusIcon} label={status} />}
    </Box>
  );
}

type ESUStatusChipProps = {
  isError?: boolean;
  icon: string | null;
  label: string;
  sx?: SxProps;
};

function ESUStatusChip({ isError = false, icon, label, sx }: ESUStatusChipProps) {
  const theme = useTheme();

  return (
    <Box
      py={1}
      px={1.25}
      display="flex"
      alignItems="center"
      gap={1}
      fontSize={14}
      minHeight="37px"
      color={isError ? theme.palette.error.main : theme.palette.primary.main}
      bgcolor={theme.palette.grey[900]}
      borderRadius={1}
      sx={sx}
    >
      {icon != null && <Iconify icon={icon} />}
      {label}
    </Box>
  );
}
