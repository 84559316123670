import { BidsAndAllocations } from '~pages/v2/fleets/components/BidsAndAllocations';
import { UploadBids } from '~pages/v2/fleets/components/UploadBids';
import { BIDDING_DOMAIN } from '~pages/v2/fleets/constants';
import { FleetLayout } from '~pages/v2/fleets/components/FleetLayout';
import { useFleetsNavigation } from '~pages/v2/fleets/hooks/useFleetsNavigation';

export function FleetsMarket() {
  const { fleetId } = useFleetsNavigation();

  return (
    <FleetLayout>
      <BidsAndAllocations fleetId={fleetId} />
      <UploadBids biddingDomain={BIDDING_DOMAIN} fleetId={fleetId} />
    </FleetLayout>
  );
}
