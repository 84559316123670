import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';

import { PageLayout } from '~layouts/PageLayout';

import { FleetsList } from '~pages/v2/fleets/components/FleetsList';
import { createFleetPath } from '~pages/v2/fleets/utils/createFleetPath';

export function Fleets() {
  useRegisterBreadcrumbs('fleets', 'Fleets', createFleetPath());

  return (
    <PageLayout title="Fleets">
      <FleetsList />
    </PageLayout>
  );
}
