import { Decimal } from 'decimal.js';

import type { MultiMarketSnapshot, ProductType } from '~types';

export type BidRow = {
  start: string;
  quantity_fcr_n: string;
  price_fcr_n: string;
  quantity_fcr_d_up: string;
  price_fcr_d_up: string;
  quantity_fcr_d_down: string;
  price_fcr_d_down: string;
};

export type CrossValidationResult = {
  remainingCapacity: Decimal;
  totalCapacity: Decimal;
  error: { message: string } | null;
};

// FCR-N requires 34% more capacity than the bid quantity because of the NEM headroom.
const FCR_N_NEM_MULTIPLIER = new Decimal(1.34);

function getTotalCapacity(
  snapshot: MultiMarketSnapshot,
  productType: ProductType,
  start: Date,
): Decimal {
  const row = snapshot.data[productType].capacity_totals.data.find(
    (row) => new Date(row.start_time).getTime() === start.getTime(),
  );
  if (row === undefined) {
    throw new Error(`${productType} capacity not found`);
  }

  // TODO: Remove this
  if (row.capacity === undefined) {
    row.capacity = (row as any).max_fcrn_capacity;
  }

  return new Decimal(row.capacity).div(1_000); // Convert to MW
}

const UTC_ISO_STRING_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const EXCESSIVE_BID_ERROR = { message: 'Bid exceeds capacity' };

function tryParseDecimal(value: string, defaultValue: Decimal): Decimal {
  try {
    return new Decimal(value);
  } catch {
    return defaultValue;
  }
}

export function crossValidateBids(
  snapshot: MultiMarketSnapshot,
  row: BidRow,
): Record<ProductType, CrossValidationResult> {
  if (!UTC_ISO_STRING_REGEX.test(row.start)) {
    throw new Error('Start time must be an ISO 8601 string in UTC');
  }
  const start = new Date(row.start);

  const defaultValue = new Decimal(NaN);
  const fcrnQuantity = tryParseDecimal(row.quantity_fcr_n, defaultValue).mul(FCR_N_NEM_MULTIPLIER);
  const fcrdUpQuantity = tryParseDecimal(row.quantity_fcr_d_up, defaultValue);
  const fcrdDownQuantity = tryParseDecimal(row.quantity_fcr_d_down, defaultValue);

  const capacityFcrn = getTotalCapacity(snapshot, 'FI FCR-N', start).mul(FCR_N_NEM_MULTIPLIER);
  const capacityFcrdUp = getTotalCapacity(snapshot, 'FI FCR-D UP', start);
  const capacityFcrdDown = getTotalCapacity(snapshot, 'FI FCR-D DOWN', start);

  // prettier-ignore
  const usedCapacityFcrn = fcrnQuantity
    .plus(Decimal.max(0, fcrdUpQuantity.minus(Decimal.max(0, capacityFcrdUp.minus(capacityFcrn)))))
    .plus(Decimal.max(0, fcrdDownQuantity.minus(Decimal.max(0, capacityFcrdDown.minus(capacityFcrn)))));

  // prettier-ignore
  const usedCapacityFcrdUp = fcrdUpQuantity
    .plus(Decimal.max(0, fcrnQuantity.minus(Decimal.max(0, capacityFcrn.minus(capacityFcrdUp)))))
    .plus(Decimal.max(0, fcrdDownQuantity.minus(Decimal.max(0, capacityFcrdDown.minus(capacityFcrdUp)))));

  // prettier-ignore
  const usedCapacityFcrdDown = fcrdDownQuantity
    .plus(Decimal.max(0, fcrnQuantity.minus(Decimal.max(0, capacityFcrn.minus(capacityFcrdDown)))))
    .plus(Decimal.max(0, fcrdUpQuantity.minus(Decimal.max(0, capacityFcrdUp.minus(capacityFcrdDown)))));

  const remainingCapacityFcrn = capacityFcrn
    .minus(usedCapacityFcrn)
    .div(FCR_N_NEM_MULTIPLIER)
    .toDecimalPlaces(1, Decimal.ROUND_HALF_UP);
  const remainingCapacityFcrdUp = capacityFcrdUp
    .minus(usedCapacityFcrdUp)
    .toDecimalPlaces(1, Decimal.ROUND_HALF_UP);
  const remainingCapacityFcrdDown = capacityFcrdDown
    .minus(usedCapacityFcrdDown)
    .toDecimalPlaces(1, Decimal.ROUND_HALF_UP);

  let fcrnError = null;
  if (!fcrnQuantity.isFinite()) {
    fcrnError = { message: 'Invalid quantity' };
  } else if (remainingCapacityFcrn.lt(0)) {
    fcrnError = EXCESSIVE_BID_ERROR;
  }
  let fcrdUpError = null;
  if (!fcrdUpQuantity.isFinite()) {
    fcrdUpError = { message: 'Invalid quantity' };
  } else if (remainingCapacityFcrdUp.lt(0)) {
    fcrdUpError = EXCESSIVE_BID_ERROR;
  }
  let fcrdDownError = null;
  if (!fcrdDownQuantity.isFinite()) {
    fcrdDownError = { message: 'Invalid quantity' };
  } else if (remainingCapacityFcrdDown.lt(0)) {
    fcrdDownError = EXCESSIVE_BID_ERROR;
  }

  return {
    'FI FCR-N': {
      totalCapacity: capacityFcrn.div(FCR_N_NEM_MULTIPLIER),
      remainingCapacity: remainingCapacityFcrn,
      error: fcrnError,
    },
    'FI FCR-D UP': {
      totalCapacity: capacityFcrdUp,
      remainingCapacity: remainingCapacityFcrdUp,
      error: fcrdUpError,
    },
    'FI FCR-D DOWN': {
      totalCapacity: capacityFcrdDown,
      remainingCapacity: remainingCapacityFcrdDown,
      error: fcrdDownError,
    },
  };
}
