import useSWR from 'swr';

import { type FetchHookData } from '~types';

import { fetcher } from '~http';

import { useAuth } from '~hooks/useAuth';

type UserMfaData = {
  mfa_enabled: boolean;
  new_otp_secret: string;
  new_otp_url: string;
};

export function useUserMfa(): FetchHookData<UserMfaData> {
  const { user } = useAuth();
  return useSWR<UserMfaData>(user ? '/v1/me/mfa' : null, {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });
}
