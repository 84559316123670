import type { BreadcrumbType } from '~types';

import { Iconify } from '~components/Iconify';

type Props = {
  breadcrumbType: BreadcrumbType;
};

export function HierarchyIcon({ breadcrumbType }: Props) {
  switch (breadcrumbType) {
    case 'fleets':
      return <Iconify icon="mdi:sitemap" fontSize={16} />;
    case 'organizations':
      return <Iconify icon="mdi:office-building" fontSize={16} />;
    case 'sites':
      return <Iconify icon="mdi:home-lightning-bolt-outline" fontSize={16} />;
    case 'controllers':
      return <Iconify icon="mdi:server-network-outline" fontSize={16} />;
    case 'esus':
      return <Iconify icon="mdi:battery-charging-50" fontSize={16} />;
    case 'other':
      return <Iconify icon="mdi:view-list" fontSize={16} />;
    default:
      return null;
  }
}
