import { type MouseEvent, type PropsWithChildren, useCallback, useMemo } from 'react';
import {
  type MRT_ColumnDef,
  type MRT_FilterFn,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { type FleetFilter } from '~types';

import { USER_ROLES } from '~constants/auth';
import { ESU_MODELS } from '~constants/esuModels';

import { collator } from '~utils/localization';
import { calculateDeviceFcrnCapacity, calculateTotalFcrnCapacity } from '~utils/esu';
import { formatDateTime } from '~utils/time';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { type ESUSettingsDataRow, useESUSettingsData } from '~hooks/useESUSettingsData';
import { CurrentESUIdContextProvider } from '~hooks/useCurrentESUId';
import { ResultListContextProvider } from '~hooks/useResultListContext';
import { type ESUOperationDataRow, useESUOperationData } from '~hooks/useESUOperationData';
import { useUserHasRootRole } from '~hooks/useUserHasRootRole';
import { useMaterialTableColumnState } from '~hooks/useMaterialTableColumnState';

import { SIDEBAR_USER_HEIGHT } from '~layouts/AppLayout/Sidebar';

import { useESUsNavigation } from '~pages/v2/esus/hooks/useESUsNavigation';
import { StatusCell, getSortingSeverity, getStatusText } from '~pages/fleet/esus/StatusCell';

import { BreadcrumbsProvider } from '~components/breadcrumbs/BreadcrumbsProvider';
import { Breadcrumbs } from '~components/breadcrumbs/Breadcrumbs';
import { SplitLayout } from '~components/SplitLayout';

const rangeSliderFilterFn: MRT_FilterFn<ESUOperationDataRow> = (row, columnId, filterValue) => {
  const value = row.getValue<number | undefined>(columnId);
  const [min, max] = filterValue as [number, number];
  return value != null && min <= value && value <= max;
};

const columnsUser: MRT_ColumnDef<ESUOperationDataRow>[] = [
  {
    header: 'Name',
    id: 'name',
    accessorFn: (row) => row.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 150,
    Footer: NameFooter,
  },
  {
    header: 'Model',
    id: 'model_name',
    accessorFn: (row) =>
      ESU_MODELS.find((esuModel) => esuModel.name === row.model_name)?.label ?? row.model_name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 150,
    filterVariant: 'autocomplete',
  },
  {
    header: 'Site',
    id: 'metering_group_name',
    accessorFn: (row) => row.edge_controller.metering_group.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 150,
    filterVariant: 'autocomplete',
  },
  {
    header: 'Status',
    id: '@calculated/esu_mode',
    size: 200,
    minSize: 200,
    filterVariant: 'autocomplete',
    filterSelectOptions: ['Offline', 'Off-grid', 'FCR-N', 'Load-shifting'],
    accessorFn: (row) => getStatusText(row),
    sortingFn: (a, b, columnId) => {
      const severityDifference = getSortingSeverity(b.original) - getSortingSeverity(a.original);
      if (severityDifference !== 0) {
        return severityDifference;
      } else {
        return collator.compare(a.getValue(columnId), b.getValue(columnId));
      }
    },
    Cell: StatusCell,
  },
  {
    header: 'SoC',
    columns: [
      {
        header: '%',
        id: 'esu_status.soc',
        accessorFn: (row) => {
          const value = row.esu_status?.soc;
          return value != null ? Math.round(value) : '';
        },
        sortUndefined: 'last',
        filterVariant: 'range',
        Cell: ({ renderedCellValue }) =>
          typeof renderedCellValue === 'number' ? `${renderedCellValue}%` : '',
        size: 80,
      },
    ],
  },
];

const columnsDiagnostics: MRT_ColumnDef<ESUOperationDataRow>[] = [
  {
    header: 'Name',
    id: 'name',
    accessorFn: (row) => row.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 150,
    Footer: NameFooter,
  },
  {
    header: 'Model',
    id: 'model_name',
    accessorFn: (row) =>
      ESU_MODELS.find((esuModel) => esuModel.name === row.model_name)?.label ?? row.model_name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 150,
    filterVariant: 'autocomplete',
  },
  {
    header: 'Status',
    id: '@calculated/esu_mode',
    size: 200,
    minSize: 200,
    filterVariant: 'autocomplete',
    filterSelectOptions: ['Offline', 'Off-grid', 'FCR-N', 'Load-shifting'],
    accessorFn: (row) => getStatusText(row),
    sortingFn: (a, b, columnId) => {
      const severityDifference = getSortingSeverity(b.original) - getSortingSeverity(a.original);
      if (severityDifference !== 0) {
        return severityDifference;
      } else {
        return collator.compare(a.getValue(columnId), b.getValue(columnId));
      }
    },
    Cell: StatusCell,
  },
  {
    header: 'SoC',
    columns: [
      {
        header: '%',
        id: 'esu_status.soc',
        accessorFn: (row) => {
          const value = row.esu_status?.soc;
          return value != null ? Math.round(value) : undefined;
        },
        Cell: ({ renderedCellValue }) =>
          renderedCellValue != null ? <>{renderedCellValue}%</> : '',
        sortUndefined: 'last',
        filterVariant: 'range-slider',
        muiFilterSliderProps: { step: 1 },
        filterFn: rangeSliderFilterFn,
        size: 80,
      },
    ],
  },
  {
    header: 'Cell temp (°C)',
    columns: [
      {
        header: 'Min',
        id: 'bms_temperatures_min',
        accessorFn: (row) => row.bms_temperatures?.['min(*)'] ?? undefined,
        sortUndefined: 'last',
        filterVariant: 'range-slider',
        muiFilterSliderProps: { step: 1 },
        filterFn: rangeSliderFilterFn,
        size: 80,
      },
      {
        header: 'Max',
        id: 'bms_temperatures_max',
        accessorFn: (row) => row.bms_temperatures?.['max(*)'] ?? undefined,
        sortUndefined: 'last',
        filterVariant: 'range-slider',
        muiFilterSliderProps: { step: 1 },
        filterFn: rangeSliderFilterFn,
        size: 80,
      },
    ],
  },
  {
    header: 'Cell voltage (V)',
    columns: [
      {
        header: 'Min',
        id: 'bms_cell_voltages_min',
        accessorFn: (row) =>
          row.bms_cell_voltages?.['min(*)'] != null
            ? // convert mV to V while rounding to 2 decimal places
              Math.round(row.bms_cell_voltages['min(*)'] / 10) / 100
            : undefined,
        sortUndefined: 'last',
        filterVariant: 'range-slider',
        muiFilterSliderProps: { step: 0.1 },
        filterFn: rangeSliderFilterFn,
        size: 80,
      },
      {
        header: 'Max',
        id: 'bms_cell_voltages_max',
        accessorFn: (row) =>
          row.bms_cell_voltages?.['max(*)'] != null
            ? // convert mV to V while rounding to 2 decimal places
              Math.round(row.bms_cell_voltages['max(*)'] / 10) / 100
            : undefined,
        sortUndefined: 'last',
        filterVariant: 'range-slider',
        muiFilterSliderProps: { step: 0.1 },
        filterFn: rangeSliderFilterFn,
        size: 80,
      },
    ],
  },
  {
    header: 'FCR-N (kW)',
    columns: [
      {
        header: 'Allocated',
        id: 'esu_power_control.fcrn_allocated_cap',
        accessorFn: (row) =>
          row.esu_power_control?.fcrn_allocated_cap != null
            ? row.esu_power_control.fcrn_allocated_cap / 1000
            : undefined,
        sortUndefined: 'last',
        filterVariant: 'range-slider',
        muiFilterSliderProps: { step: 1 },
        filterFn: rangeSliderFilterFn,
        size: 120,
      },
      {
        header: 'Maintained',
        id: 'esu_power_control.fcrn_maintained_cap',
        accessorFn: (row) =>
          row.esu_power_control?.fcrn_maintained_cap != null
            ? row.esu_power_control.fcrn_maintained_cap / 1000
            : undefined,
        sortUndefined: 'last',
        filterVariant: 'range-slider',
        muiFilterSliderProps: { step: 1 },
        filterFn: rangeSliderFilterFn,
        size: 120,
      },
    ],
  },
];

const columnsConfiguration: MRT_ColumnDef<ESUSettingsDataRow>[] = [
  {
    header: 'ESU',
    columns: [
      {
        header: 'Name',
        id: 'name',
        accessorFn: (row) => row.name,
        sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
        Footer: NameFooter,
      },
      {
        header: 'Model',
        id: 'model_name',
        accessorFn: (row) =>
          ESU_MODELS.find((esuModel) => esuModel.name === row.model_name)?.label ?? row.model_name,
        sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
        size: 150,
        filterVariant: 'autocomplete',
      },
      {
        header: 'Site',
        id: 'metering_group_name',
        accessorFn: (row) => row.edge_controller.metering_group.name,
        sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
        filterVariant: 'autocomplete',
      },
      {
        header: 'In Site Since',
        id: 'metering_group_since',
        accessorFn: (row) => formatDateTime(row.group_membership_start),
        sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
      },
      {
        header: 'Organization',
        id: 'organization_name',
        accessorFn: (row) => row.edge_controller.organization.human_name,
        sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
        filterVariant: 'autocomplete',
      },
    ],
  },
  // Optimizer
  {
    header: 'Energy transfer (kWh)',
    columns: [
      {
        header: 'Max (24h)',
        id: 'ems_optimizer_configuration.energy_transfer_max',
        accessorFn: (row) => row.ems_optimizer_configuration?.energy_transfer_max,
        size: 170,
      },
    ],
  },
  {
    header: 'Idle SoC',
    columns: [
      {
        header: 'Min',
        id: 'ems_optimizer_configuration.idle_low_soc',
        accessorFn: (row) => row.ems_optimizer_configuration?.idle_low_soc,
        size: 80,
      },
      {
        header: 'Max',
        id: 'ems_optimizer_configuration.idle_high_soc',
        accessorFn: (row) => row.ems_optimizer_configuration?.idle_high_soc,
        size: 80,
      },
    ],
  },
  {
    header: 'Optimizer',
    columns: [
      {
        header: 'Include',
        id: 'ems_optimizer_configuration.run_optimizer',
        accessorFn: (row) => {
          if (!row.ems_optimizer_configuration) {
            return '';
          }
          return row.ems_optimizer_configuration.run_optimizer ? 'Yes' : 'No';
        },
        size: 100,
      },
    ],
  },
  // Installation
  {
    header: 'Max power (kW)',
    columns: [
      {
        header: 'Import',
        id: 'ems_optimizer_configuration.default_esu_import_power_max',
        accessorFn: (row) => row.ems_optimizer_configuration?.default_esu_import_power_max,
        size: 90,
      },
      {
        header: 'Export',
        id: 'ems_optimizer_configuration.default_esu_export_power_max',
        accessorFn: (row) => row.ems_optimizer_configuration?.default_esu_export_power_max,
        size: 90,
      },
    ],
  },
  // FCR-N
  {
    header: 'FCR-N power (kW)',
    columns: [
      {
        header: 'Charge',
        id: 'ems_optimizer_configuration.max_fcrn_power',
        accessorFn: (row) => row.ems_optimizer_configuration?.max_fcrn_power,
        size: 100,
      },
      {
        header: 'Discharge',
        id: 'ems_optimizer_configuration.max_fcrn_discharge_power',
        accessorFn: (row) => row.ems_optimizer_configuration?.max_fcrn_discharge_power,
        size: 120,
      },
      {
        header: 'Capacity',
        id: '@calculated/fcrn_cap_max',
        accessorFn: (row) => calculateDeviceFcrnCapacity(row.ems_optimizer_configuration),
        Footer: FCRNCapacityFooter,
      },
      {
        header: 'Offset',
        id: 'ems_optimizer_configuration.desired_fcrn_power_offset',
        accessorFn: (row) => row.ems_optimizer_configuration?.desired_fcrn_power_offset,
        size: 100,
      },
    ],
  },
  {
    header: 'FCR-N SoC',
    columns: [
      {
        header: 'Min',
        id: 'ems_optimizer_configuration.fcrn_low_soc',
        accessorFn: (row) => row.ems_optimizer_configuration?.fcrn_low_soc,
        size: 80,
      },
      {
        header: 'Max',
        id: 'ems_optimizer_configuration.fcrn_high_soc',
        accessorFn: (row) => row.ems_optimizer_configuration?.fcrn_high_soc,
        size: 80,
      },
    ],
  },
  {
    header: 'FCR-N',
    columns: [
      {
        header: 'Priority',
        id: 'ems_optimizer_configuration.fcrn_priority_group',
        accessorFn: (row) => row.ems_optimizer_configuration?.fcrn_priority_group,
        size: 100,
      },
    ],
  },
  // Peak shaving
  {
    header: 'Peak shaving',
    columns: [
      {
        header: 'Threshold (A)',
        id: 'ems_optimizer_configuration.peak_shaving_threshold_override',
        accessorFn: (row) => row.ems_optimizer_configuration?.peak_shaving_threshold_override,
        size: 140,
      },
    ],
  },
  // Device
  {
    header: 'Energy (kWh)',
    columns: [
      {
        header: 'Capacity',
        id: 'ems_optimizer_configuration.device_energy_capacity',
        accessorFn: (row) => row.ems_optimizer_configuration?.device_energy_capacity,
        size: 110,
        Footer: DeviceEnergyCapacityFooter,
      },
    ],
  },
  {
    header: 'Efficiency',
    columns: [
      {
        header: 'Import',
        id: 'ems_optimizer_configuration.import_efficiency',
        accessorFn: (row) => row.ems_optimizer_configuration?.import_efficiency,
        size: 90,
      },
      {
        header: 'Export',
        id: 'ems_optimizer_configuration.export_efficiency',
        accessorFn: (row) => row.ems_optimizer_configuration?.export_efficiency,
        size: 90,
      },
    ],
  },
];

export function ESUsList() {
  const { esuId, tab, navigateToESU } = useESUsNavigation();

  const [columnsMode, setColumnsMode] = useLocalStorage<'diagnostics' | 'configuration'>(
    'esus.columnsMode',
    'diagnostics',
  );
  const [fleetFilter, setFleetFilter] = useLocalStorage<FleetFilter>('esus.fleetFilter', 'all');

  const rowSelection = useMemo(() => (esuId ? { [esuId]: true } : {}), [esuId]);
  const {
    columnSizing,
    columnFilters,
    columnVisibility,
    setColumnSizing,
    setColumnFilters,
    setColumnVisibility,
  } = useMaterialTableColumnState('esus');

  const onFleetFilterChange = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const { value } = event.currentTarget as HTMLButtonElement;
      setFleetFilter(value as FleetFilter);
    },
    [setFleetFilter],
  );
  const { data: configurationData = [] } = useESUSettingsData(fleetFilter);
  const { data: diagnosticsData = [] } = useESUOperationData(fleetFilter);

  const canViewConfiguration = useUserHasRootRole(USER_ROLES.OPERATOR_VIEW);
  const canViewDiagnostics = useUserHasRootRole(USER_ROLES.EMS_DIAGNOSTIC_VIEW);
  const canViewColumnSwitch = canViewConfiguration && canViewDiagnostics;
  const shouldViewUserColumns = !(canViewConfiguration || canViewDiagnostics);

  const data = useMemo(() => {
    if (shouldViewUserColumns) return diagnosticsData;

    return columnsMode === 'diagnostics' ? diagnosticsData : configurationData;
  }, [shouldViewUserColumns, columnsMode, diagnosticsData, configurationData]);
  const columns = useMemo(() => {
    if (canViewColumnSwitch) {
      return columnsMode === 'diagnostics' ? columnsDiagnostics : columnsConfiguration;
    }

    if (canViewConfiguration) return columnsConfiguration;
    if (canViewDiagnostics) return columnsDiagnostics;

    return columnsUser;
  }, [canViewConfiguration, canViewDiagnostics, canViewColumnSwitch, columnsMode]);

  const table = useMaterialReactTable<ESUSettingsDataRow>({
    data,
    columns,
    initialState: {
      density: 'compact',
    },
    state: {
      rowSelection,
      columnSizing,
      columnFilters,
      columnVisibility,
      columnPinning: {
        left: ['name'],
      },
    },
    onColumnSizingChange: setColumnSizing,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableTableFooter: true,
    enableBottomToolbar: false,
    enableFacetedValues: true,
    positionToolbarAlertBanner: 'none',
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigateToESU(row.id, tab);
      },
      sx: { cursor: 'pointer' },
    }),
    muiTableContainerProps: {
      sx: { maxHeight: 'calc(100vh - 128px)' },
    },
    muiTablePaperProps: {
      sx: { borderRadius: 0 },
    },
    muiTableFooterCellProps: {
      sx: { height: SIDEBAR_USER_HEIGHT + 1 },
    },
    renderTopToolbarCustomActions: () =>
      canViewColumnSwitch && (
        <Box display="flex" gap={2}>
          <ToggleButtonGroup
            exclusive
            size="small"
            color="primary"
            value={fleetFilter}
            onChange={onFleetFilterChange}
          >
            <ToggleButton value="all">All</ToggleButton>
            <ToggleButton value="fleet">Fleet</ToggleButton>
          </ToggleButtonGroup>
          <ToggleButtonGroup
            exclusive
            size="small"
            color="primary"
            value={columnsMode}
            onChange={(_event, value) => setColumnsMode(value)}
          >
            <ToggleButton value="diagnostics">Diagnostics</ToggleButton>
            <ToggleButton value="configuration">Configuration</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      ),
  });

  const rows = useMRT_Rows(table);

  const resultListContext = useMemo(() => {
    const currentRowIndex = esuId ? rows.findIndex((row) => row.id === esuId) : -1;
    const totalRowCount = rows.length;

    return {
      currentRowIndex,
      totalRowCount,
      goToNextResult: () => {
        if (currentRowIndex + 1 >= totalRowCount) return;

        navigateToESU(rows[currentRowIndex + 1].id, tab);
      },
      goToPreviousResult: () => {
        if (currentRowIndex <= 0) return;

        navigateToESU(rows[currentRowIndex - 1].id, tab);
      },
      goToResultList: () => navigateToESU(),
    };
  }, [rows, esuId, tab, navigateToESU]);

  // TODO remove CurrentESUIdContextProvider in favor or useESUsNavigation
  return (
    <CurrentESUIdContextProvider value={esuId ?? null}>
      <ResultListContextProvider value={resultListContext}>
        <BreadcrumbsProvider>
          <SplitLayout
            showDetails={!!esuId}
            renderTitle={() => <Breadcrumbs hasIcon hasNavigation />}
          >
            <MaterialReactTable table={table} />
          </SplitLayout>
        </BreadcrumbsProvider>
      </ResultListContextProvider>
    </CurrentESUIdContextProvider>
  );
}

function NameFooter({ table }: { table: MRT_TableInstance<ESUSettingsDataRow> }) {
  const rows = useMRT_Rows(table);

  return <CenteredBox>ESUs: {rows.length}</CenteredBox>;
}

function FCRNCapacityFooter({ table }: { table: MRT_TableInstance<ESUSettingsDataRow> }) {
  const rows = useMRT_Rows(table);
  const totalFcrnCapacity = useMemo(
    () => calculateTotalFcrnCapacity(rows.map((row) => row.original.ems_optimizer_configuration)),
    [rows],
  );

  return <CenteredBox>Total: {totalFcrnCapacity} MW</CenteredBox>;
}

function DeviceEnergyCapacityFooter({ table }: { table: MRT_TableInstance<ESUSettingsDataRow> }) {
  const rows = useMRT_Rows(table);
  const totalDeviceEnergyCapacity = useMemo(
    () => calculateTotalDeviceEnergyCapacity(rows.map((row) => row.original)),
    [rows],
  );

  return <CenteredBox>Total: {totalDeviceEnergyCapacity} kWh</CenteredBox>;
}

function CenteredBox({ children }: PropsWithChildren) {
  return (
    <Box height="100%" display="flex" alignItems="center">
      {children}
    </Box>
  );
}

function calculateTotalDeviceEnergyCapacity(
  data: ESUSettingsDataRow[] | undefined,
): string | undefined {
  if (!data) return undefined;

  const total = data.reduce((acc, row) => {
    if (!row.ems_optimizer_configuration?.device_energy_capacity) return acc;

    const capacity = parseInt(row.ems_optimizer_configuration.device_energy_capacity, 10);

    return acc + capacity;
  }, 0);

  return total.toFixed(3);
}
