import type {
  MRT_ColumnFiltersState,
  MRT_ColumnSizingState,
  MRT_VisibilityState,
} from 'material-react-table';

import { useLocalStorage } from '~hooks/useLocalStorage';

export function useMaterialTableColumnState(key: string) {
  const [columnSizing, setColumnSizing] = useLocalStorage<MRT_ColumnSizingState>(
    `${key}.columnSizing`,
    {},
  );
  const [columnVisibility, setColumnVisibility] = useLocalStorage<MRT_VisibilityState>(
    `${key}.columnVisibility`,
    {},
  );
  const [columnFilters, setColumnFilters] = useLocalStorage<MRT_ColumnFiltersState>(
    `${key}.columnFilters`,
    [],
  );

  return {
    columnSizing,
    setColumnSizing,
    columnVisibility,
    setColumnVisibility,
    columnFilters,
    setColumnFilters,
  };
}
