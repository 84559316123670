import axios from 'axios';

import type { User } from '~types';

import { http } from '~http';

export async function getMe(signal: AbortSignal) {
  try {
    const response = await http.get('/v1/me', { signal });
    const userData = response.data as User;

    const organizationsByRole: Record<string, string[]> = {};

    if ('roles' in userData) {
      Object.keys(userData.roles).forEach((organization) => {
        userData.roles[organization].forEach((role) => {
          if (!(role in organizationsByRole)) {
            organizationsByRole[role] = [];
          }
          organizationsByRole[role].push(organization);
        });
      });
      userData.organizationsByRole = organizationsByRole;
    }

    return userData;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      return null;
    }

    throw error;
  }
}
