import { Grid } from '@mui/material';

import { useESU } from '~hooks/useESUList';
import { createLatestQuery, useTimeseriesLatest } from '~hooks/timeseries/queries';

import { EMSBatteryCard } from '~pages/dashboard/cards/EMSBatteryCard';
import { EMSChargeCard } from '~pages/dashboard/cards/EMSChargeCard';
import { EMSGridFrequencyCard } from '~pages/dashboard/cards/EMSGridFrequencyCard';
import { EMSPowerCard } from '~pages/dashboard/cards/EMSPowerCard';
import { useESUsNavigation } from '~pages/v2/esus/hooks/useESUsNavigation';
import { ESUInsights } from '~pages/v2/esus/components/ESUInsights';
import { ESUStatus } from '~pages/v2/esus/components/ESUStatus';

import { ScrollView } from '~components/ScrollView';

export function ESUDashboard() {
  const { esuId } = useESUsNavigation();
  const { data: esu } = useESU(esuId);
  const { data: queryResult, isLoading: esuDataLoading } = useTimeseriesLatest(
    createLatestQuery({
      resources: esu ? [esu.resource_id] : [],
      columns: {
        bms_cell_voltages: ['max(*)', 'min(*)'],
        bms_temperatures: ['max(*)'],
        esu_status: ['soc'],
        esu_diagnostics_power: ['bms_battery_pack_voltage'],
        esu_power_control: ['esu_realized_power', 'control_grid_f'],
      },
      start: { minutes: 10 },
    }),
    {
      refreshInterval: 10_000,
      revalidateOnFocus: true,
    },
  );

  const esuData = esu ? queryResult?.[esu.resource_id] : undefined;

  return (
    <ScrollView>
      <ESUStatus />
      <Grid container spacing={2} px={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <EMSPowerCard
            powerWatts={esuData?.esu_power_control?.esu_realized_power}
            isLoading={esuDataLoading}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <EMSChargeCard socPercentage={esuData?.esu_status?.soc} isLoading={esuDataLoading} />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <EMSGridFrequencyCard
            frequencyMilliHz={esuData?.esu_power_control?.control_grid_f}
            isLoading={esuDataLoading}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <EMSBatteryCard data={esuData} isLoading={esuDataLoading} />
        </Grid>
      </Grid>

      <ESUInsights />
    </ScrollView>
  );
}
