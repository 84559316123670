import { useCallback, useEffect } from 'react';

import { useResultListContext } from '~hooks/useResultListContext';

export function useResultListKeyboardShortcuts() {
  const context = useResultListContext();
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // Ignore event if focus in within an input or textarea.
      if (['INPUT', 'TEXTAREA'].includes((event.target as Element).tagName)) {
        return;
      }
      if (event.key === 'Escape') {
        context.goToResultList();
      } else if (event.key === 'j' && !event.metaKey && !event.ctrlKey) {
        context.goToNextResult();
      } else if (event.key === 'k' && !event.metaKey && !event.ctrlKey) {
        context.goToPreviousResult();
      }
    },
    [context],
  );
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
}
