import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

import type { OrganizationDetails } from '~types';

import { useMeteringGroupList } from '~hooks/useMeteringGroupList';
import { useIsRoutePermitted } from '~hooks/useIsRoutePermitted';

import { createOrganizationPath } from '~pages/v2/organizations/utils/createOrganizationPath';

import { HierarchyIcon } from '~components/hierarchy/HierarchyIcon';
import { HierarchySite } from '~components/hierarchy/HierarchySite';
import { HierarchyLoading } from '~components/hierarchy/HierarchyLoading';

type Props = {
  organization: OrganizationDetails;
  paddingLeft?: number;
  isRoot?: boolean;
};

export function HierarchyOrganization({ organization, paddingLeft = 2, isRoot = false }: Props) {
  const { data: meteringGroups = [], isLoading } = useMeteringGroupList();
  const isRoutePermitted = useIsRoutePermitted(createOrganizationPath(organization.id));

  const organizationMeteringGroups = useMemo(
    () => meteringGroups.filter((mg) => mg.organization_id === organization.id),
    [meteringGroups, organization.id],
  );

  if (isLoading) return <HierarchyLoading />;

  if (isRoot || !isRoutePermitted) {
    if (isRoot && !organizationMeteringGroups.length) {
      return (
        <Typography variant="body2" py={1} px={2}>
          Nothing to display here
        </Typography>
      );
    }

    return organizationMeteringGroups.map((meteringGroup) => (
      <HierarchySite
        key={meteringGroup.id}
        meteringGroup={meteringGroup}
        paddingLeft={paddingLeft}
      />
    ));
  }

  return (
    <List disablePadding>
      <Link
        to={createOrganizationPath(organization.id)}
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        <ListItemButton sx={{ padding: 0, paddingLeft, paddingRight: 2 }}>
          <ListItemIcon>
            <HierarchyIcon breadcrumbType="organizations" />
          </ListItemIcon>
          <ListItemText primary={organization.human_name} />
        </ListItemButton>
      </Link>
      {organizationMeteringGroups.map((meteringGroup) => (
        <HierarchySite
          key={meteringGroup.id}
          meteringGroup={meteringGroup}
          paddingLeft={paddingLeft + 2}
        />
      ))}
    </List>
  );
}
