import { useOrganization } from '~hooks/useOrganization';

import { useOrganizationsNavigation } from '~pages/v2/organizations/hooks/useOrganizationsNavigation';

import { HierarchyOrganization } from '~components/hierarchy/HierarchyOrganization';
import { HierarchyLoading } from '~components/hierarchy/HierarchyLoading';

export function OrganizationHierarchy() {
  const { organizationId } = useOrganizationsNavigation();
  const { data: organization, isLoading } = useOrganization(organizationId);

  if (isLoading) return <HierarchyLoading />;
  if (!organization) return null;

  return <HierarchyOrganization isRoot organization={organization} />;
}
