import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';

import { PageLayout } from '~layouts/PageLayout';

import { ESUsList } from '~pages/v2/esus/components/ESUsList';
import { createESUPath } from '~pages/v2/esus/utils/createESUPath';

export function ESUs() {
  useRegisterBreadcrumbs('esus', 'Energy Storage Units', createESUPath());

  return (
    <PageLayout title="Energy Storage Units">
      <ESUsList />
    </PageLayout>
  );
}
