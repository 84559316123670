import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';

import { PageLayout } from '~layouts/PageLayout';

import { Optimizer as OptimizerContent } from '~pages/fleet/optimizer/Optimizer';

import { ScrollView } from '~components/ScrollView';

export function Optimizer() {
  useRegisterBreadcrumbs('other', 'Optimizer', '/optimizer');

  return (
    <PageLayout title="Optimizer">
      <ScrollView>
        <OptimizerContent />
      </ScrollView>
    </PageLayout>
  );
}
