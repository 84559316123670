import { useMemo } from 'react';

import { type ResourceID, useTimeseriesLatest } from '~hooks/timeseries';
import { createLatestQuery, createRangeQuery, useTimeseriesRange } from '~hooks/timeseries/queries';
import { useESUList } from '~hooks/useESUList';

import type { UnitInfo } from '~components/charts/HistogramBarChart';

const TEMPERATURE_DATA_REFRESH_INTERVAL = 30_000; // ms

export const useGroupedFleetDiagnostics = () => {
  const { data: esus, error: esusError, isLoading: esusLoading } = useESUList('fleet');
  const {
    data: latestQueryResult,
    error: latestQueryError,
    isLoading: loadingLatestQuery,
  } = useTimeseriesLatest(
    createLatestQuery({
      resources: (esus ?? []).map((esu) => `ems:${esu.id}` as ResourceID),
      columns: { bms_temperatures: ['min(*)', 'max(*)'], bms_cell_voltages: ['min(*)', 'max(*)'] },
      atTime: 'now',
      start: { minutes: 10 },
    }),
    { refreshInterval: TEMPERATURE_DATA_REFRESH_INTERVAL, revalidateOnFocus: true },
  );

  // on cactos 1 the ambient temperature is missing for about 10% of the pings,
  // use a range query and take the latest non-null value
  const {
    data: rangeQueryResult,
    error: rangeQueryError,
    isLoading: loadingRangeQuery,
  } = useTimeseriesRange(
    createRangeQuery({
      resources: (esus ?? []).map((esu) => `ems:${esu.id}` as ResourceID),
      columns: { esu_diagnostics_environ: ['ambient_temperature'] },
      start: { minutes: 10 },
      end: 'now',
    }),
    { refreshInterval: TEMPERATURE_DATA_REFRESH_INTERVAL },
  );

  const data = useMemo(() => {
    const joinedData = (esus ?? []).map((esu) => {
      const resourceID = `ems:${esu.id}`;
      const cellVoltageMin = latestQueryResult?.[resourceID]?.bms_cell_voltages?.['min(*)'];
      const cellVoltageMax = latestQueryResult?.[resourceID]?.bms_cell_voltages?.['max(*)'];

      return {
        id: esu.id,
        name: esu.name,
        minCellTemperature: latestQueryResult?.[resourceID]?.bms_temperatures?.['min(*)'] ?? null,
        maxCellTemperature: latestQueryResult?.[resourceID]?.bms_temperatures?.['max(*)'] ?? null,
        ambientTemperature:
          rangeQueryResult?.[resourceID]?.esu_diagnostics_environ
            ?.map((row) => row.ambient_temperature)
            .filter((temperature) => Number.isFinite(temperature))
            .at(-1) ?? null,
        maxCellVoltageDiff:
          cellVoltageMax != null && cellVoltageMin != null
            ? Math.floor((cellVoltageMax - cellVoltageMin) / 10)
            : null,
      };
    });

    const byMinCellTemperature = new Map<number, UnitInfo[]>();
    const byMaxCellTemperature = new Map<number, UnitInfo[]>();
    const byMaxAmbientTemperature = new Map<number, UnitInfo[]>();
    const byVoltageDifference = new Map<number, UnitInfo[]>();

    joinedData.forEach((esu) => {
      if (esu.minCellTemperature != null) {
        const roundedTemp = Math.round(esu.minCellTemperature);
        if (!byMinCellTemperature.has(roundedTemp)) {
          byMinCellTemperature.set(roundedTemp, []);
        }
        byMinCellTemperature.get(roundedTemp)!.push(esu);
      }
      if (esu.maxCellTemperature != null) {
        const roundedTemp = Math.round(esu.maxCellTemperature);
        if (!byMaxCellTemperature.has(roundedTemp)) {
          byMaxCellTemperature.set(roundedTemp, []);
        }
        byMaxCellTemperature.get(roundedTemp)!.push(esu);
      }
      if (esu.ambientTemperature != null) {
        const roundedTemp = Math.round(esu.ambientTemperature);
        if (!byMaxAmbientTemperature.has(roundedTemp)) {
          byMaxAmbientTemperature.set(roundedTemp, []);
        }
        byMaxAmbientTemperature.get(roundedTemp)!.push(esu);
      }
      if (esu.maxCellVoltageDiff != null) {
        const roundedVoltage = Math.round(esu.maxCellVoltageDiff);
        if (!byVoltageDifference.has(roundedVoltage)) {
          byVoltageDifference.set(roundedVoltage, []);
        }
        byVoltageDifference.get(roundedVoltage)!.push(esu);
      }
    });

    return {
      byMinCellTemperature,
      byMaxCellTemperature,
      byMaxAmbientTemperature,
      byVoltageDifference,
    };
  }, [esus, latestQueryResult, rangeQueryResult]);

  return {
    data,
    error: esusError ?? latestQueryError ?? rangeQueryError,
    isLoading: esusLoading || loadingLatestQuery || loadingRangeQuery,
  };
};
