import type { MeteringGroup } from '~types';

/**
 * Returns the energy meter resource ID of the given metering group,
 * if the metering group has exactly one grid meter that stores data in
 * the energy_meter_* time series. Otherwise, returns null.
 *
 * This is useful for checking if the metering group has meter data available
 * in the new format including reactive power etc.
 */
export function getEnergyMeterResourceId(meteringGroup: MeteringGroup | undefined) {
  if (meteringGroup == null) {
    return null;
  }
  if (meteringGroup.grid_meters.length !== 1) {
    return null;
  }
  if (meteringGroup.grid_meters[0].series !== 'energy_meter') {
    return null;
  }
  return meteringGroup.grid_meters[0].resource_id;
}
