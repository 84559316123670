import { GroupOptimizerConfiguration } from '~pages/settings/groups/GroupOptimizerConfiguration';

import { ScrollView } from '~components/ScrollView';

export function SiteSettings() {
  return (
    <ScrollView>
      <GroupOptimizerConfiguration />
    </ScrollView>
  );
}
