import React, { useCallback, useMemo, useState } from 'react';
import deepEqual from 'fast-deep-equal';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { ParentSize } from '@visx/responsive';
import { extent } from 'd3-array';

import { asCactosError } from '~http';

import { type Row, joinTimeseriesData, truncateTimeseriesDataToWindow } from '~utils/timeseries';
import { type DateTimeRange } from '~utils/time';

import { Iconify } from '~components/Iconify';

import {
  type ChartAnnotation,
  type ChartArea,
  type ChartSeries,
  type DataPoint,
  MultiLineChart,
} from './MultiLineChart';
import { ChartLegend } from './utils';

export type ChartVariable = {
  /** Name of variable shown on chart, must be unique. */
  name: string;
  /** Name of column in source data. */
  sourceColumn: string;
  /**
   * If provided, this function is called on the value read from the source data.
   * This transformation is applied before scaling the value by the 'denominator' and displaying it on the chart.
   */
  transform?: (value: number | string | null) => number | string | null;
  /**
   * If provided, the variable gets divided by it's factor before displaying,
   * use when different inputs have different units.
   */
  denominator?: number;
  /** Color of line on chart. */
  color: string;
  /** Opacity of line on chart. */
  opacity?: number;
  /** Set to false to hide the variable from legend, if missing defaults to true. */
  includeInLegend?: boolean;
  /** Interpolation type to use for line, defaults to linear. */
  interpolation?: 'linear' | 'step-after';
  /** An svg stroke-dasharray used for creating dotted lines, for example: '5,2'. */
  strokeDasharray?: string;
  /**
   * Providing the variable name of another line (from the same source array)
   * combined with a color and opacity will color the area between the 2 lines.
   */
  lowerBoundOfArea?: {
    upperBoundSeriesName: string;
    areaColor: string;
    areaOpacity?: number;
  };
  /**
   * Providing the variable names of 2 other lines (from the same source array)
   * combined with a color and opacity will color the area between the
   * lower and upper lines and combine this line and the area into a single legend item.
   */
  centerOfArea?: {
    name: string;
    lowerBoundSeriesName: string;
    upperBoundSeriesName: string;
    areaColor?: string;
    areaOpacity?: number;
  };
};

export type TimeSeriesMultiLineChartProps = {
  /**
   * Variables to display in the chart, grouped by source timeseries.
   * The key 'timeseriesName' is used to retreive data from the data prop.
   * If 'longestDrawnGapMs' is provided, lines using this timeseries source will have visible gaps
   * whenever the time difference between 2 adjacent timestamps is exceeds 'longestDrawnGapMs'.
   * If 'snapTooltip'=true, the tooltip of the chart will snap to the timestamps of this timeseries.
   */
  variableSources: {
    [timeseriesName: string]: {
      variables: ChartVariable[];
      longestDrawnGapMs?: number;
      snapTooltip?: boolean;
    };
  };
  /**
   * Data for lines, each 'timeseriesName' points to a data source with strictly increasing timestamps.
   * Different data arrays are allowed to have different timestamps.
   * For each data source, another array with '#export' appended to the key can be provided,
   * this alternate array will be used instead when exporting as CSV.
   */
  data: { [timeseriesName: string]: Row[] | null | undefined };
  /** Points, lines or areas to highlight on the chart. */
  chartAnnotations?: ChartAnnotation[];
  /** If true, a spinning loading indicator will be shown to indicate that chart data is loading. */
  isLoading?: boolean;
  /** A possible axios/other error to indicate an issue while loading data. */
  error?: any;
  /** Height of the chart component */
  height?: number;
  /** Title displayed at top of component. */
  title: string;
  /** Subtitle displayed below title. */
  subtitle?: string;
  /** If true, the returned JSX only contains the inner chart component and chart legend. */
  frameless?: boolean;
  /** The units represented on the y-axis of the chart, used by chart tooltip. */
  units: string;
  /** Amount of decimals to show for variable value in tooltip. */
  tooltipDecimalDigits?: number;
  /** Defines the behaviour for creating the horizontal axis (time) based on data. */
  timeAxis?: {
    /** The start of the time axis will not be set to a date earlier than this, even if the data contained such a date. */
    clipMin?: Date;
    /** The time axis will be scaled such that **at least** these dates are contained within the chart. */
    include?: Date[];
    /** The end of the time axis will not be set to a date later than this, even if the data contained such a date. */
    clipMax?: Date;
  };
  /** Defines the behaviour for creating the vertical axis based on data. */
  valueAxis?: {
    /**
     * The start of the value axis will not be set to a value lower than this, even if the data contained such a value.
     * Note that unless valueAxis.nice is explicitly set to false, the start of the axis may still be set slightly lower than this.
     */
    clipMin?: number;
    /** The value axis will be scaled such that **at least** these values are contained within the chart. */
    include?: number[];
    /**
     * The end of the value axis will not be set to a value higher than this, even if the data contained such a value.
     * Note that unless valueAxis.nice is explicitly set to false, the end of the axis may still be set slightly higher than this.
     */
    clipMax?: number;
    /** Unless explicitly set to false, the value axis may be extended to start and end on round values. */
    nice?: boolean;
  };
  /**
   * If provided, the chart will have click+drag to zoom enabled, and this callback function
   * will be called with the new zoom range whenever the user zooms.
   */
  onZoom?: (range: DateTimeRange) => void;
  /**
   * Called when exporting chart, the filename should not contain
   * a file extension as that will be applied by the chart component.
   */
  exportFilename?: (startTime: Date, endTime: Date, chartTitle: string) => string;
};

const TimeSeriesMultiLineChartComponent = ({
  variableSources,
  data,
  chartAnnotations,
  isLoading,
  error,
  height,
  title,
  subtitle,
  frameless = false,
  units,
  tooltipDecimalDigits,
  timeAxis: { clipMin: timeClipMin, include: timeInclude, clipMax: timeClipMax } = {},
  valueAxis: {
    clipMin: valueClipMin,
    include: valueInclude,
    clipMax: valueClipMax,
    nice: niceValueAxis,
  } = {},
  onZoom,
  exportFilename,
}: TimeSeriesMultiLineChartProps) => {
  // note: this component uses React.memo with a custom arePropsEqual function
  // take care when adding new props to the component

  const series: ChartSeries[] = useMemo(
    () =>
      Object.entries(variableSources).flatMap(
        ([timeseriesName, { variables, longestDrawnGapMs }]) => {
          const rows: Row[] = data[timeseriesName] ?? [];
          const rowsTruncated: Row[] =
            timeClipMin == null && timeClipMax == null
              ? rows
              : truncateTimeseriesDataToWindow(
                  rows,
                  timeClipMin ?? null,
                  timeClipMax ?? null,
                  Object.fromEntries(
                    variables.map((v) => [v.sourceColumn, v.interpolation ?? 'linear']),
                  ),
                );

          return variables.map((variable) => {
            const dataPoints: DataPoint[] = rowsTruncated.map((row) => {
              let value: string | number | null = row[variable.sourceColumn];
              if (variable.transform != null) {
                value = variable.transform(value);
              }
              return {
                time: new Date(row.time),
                value:
                  typeof value === 'number' && Number.isFinite(value)
                    ? variable.denominator != null
                      ? value / variable.denominator
                      : value
                    : NaN,
              };
            });

            return {
              id: variable.name,
              name: variable.name,
              color: variable.color,
              opacity: variable.opacity,
              interpolation: variable.interpolation ?? 'linear',
              strokeDasharray: variable.strokeDasharray,
              lowerBoundOfArea: variable.lowerBoundOfArea,
              data:
                variable.interpolation !== 'step-after'
                  ? insertNaNValues(dataPoints, longestDrawnGapMs ?? null)
                  : dataPoints,
            };
          });
        },
      ),
    [data, variableSources, timeClipMin, timeClipMax],
  );

  const areas: ChartArea[] = useMemo(() => {
    const variables = Object.values(variableSources).flatMap((source) => source.variables);

    const minMaxAreas: ChartArea[] = variables.flatMap((lowerVariable) => {
      const areaDetails = lowerVariable.lowerBoundOfArea;
      if (areaDetails == null) return [];

      const lowerSeries = series.find((s) => s.name === lowerVariable.name)!;
      const upperSeries = series.find((s) => s.name === areaDetails.upperBoundSeriesName)!;

      return {
        id: `area-min@${lowerSeries.id}-max@${upperSeries.id}`,
        color: areaDetails.areaColor,
        opacity: areaDetails.areaOpacity,
        interpolation: lowerSeries.interpolation,
        data: lowerSeries.data.map((dataPoint, index) => ({
          time: dataPoint.time,
          lower: dataPoint.value,
          upper: upperSeries.data[index].value,
        })),
      };
    });

    const minMaxMeanAreas: ChartArea[] = variables.flatMap((middleVariable) => {
      const areaDetails = middleVariable.centerOfArea;
      if (areaDetails == null) return [];

      const middleSeries = series.find((s) => s.name === middleVariable.name)!;
      const lowerSeries = series.find((s) => s.name === areaDetails.lowerBoundSeriesName)!;
      const upperSeries = series.find((s) => s.name === areaDetails.upperBoundSeriesName)!;

      return {
        id: `area-mean@${middleSeries.id}-min@${lowerSeries.id}-max@${upperSeries.id}`,
        color: areaDetails.areaColor ?? middleVariable.color,
        opacity: areaDetails.areaOpacity,
        interpolation: lowerSeries.interpolation,
        data: lowerSeries.data.map((dataPoint, index) => ({
          time: dataPoint.time,
          lower: dataPoint.value,
          upper: upperSeries.data[index].value,
        })),
      };
    });

    return [...minMaxAreas, ...minMaxMeanAreas];
  }, [variableSources, series]);

  const tooltipSnapIndex: Date[] | undefined = useMemo(() => {
    const indexTimeseriesNames = Object.entries(variableSources)
      .filter(([_, { snapTooltip }]) => snapTooltip === true)
      .map(([seriesName, _]) => seriesName);
    if (indexTimeseriesNames.length === 0) return undefined;
    if (indexTimeseriesNames.length === 1) {
      const rows = data[indexTimeseriesNames[0]];
      if (rows == null) return undefined;
      return truncateTimeseriesDataToWindow(rows, timeClipMin ?? null, timeClipMax ?? null, {}).map(
        (row) => new Date(row.time),
      );
    }
    // MultiLineChart supports any arbitrary index including a union of the indices
    // of many timeseries, so only this part really needs implementing
    throw new Error('Only one timeseries can have snapTooltip=true');
  }, [variableSources, data, timeClipMin, timeClipMax]);

  const [minDate, maxDate] = useMemo(() => {
    // min and max timestamps across all series in one array
    const minAndMaxDates = series.flatMap((s) =>
      s.data.length > 0 ? [s.data[0].time, s.data[s.data.length - 1].time] : [],
    );
    if (timeInclude != null) minAndMaxDates.push(...timeInclude);
    return extent(minAndMaxDates);
  }, [series, timeInclude]);

  const zoomOut = useCallback(() => {
    if (onZoom == null || minDate == null || maxDate == null) return;
    const startMs = minDate.getTime();
    const endMs = maxDate.getTime();
    if (startMs === endMs) return;
    const lengthMs = endMs - startMs;
    onZoom({
      start: new Date(startMs - lengthMs / 2),
      end: new Date(endMs + lengthMs / 2),
    });
  }, [minDate, maxDate, onZoom]);

  const { legendItems, chartElementIds } = useMemo(() => {
    const legendItems = Object.values(variableSources)
      .flatMap((source) => source.variables)
      .filter((variable) => variable.includeInLegend !== false)
      .map((variable) => {
        const mainSeries = series.find((s) => s.name === variable.name)!;
        const areaDetails = variable.centerOfArea;
        if (areaDetails == null)
          return {
            id: mainSeries.id,
            displayName: mainSeries.name,
            color: mainSeries.color,
            chartElements: new Set([mainSeries.id]),
          };

        const lowerSeries = series.find((s) => s.name === areaDetails.lowerBoundSeriesName)!;
        const upperSeries = series.find((s) => s.name === areaDetails.upperBoundSeriesName)!;
        return {
          id: mainSeries.id,
          displayName: areaDetails.name,
          color: mainSeries.color,
          chartElements: new Set([
            mainSeries.id,
            lowerSeries.id,
            upperSeries.id,
            `area-mean@${mainSeries.id}-min@${lowerSeries.id}-max@${upperSeries.id}`,
          ]),
        };
      });

    const chartElementIds = new Map(legendItems.map((item) => [item.id, item.chartElements]));

    return { legendItems, chartElementIds };
  }, [variableSources, series]);

  const [selectedLegendItem, setSelectedLegendItem] = useState<string | null>(null);
  const highlightedElementIds =
    selectedLegendItem !== null ? chartElementIds.get(selectedLegendItem)! : null;

  const innerElements = (
    <>
      <ParentSize debounceTime={100}>
        {({ width }) => (
          <MultiLineChart
            width={width}
            height={height ?? 300}
            series={series}
            areas={areas}
            annotations={chartAnnotations}
            highlightedElementIds={highlightedElementIds}
            units={units}
            tooltipDecimalDigits={tooltipDecimalDigits}
            tooltipSnapIndex={tooltipSnapIndex}
            xAxisInclude={timeInclude}
            yAxisInclude={valueInclude}
            yAxisClipMin={valueClipMin}
            yAxisClipMax={valueClipMax}
            niceYScale={niceValueAxis}
            onZoom={onZoom}
          />
        )}
      </ParentSize>
      <ChartLegend
        items={legendItems}
        selectedItemID={selectedLegendItem}
        onSelectedItemChange={setSelectedLegendItem}
      />
    </>
  );

  if (frameless) return innerElements;

  return (
    <Card>
      <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
        <CardHeader
          title={
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography gutterBottom variant="h6" component="h4">
                {title}
              </Typography>
              {isLoading === true && <CircularProgress size={12} sx={{ mb: 0.8 }} />}
              {error != null && (
                <Tooltip
                  title={`Error loading data: ${asCactosError(error).message}`}
                  placement="right"
                >
                  <ErrorOutline color="error" fontSize="small" sx={{ mb: 0.8 }} />
                </Tooltip>
              )}
            </Stack>
          }
          subheader={subtitle}
        />
        <Box
          sx={{
            p: 2,
            paddingRight: 3,
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'flex-end',
          }}
        >
          {onZoom != null && (
            <Tooltip title="Zoom Out" placement="top">
              <IconButton color="default" size="small" onClick={zoomOut}>
                <Iconify icon="mdi:zoom-out" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Download as CSV" placement="top">
            <IconButton
              color="default"
              size="small"
              onClick={() => downloadCsv(variableSources, data, title, exportFilename ?? null)}
            >
              <Iconify icon="mdi:file-download" />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
      <Box sx={{ p: 3, pb: 1 }}>{innerElements}</Box>
    </Card>
  );
};

const arePropsEqual = (
  oldProps: TimeSeriesMultiLineChartProps,
  newProps: TimeSeriesMultiLineChartProps,
) => {
  const {
    data: oldData,
    onZoom: oldOnZoom,
    exportFilename: oldExportFilename,
    error: oldError,
    ...oldOtherProps
  } = oldProps;
  const {
    data: newData,
    onZoom: newOnZoom,
    exportFilename: newExportFilename,
    error: newError,
    ...newOtherProps
  } = newProps;

  // test if each data array is the same array as before
  // we don't want to check the contents of the arrays as they are large,
  // just assume that if the array reference is the same, the contents are the same
  if (Object.keys(oldData).length !== Object.keys(newData).length) return false;
  for (const key of Object.keys(oldData)) {
    if (!(key in newData)) return false;
    if (!Object.is(oldData[key], newData[key])) return false;
  }

  // for functions and the error object, comparing the references is enough
  if (!Object.is(oldOnZoom, newOnZoom)) return false;
  if (!Object.is(oldExportFilename, newExportFilename)) return false;
  if (!Object.is(oldError, newError)) return false;

  // other props are either primitives like strings, numbers or Dates,
  // or relatively shallow objects consisting of such primitives
  return deepEqual(oldOtherProps, newOtherProps);
};

export const TimeSeriesMultiLineChart = React.memo(
  TimeSeriesMultiLineChartComponent,
  arePropsEqual,
);

const insertNaNValues = (dataPoints: DataPoint[], longestDrawnGapMilliSeconds: number | null) => {
  if (longestDrawnGapMilliSeconds == null) return dataPoints;
  const dataPointsWithGaps: DataPoint[] = [];
  for (let i = 0; i < dataPoints.length; i++) {
    const current = dataPoints[i];
    dataPointsWithGaps.push(current);
    if (i < dataPoints.length - 1) {
      const next = dataPoints[i + 1];
      const diffMilliSeconds = next.time.getTime() - current.time.getTime();
      if (diffMilliSeconds > longestDrawnGapMilliSeconds) {
        dataPointsWithGaps.push({
          time: new Date(current.time.getTime() + diffMilliSeconds / 2),
          value: NaN,
        });
      }
    }
  }
  return dataPointsWithGaps;
};

const downloadCsv = (
  variableSources: { [timeseriesName: string]: { variables: ChartVariable[] } },
  data: { [timeseriesName: string]: Row[] | null | undefined },
  title: string,
  exportFilename: ((startTime: Date, endTime: Date, chartTitle: string) => string) | null,
): void => {
  const joinedData = Object.entries(variableSources)
    .map(([timeseriesName, { variables }]) => ({
      // use raw variant of data if available
      data: data[timeseriesName + '#export'] ?? data[timeseriesName] ?? [],
      columns: variables.map((variable) => variable.sourceColumn),
    }))
    .reduce(
      (acc, current) => ({
        data: joinTimeseriesData(acc.data, acc.columns, current.data, current.columns) as Row[],
        columns: [...acc.columns, ...current.columns],
      }),
      { data: [], columns: [] },
    ).data;

  if (joinedData.length === 0) {
    return;
  }

  const columns = [
    'time',
    ...Object.values(variableSources).flatMap((source) =>
      source.variables.map((variable) => variable.sourceColumn),
    ),
  ];

  const headerRow = columns.map((column) => `"${column}"`).join(',') + '\n';

  const rows = joinedData.map(
    (row) =>
      columns
        .map((column) =>
          column === 'time'
            ? new Date(row.time).toISOString()
            : typeof row[column] === 'number' && Number.isFinite(row[column])
            ? (row[column] as number).toString()
            : '',
        )
        .join(',') + '\n',
  );

  const filename =
    exportFilename != null
      ? exportFilename(
          new Date(joinedData[0].time),
          new Date(joinedData[joinedData.length - 1].time),
          title,
        )
      : `${title} ${new Date(joinedData[0].time).toISOString()}`;

  const blob = new Blob([headerRow].concat(rows), { type: 'text/csv' });
  downloadBlob(blob, `${filename}.csv`);
};

const downloadBlob = (blob: Blob, filename: string): void => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
};
