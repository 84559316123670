import useSWR from 'swr';
import type { SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';
import dayjs from 'dayjs';

import type { FetchHookData, MultiMarketSnapshot } from '~types';

import { http } from '~http';

import type { DateTimeRange } from '~utils/time';

async function fetchSnapshot(url: string): Promise<MultiMarketSnapshot> {
  const response = await http.get(url, {
    timeout: 30_000,
  });
  return response.data;
}

export function useMultiMarketSnapshot(
  delivery_period_id: string,
  at_time: string,
  fleet_name: string,
  config?: SWRConfiguration,
): FetchHookData<MultiMarketSnapshot> {
  const searchParams = new URLSearchParams({
    observation_time: at_time,
    include_capacities: 'false',
  });
  return useSWR<MultiMarketSnapshot>(
    `/v1/snapshot/${fleet_name}/${delivery_period_id}?${searchParams.toString()}`,
    {
      ...config,
      fetcher: fetchSnapshot,
    },
  );
}

type AllocationRow = {
  start_time: string;
  end_time: string;
  duration: number;
  allocation_in_kw: string;
  price_per_kwh: string;
  currency: string | null;
  received_at: string | null;
};

export function useLatestAllocation(
  fleetId: string,
  atTime: Date,
): FetchHookData<AllocationRow | null> {
  const { data, isLoading, error, mutate } = useAllocations(fleetId, {
    // Match the range fetched by the FCRGraph component
    // to avoid fetching 2 snapshot ranges.
    // TODO: use the allocations API for instead of snapshot range.
    start: dayjs.utc(atTime).subtract(5, 'hours').startOf('hour').toDate(),
    end: dayjs.utc(atTime).add(1, 'hour').startOf('hour').toDate(),
  });
  if (typeof data === 'undefined') {
    return { data: undefined, isLoading, error, mutate };
  }
  const latestAllocation = data[data.length - 1];
  return {
    data: latestAllocation,
    isLoading,
    error,
    mutate,
  };
}

export function useAllocations(
  fleetId: string,
  range: DateTimeRange,
): FetchHookData<AllocationRow[]> {
  return useSWRImmutable([`/v1/snapshot/${fleetId}/range`, range], fetchAllocations);
}

async function fetchAllocations([url, range]: [url: string, range: DateTimeRange]): Promise<
  AllocationRow[]
> {
  // TODO: This is a temporary solution to get allocations data
  // from the snapshot range endpoint.
  // This is a slow and inefficient way to fetch them.
  // This should soon be replaced with a dedicated API for allocations.
  const response = await http.get(url, {
    params: {
      start_time: range.start.toISOString(),
      end_time: range.end.toISOString(),
      observation_time: range.end.toISOString(),
      include_capacities: false,
    },
    timeout: 30_000,
  });
  const allocations = response.data.data?.['FI FCR-N']?.allocations?.data.filter(
    (allocation: AllocationRow) => {
      const startTimestamp = new Date(allocation.start_time).getTime();
      return startTimestamp >= range.start.getTime() && startTimestamp < range.end.getTime();
    },
  );
  return allocations;
}
