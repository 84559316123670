import type { Tab } from '~pages/v2/esus/types';
import { TABS } from '~pages/v2/esus/constants';
import { useESUsNavigation } from '~pages/v2/esus/hooks/useESUsNavigation';
import { createESUPath } from '~pages/v2/esus/utils/createESUPath';

import { TabsRedirection } from '~components/TabsRedirection';

// Smart redirection for ESU index route
export function ESURedirect() {
  const { esuId, tab } = useESUsNavigation();

  return (
    <TabsRedirection
      tabs={TABS}
      currentTab={tab}
      createPath={(tab: Tab) => createESUPath(esuId, tab)}
    />
  );
}
