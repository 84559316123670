import { Outlet } from 'react-router-dom';

import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useOrganization } from '~hooks/useOrganization';

import { TABS } from '~pages/v2/organizations/constants';
import { useOrganizationsNavigation } from '~pages/v2/organizations/hooks/useOrganizationsNavigation';
import { createOrganizationPath } from '~pages/v2/organizations/utils/createOrganizationPath';

import { Tabs } from '~components/Tabs';

export function Organization() {
  const { organizationId, tab } = useOrganizationsNavigation();
  const { data: organization } = useOrganization(organizationId);

  useRegisterBreadcrumbs(
    'organizations',
    organization?.human_name ?? '',
    createOrganizationPath(organizationId),
  );

  return (
    <>
      <Tabs tabs={TABS} tab={tab} pathname={createOrganizationPath(organizationId)} />
      <Outlet />
    </>
  );
}
