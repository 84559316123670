import { type Duration, sub } from 'date-fns';
import axios from 'axios';

import { BASE_URL } from '~http';

import type { AggregateColumns, Columns, RawQueryResult, TimeSeriesQuery } from './types';

function formatColumns(columnsBySeries: Columns | AggregateColumns): string {
  return Object.entries(columnsBySeries)
    .flatMap(([series, columns]) => {
      return (columns as string[]).map((column) => `${series}.${column}`);
    })
    .join(',');
}

function stringifyStart(value: Date | Duration | undefined, end: Date): { start: string } | null {
  if (!value) {
    return null;
  }
  if (value instanceof Date) {
    return { start: value.toISOString() };
  }
  return { start: sub(end, value).toISOString() };
}

function queryToSearchParams(query: TimeSeriesQuery): URLSearchParams {
  const resources = query.resources.join(',');
  const columns = formatColumns(query.columns);
  switch (query.type) {
    case 'latest': {
      const atTime = query.atTime === 'now' ? new Date() : query.atTime;
      return new URLSearchParams({
        latest: 'true',
        resources,
        columns,
        not_null: query.notNull.toString(),
        include_schema: 'false',
        ...stringifyStart(query.start, atTime),
        at: atTime.toISOString(),
      });
    }
    case 'latest_forecast': {
      const atTime = query.atTime === 'now' ? new Date() : query.atTime;
      return new URLSearchParams({
        latest_forecast: 'true',
        resources,
        columns,
        include_schema: 'false',
        ...stringifyStart(query.start, atTime),
        at: atTime.toISOString(),
      });
    }
    case 'range': {
      const end = query.end === 'now' ? new Date() : query.end;
      return new URLSearchParams({
        resources,
        columns,
        fill_start:
          typeof query.fillStart === 'boolean'
            ? query.fillStart.toString()
            : query.fillStart.toISOString(),
        fill_end:
          typeof query.fillEnd === 'boolean'
            ? query.fillEnd.toString()
            : query.fillEnd.toISOString(),
        allow_partial_result: query.allowPartialResult.toString(),
        include_schema: 'false',
        ...stringifyStart(query.start, end),
        end: end.toISOString(),
      });
    }
    case 'aggregate': {
      const end = query.end === 'now' ? new Date() : query.end;
      return new URLSearchParams({
        resources,
        columns,
        step: query.step,
        fill_na_method: query.fillNaMethod,
        include_schema: 'false',
        ...stringifyStart(query.start, end),
        end: end.toISOString(),
      });
    }
    default: {
      throw new Error('Invalid query type');
    }
  }
}

export async function fetchQuery(query: TimeSeriesQuery): Promise<RawQueryResult> {
  try {
    const urlSearchParams = queryToSearchParams(query);
    const response = await axios.post('/v2/timeseries', urlSearchParams, {
      baseURL: BASE_URL,
      timeout: 60_000,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch timeseries data', error);
    throw error;
  }
}

export async function fetchQuerySerially(query: TimeSeriesQuery): Promise<RawQueryResult> {
  const results = [];
  for (const resource of query.resources) {
    const response = await fetchQuery({
      ...query,
      resources: [resource],
    });
    results.push(...response.results);
  }
  return { results };
}
