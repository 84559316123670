import { useMeteringGroup } from '~hooks/useMeteringGroupList';

import { useSitesNavigation } from '~pages/v2/sites/hooks/useSitesNavigation';

import { HierarchySite } from '~components/hierarchy/HierarchySite';
import { HierarchyLoading } from '~components/hierarchy/HierarchyLoading';

export function SiteHierarchy() {
  const { meteringGroupId } = useSitesNavigation();
  const { data: meteringGroup, isLoading } = useMeteringGroup(meteringGroupId);

  if (isLoading) return <HierarchyLoading />;
  if (!meteringGroup) return null;

  return <HierarchySite isRoot meteringGroup={meteringGroup} />;
}
