import { type MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

import { asCactosError } from '~http';

import { formatLocalTime } from '~utils/time';

import { useMeteringGroupContext } from '~hooks/useMeteringGroupContext';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';

import type {
  Attachment,
  Category,
  Direction,
  Pricing,
  PricingItem,
  SpotItem,
} from '~pages/settings/groups/pricing/types';
import { PricingWizard } from '~pages/settings/groups/pricing/wizard/PricingWizard';
import {
  PricingAttachmentsDialog,
  PricingCancelConfirmationDialog,
  PricingDeleteConfirmationDialog,
  PricingHistoryDialog,
  PricingSaveConfirmationDialog,
  PricingSaveErrorDialog,
} from '~pages/settings/groups/pricing/PricingTableDialogs';
import {
  parseEffectivePeriod,
  printEffectivePeriodHours,
  printEffectivePeriodMonths,
  printEffectivePeriodWeekdays,
} from '~pages/settings/groups/pricing/utils/effectivePeriod';
import {
  deleteAttachment,
  deletePricing,
  savePricing,
  savePricingAttachments,
  updateAttachment,
} from '~pages/settings/groups/pricing/utils/pricing';
import { CURRENCY, TIME_ZONE } from '~pages/settings/groups/pricing/constants';

import { Iconify } from '~components/Iconify';

type BooleanIconProps = {
  value: boolean;
};

type EffectivePeriodProps = {
  value?: string;
};

type SpotItemViewProps = {
  item: SpotItem;
};

type PricingItemViewProps = {
  item: PricingItem;
  currency: string;
};

type PricingTableItemsContentProps = {
  pricing: Pricing;
  direction: Direction;
  spotItems: SpotItem[];
  pricingItems: PricingItem[];
};

type PricingViewProps = {
  pricing: Pricing;
  isEditing: boolean;
  setPricing: (pricing: Pricing | null) => void;
  setIsEditing: (isEditing: boolean) => void;
  refetchPricings: () => Promise<void>;
};

type PricingTableProps = {
  pricings: Pricing[];
  refetchPricings: () => Promise<void>;
};

const PRICING_ID_SEARCH_PARAMETER = 'pricingId';

const tableRowStyle = {
  '& > .MuiTableCell-root': {
    borderBottom: 'none',
    verticalAlign: 'top',
    padding: '4px 4px 4px 0px',
  },
  '& > .MuiTableCell-root.price': {
    width: '18rem',
  },
};

const menuItemStyle = {
  display: 'flex',
  flexDirection: 'row',
  gap: 1,
  justifyContent: 'space-between',
};

function displayCategoryTitle(category: Category): string {
  switch (category) {
    case 'TRANSFER':
      return 'Transfer price';
    case 'ENERGY':
      return 'Energy fixed price';
    case 'FEE':
      return 'Fee';
    case 'TAX':
      return 'Tax';
    default:
      return String(category).toLowerCase();
  }
}

function BooleanIcon({ value }: BooleanIconProps) {
  return value ? <Iconify icon="mdi:check" /> : <Iconify icon="mdi:close" />;
}

function EffectivePeriod({ value }: EffectivePeriodProps) {
  if (!value) return null;

  const { weekdays, hours, months } = parseEffectivePeriod(value);

  return (
    <>
      <u>Active time</u>:
      <div>
        {printEffectivePeriodWeekdays(weekdays)}
        <br />
        {printEffectivePeriodHours(hours)}
        <br />
        {printEffectivePeriodMonths(months)}
      </div>
    </>
  );
}

function SpotItemView({ item }: SpotItemViewProps) {
  return (
    <TableRow sx={tableRowStyle}>
      <TableCell />
      <TableCell />

      <TableCell>Energy spot price</TableCell>

      <TableCell style={{ fontFamily: 'monospace' }} className="price">
        {item.factor} SPOT
      </TableCell>

      <TableCell />

      <TableCell align="center">
        <BooleanIcon value={item.includes_vat} />
      </TableCell>
    </TableRow>
  );
}

function PricingItemView({ item, currency }: PricingItemViewProps) {
  return (
    <TableRow sx={tableRowStyle}>
      <TableCell />
      <TableCell />

      <TableCell>{displayCategoryTitle(item.category)}</TableCell>

      <TableCell style={{ fontFamily: 'monospace' }} className="price">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {item.amount} {currency}/kWh
            <Box mt={0.5}>
              <EffectivePeriod value={item.effective_period} />
            </Box>
          </Grid>
        </Grid>
      </TableCell>

      <TableCell>{item.name}</TableCell>

      <TableCell align="center">
        <BooleanIcon value={item.includes_vat} />
      </TableCell>
    </TableRow>
  );
}

function PricingTableItemsContent({
  pricing,
  direction,
  spotItems,
  pricingItems,
}: PricingTableItemsContentProps) {
  const spotItemsMemo = useMemo(
    () => spotItems.map((item, i) => <SpotItemView key={i} item={item} />),
    [spotItems],
  );
  const energyItemsMemo = useMemo(
    () =>
      pricingItems
        .filter((item) => item.category === 'ENERGY')
        .map((item, i) => <PricingItemView key={i} item={item} currency={pricing.currency} />),
    [pricing.currency, pricingItems],
  );
  const transferItemsMemo = useMemo(
    () =>
      pricingItems
        .filter((item) => item.category === 'TRANSFER')
        .map((item, i) => <PricingItemView key={i} item={item} currency={pricing.currency} />),
    [pricing.currency, pricingItems],
  );
  const feeAndTaxItemsMemo = useMemo(
    () =>
      pricingItems
        .filter((item) => item.category === 'FEE' || item.category === 'TAX')
        .map((item, i) => <PricingItemView key={i} item={item} currency={pricing.currency} />),
    [pricing.currency, pricingItems],
  );

  return (
    <>
      {/* Label */}
      <TableRow sx={tableRowStyle}>
        <TableCell colSpan={2} sx={{ fontWeight: 'bold', alignItems: 'center' }}>
          <Box px={1} pt={1}>
            {direction}
          </Box>
        </TableCell>
      </TableRow>

      {/* Items */}
      <TableRow>
        <TableCell colSpan={2} sx={{ p: 0, m: 0 }}>
          <Table>
            {/* Table column titles */}
            <TableHead>
              <TableRow sx={tableRowStyle}>
                <TableCell />
                <TableCell>Active pricing</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="center">VAT included</TableCell>
              </TableRow>
            </TableHead>

            {/* Table contents */}
            <TableBody>
              {/* Spot and Energy items */}
              {(spotItemsMemo.length > 0 || pricingItems.length > 0) && (
                <>
                  <TableRow sx={tableRowStyle}>
                    <TableCell />
                    <TableCell>Spot/Energy</TableCell>
                  </TableRow>

                  {spotItemsMemo}
                  {energyItemsMemo}
                </>
              )}

              {/* Transfer, fee and tax items */}
              {(transferItemsMemo.length > 0 || feeAndTaxItemsMemo.length > 0) && (
                <>
                  <TableRow sx={tableRowStyle}>
                    <TableCell />
                    <TableCell>Transfer</TableCell>
                  </TableRow>

                  {transferItemsMemo}
                  {feeAndTaxItemsMemo}
                </>
              )}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </>
  );
}

function PricingView({
  pricing,
  isEditing,
  setPricing,
  setIsEditing,
  refetchPricings,
}: PricingViewProps) {
  const [, setSearchParams] = useSearchParams();
  const [menuAnchorEl, setMenuAnchorEl] = useState<undefined | HTMLElement>(undefined);

  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isCancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [isAttachmentOpen, setAttachmentOpen] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [isSaveErrorOpen, setSaveErrorOpen] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [attachments, setAttachments] = useState<Attachment[]>(pricing.attachments ?? []);

  const { importSpotItems, importPricingItems, exportSpotItems, exportPricingItems } = useMemo(
    () => ({
      importSpotItems: pricing.spot_items.filter((spotItem) => spotItem.direction === 'IMPORT'),
      importPricingItems: pricing.items
        .filter((pricingItem) => pricingItem.direction === 'IMPORT')
        .sort((a, b) => a.category.localeCompare(b.category)),
      exportSpotItems: pricing.spot_items.filter((spotItem) => spotItem.direction === 'EXPORT'),
      exportPricingItems: pricing.items
        .filter((pricingItem) => pricingItem.direction === 'EXPORT')
        .sort((a, b) => a.category.localeCompare(b.category)),
    }),
    [pricing],
  );

  useEffect(() => {
    setAttachments(pricing.attachments ?? []);
  }, [isEditing, pricing.attachments]);

  const handleSave = useCallback(async () => {
    if (!pricing || isSaveLoading) return;

    setSaveLoading(true);

    try {
      const { data } = await savePricing(
        pricing.id || null,
        pricing.currency,
        pricing.metering_group_id,
        pricing.start_time,
        importPricingItems,
        exportPricingItems,
        importSpotItems,
        exportSpotItems,
      );

      if (data && data.id && data.metering_group_id && attachments.length !== 0) {
        const newAttachments = attachments.filter((attachment) => !attachment.id);
        const updatedAttachments = attachments.filter((attachment) => !!attachment.id);
        const deletedAttachmenets = pricing.attachments?.filter(
          (attachment) => !attachments.some((a) => a.id === attachment.id),
        );

        if (newAttachments.length) {
          await savePricingAttachments(data.id, newAttachments);
        }

        for (const attachement of updatedAttachments) {
          await updateAttachment(attachement.id!, attachement.content_metadata);
        }

        for (const attachement of deletedAttachmenets || []) {
          await deleteAttachment(attachement.id!);
        }
      }

      setIsEditing(false);

      // refetch pricing list *before* setting the new pricing
      try {
        await refetchPricings();
      } catch (error) {
        // ignore
      }

      setPricing(data);
    } catch (error) {
      setSaveError(asCactosError(error).message);
      setSaveErrorOpen(true);

      try {
        await refetchPricings();
      } catch (error) {
        // ignore
      }
    }

    setSaveLoading(false);
  }, [
    pricing,
    isSaveLoading,
    importPricingItems,
    exportPricingItems,
    importSpotItems,
    exportSpotItems,
    attachments,
    setIsEditing,
    setPricing,
    refetchPricings,
  ]);

  const handleMenuOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(undefined);
  }, []);

  const handleEditClick = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const handleDelete = useCallback(async () => {
    try {
      if (!pricing.id) return;

      await deletePricing(pricing.id);
      await refetchPricings();

      setSearchParams((sp) => {
        sp.delete(PRICING_ID_SEARCH_PARAMETER);

        return sp;
      });
    } catch (error) {
      console.error(error);
    }

    setDeleteConfirmationOpen(false);
  }, [pricing, setSearchParams, refetchPricings]);

  const handleDeleteConfirmationClose = useCallback(() => {
    setDeleteConfirmationOpen(false);
  }, []);

  const handleCancelConfirmationClose = useCallback(() => {
    setCancelConfirmationOpen(false);
  }, []);

  const handleCancelConfirmationConfirm = useCallback(() => {
    handleCancelEdit();
    setCancelConfirmationOpen(false);
  }, [handleCancelEdit]);

  const handleSaveConfirmationClose = useCallback(() => {
    setSaveConfirmationOpen(false);
  }, []);

  const handleSaveConfirmationConfirm = useCallback(() => {
    handleSave();
    setSaveConfirmationOpen(false);
  }, [handleSave]);

  const handleDuplicatePricing = useCallback(() => {
    handleMenuClose();

    if (!pricing) return;

    setPricing({
      ...pricing,
      id: null,
      current: false,
      attachments: [],
    });
    setIsEditing(true);
  }, [pricing, handleMenuClose, setPricing, setIsEditing]);

  return (
    <TableContainer sx={{ minWidth: 800 }}>
      {isEditing && (
        <PricingWizard
          pricing={pricing}
          setPricing={setPricing}
          handleCancel={() => setCancelConfirmationOpen(true)}
          attachments={attachments}
          setAttachments={setAttachments}
          handleSave={handleSave}
          isSaveLoading={isSaveLoading}
        />
      )}

      <Table>
        <TableBody>
          {/* Actions */}
          <TableRow>
            {/* Start time */}
            <TableCell sx={{ alignItems: 'center', paddingLeft: 0 }}>
              <Box display="flex" flexWrap="wrap" alignItems="center" gap={1}>
                <Typography fontWeight="medium" variant="h4">
                  {formatLocalTime(pricing.start_time)}
                </Typography>

                {!isEditing &&
                  (pricing.current ? (
                    <Chip
                      label={<Typography>Active</Typography>}
                      size="small"
                      color="success"
                      variant="outlined"
                    />
                  ) : (
                    <Chip
                      label={<Typography>Inactive</Typography>}
                      size="small"
                      color="error"
                      variant="outlined"
                    />
                  ))}
              </Box>
            </TableCell>

            {/* Buttons */}
            <TableCell align="right">
              <Box display="flex" gap="0.5rem" flexWrap="wrap" justifyContent="flex-end">
                {!isEditing && (
                  <>
                    <Button variant="outlined" onClick={() => setAttachmentOpen(true)}>
                      {pricing.attachments?.length ?? 0} Attachment
                      {(pricing.attachments?.length ?? 0) > 1 ? 's' : ''}
                    </Button>

                    <Button variant="contained" onClick={handleEditClick}>
                      Edit
                    </Button>

                    <Button
                      variant="contained"
                      aria-controls="menu"
                      aria-haspopup="true"
                      onClick={handleMenuOpen}
                    >
                      ...
                    </Button>

                    <Menu
                      id="menu"
                      anchorEl={menuAnchorEl}
                      open={Boolean(menuAnchorEl)}
                      onClose={handleMenuClose}
                      anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom',
                      }}
                      transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                      }}
                    >
                      <MenuItem sx={menuItemStyle} onClick={handleDuplicatePricing}>
                        <Iconify icon="mdi:content-copy" />
                        <ListItemText>Duplicate</ListItemText>
                      </MenuItem>

                      <MenuItem
                        sx={{
                          ...menuItemStyle,
                          color: 'red',
                        }}
                        onClick={() => {
                          handleMenuClose();
                          setDeleteConfirmationOpen(true);
                        }}
                      >
                        <Iconify icon="mdi:delete" />
                        <ListItemText>Delete</ListItemText>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Box>
            </TableCell>
          </TableRow>

          {/* Imports */}
          <PricingTableItemsContent
            pricing={pricing}
            direction="IMPORT"
            spotItems={importSpotItems}
            pricingItems={importPricingItems}
          />

          {/* Exports */}
          <PricingTableItemsContent
            pricing={pricing}
            direction="EXPORT"
            spotItems={exportSpotItems}
            pricingItems={exportPricingItems}
          />
        </TableBody>
      </Table>

      {/* Dialogs */}
      <PricingDeleteConfirmationDialog
        open={isDeleteConfirmationOpen}
        onClose={handleDeleteConfirmationClose}
        onConfirm={handleDelete}
      />

      <PricingCancelConfirmationDialog
        open={isCancelConfirmationOpen}
        onClose={handleCancelConfirmationClose}
        onConfirm={handleCancelConfirmationConfirm}
      />

      <PricingSaveConfirmationDialog
        open={isSaveConfirmationOpen}
        isNewPricing={!pricing.id}
        onClose={handleSaveConfirmationClose}
        onConfirm={handleSaveConfirmationConfirm}
      />

      <PricingSaveErrorDialog
        open={isSaveErrorOpen}
        error={saveError}
        onClose={() => setSaveErrorOpen(false)}
      />

      <PricingAttachmentsDialog
        open={isAttachmentOpen}
        attachments={attachments}
        onClose={() => setAttachmentOpen(false)}
      />
    </TableContainer>
  );
}

export function PricingTable({ pricings, refetchPricings }: PricingTableProps) {
  const { id: meteringGroupId } = useMeteringGroupContext();
  const { data: meteringGroups = [] } = useMeteringGroupList();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPricingId = searchParams.get(PRICING_ID_SEARCH_PARAMETER);

  const [pricing, setPricing] = useState<Pricing | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);

  const handleSetCurrentPricingId = useCallback(
    (pricingId?: string | null) => {
      setSearchParams((sp) => {
        if (pricingId) {
          sp.set(PRICING_ID_SEARCH_PARAMETER, pricingId);
        } else {
          sp.delete(PRICING_ID_SEARCH_PARAMETER);
        }

        return sp;
      });
    },
    [setSearchParams],
  );

  const handleSetPricing = useCallback(
    (pricing: Pricing | null) => {
      setPricing(pricing);
      handleSetCurrentPricingId(pricing?.id);
    },
    [handleSetCurrentPricingId],
  );

  const handleCreateNewPricing = useCallback(() => {
    handleSetPricing({
      metering_group_id: meteringGroupId,
      start_time: dayjs.utc().tz(TIME_ZONE).startOf('isoWeek').toISOString(),
      currency: CURRENCY,
      items: [],
      spot_items: [],
    });
    setIsEditing(true);
  }, [meteringGroupId, handleSetPricing]);

  useEffect(() => {
    if (isEditing) return;

    setPricing(
      pricings.find((p) => p.id === currentPricingId) ?? pricings.find((p) => p.current) ?? null,
    );
  }, [pricings, currentPricingId, isEditing]);

  useEffect(() => {
    if (!pricing) return;
    // Handle pricing deletion case
    if (currentPricingId && pricings.find((p) => p.id === currentPricingId)) return;

    handleSetCurrentPricingId(pricing?.id);
  }, [pricings, pricing, currentPricingId, handleSetCurrentPricingId]);

  const targetMeteringGroupDisplayName =
    meteringGroups.find((mg) => mg.id === pricing?.metering_group_id)?.name ??
    pricing?.metering_group_id;

  return (
    <>
      {isEditing && meteringGroupId !== pricing?.metering_group_id && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          This pricing was created for the metering group '{targetMeteringGroupDisplayName}' and
          will be saved in that group.
        </Alert>
      )}

      {pricing && (
        <PricingView
          pricing={pricing}
          setPricing={handleSetPricing}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          refetchPricings={refetchPricings}
        />
      )}

      {!isEditing && (
        <Box display="flex" gap="0.5rem" flexWrap="wrap" marginTop="1rem">
          <Button variant="contained" onClick={handleCreateNewPricing}>
            Add new pricing
          </Button>

          {pricings.length > 0 && (
            <Button variant="contained" onClick={() => setHistoryDialogOpen(true)}>
              View history
            </Button>
          )}
        </Box>
      )}

      <PricingHistoryDialog
        open={historyDialogOpen}
        onClose={() => setHistoryDialogOpen(false)}
        pricings={pricings}
        activePricing={pricing}
        setPricing={handleSetPricing}
      />
    </>
  );
}
