import useSWR, { preload } from 'swr';

import type { FetchHookData, Fleet } from '~types';

import { fetcher } from '~http';

export async function preloadFleetList() {
  try {
    return await preload('/v1/region/fleet', fetcher);
  } catch {
    return null;
  }
}

export function useFleetList(): FetchHookData<Fleet[]> {
  return useSWR<Fleet[]>('/v1/region/fleet', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });
}
