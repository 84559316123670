import { USER_ROLES } from '~constants/auth';

import { useUserHasRootRole } from '~hooks/useUserHasRootRole';

import { Schedule } from '~pages/fleet/esus/schedule/Schedule';
import { CustomerSchedule } from '~pages/CustomerSchedule';

import { ScrollView } from '~components/ScrollView';

export function ESUSchedule() {
  const canViewCactosSchedule = useUserHasRootRole(USER_ROLES.OPERATOR_VIEW);

  return <ScrollView>{canViewCactosSchedule ? <Schedule /> : <CustomerSchedule />}</ScrollView>;
}
