import type { ESUModel } from '~types';

export const ESU_MODELS: ESUModel[] = [
  {
    name: 'CACTOS_ONE_CLASSIC',
    label: 'Cactos One Classic',
    version: 1,
  },
  {
    name: 'CACTOS_ONE_CARDO',
    label: 'Cactos One Cardo',
    version: 1,
  },
  {
    name: 'CACTOS_TWO',
    label: 'Fero/Yavia',
    version: 2,
  },
  {
    name: 'CACTOS_TWO_GRID_SCALE',
    label: 'Yavia Pro',
    version: 2,
  },
];
