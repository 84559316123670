import { Box } from '@mui/material';
import type { PropsWithChildren } from 'react';

import { ScrollView } from '~components/ScrollView';

export function FleetLayout({ children }: PropsWithChildren) {
  return (
    <ScrollView>
      <Box p={2} display="flex" flexDirection="column" gap={2}>
        {children}
      </Box>
    </ScrollView>
  );
}
