import { useCallback } from 'react';
import { Grid, useTheme } from '@mui/material';
import { addMinutes, differenceInMinutes, subMinutes } from 'date-fns';

import { type DateTimeRange, formatRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';
import { transformAbsoluteValue } from '~utils/number';

import {
  createAggregateQuery,
  createLatestQuery,
  createRangeQuery,
  useTimeseriesAggregate,
  useTimeseriesLatest,
  useTimeseriesRange,
} from '~hooks/timeseries/queries';
import { useESU } from '~hooks/useESUList';
import { useCurrentESUId } from '~hooks/useCurrentESUId';
import type { ResourceID } from '~hooks/timeseries/types';
import { useESUSchedule } from '~hooks/useESUSchedule';

import { useSiteGridMeterData } from '~pages/v2/sites/hooks/useSiteGridMeterData';

import {
  ESUCellTempGraph,
  ESUCellVoltageGraph,
  TimeSeriesMultiLineChart,
} from '~components/charts';

import { useESUDiagnosticsData } from '../diagnostics/useDiagnosticsData';

const CELL_DATA_REFRESH_INTERVAL = 30_000; // ms

export type DiagnosticsChartsProps = {
  range: DateTimeRange;
  atTime: Date | 'now';
  setRange: (range: DateTimeRange) => void;
};

export function DiagnosticsCharts({ range, atTime, setRange }: DiagnosticsChartsProps) {
  const esuId = useCurrentESUId();
  const { data: esu } = useESU(esuId);
  const resourceId: ResourceID | null = esu != null ? esu.resource_id : null;

  const theme = useTheme();

  const {
    data: chartData,
    isLoading: isLoadingChartData,
    error: chartDataError,
  } = useChartData(resourceId, range);
  const isAggregatedOverTime = chartData.isAggregatedOverTime;
  const {
    data: scheduleData,
    extendedData: extendedScheduleData,
    isLoading: isLoadingSchedule,
    error: scheduleError,
  } = useESUSchedule(resourceId, range);
  const {
    data: gridMeterData,
    isLoading: isLoadingGridMeter,
    error: gridMeterError,
  } = useSiteGridMeterData(esu?.edge_controller.metering_group.id ?? null, range);

  const esuName = esu?.name;
  const chartExportFilename = useCallback(
    (startTime: Date, endTime: Date, chartTitle: string) =>
      `${chartTitle} ${esuName ?? esuId} at ${startTime.toISOString()}`,
    [esuName, esuId],
  );

  const rangeString = formatRange(range);

  // don't draw over gap when 5 pings dropped
  const rawDataLongestGapMs = 60_000;
  // don't draw over gap when 5 data points are missing
  const aggregateLongestGapMs = getAggregageQueryTimeStepSeconds(range) * 5_000;

  const annotationLineColor = theme.palette.grey[500];
  const annotationStrokeDasharray = '4,2';

  const timeAxis = {
    clipMin: range.start,
    include: [range.start, range.end],
    clipMax: range.end,
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="State of charge"
          subtitle={rangeString}
          height={280}
          data={{
            esu_status: chartData.esu_status,
            schedule_history: extendedScheduleData,
            'schedule_history#export': scheduleData,
          }}
          isLoading={isLoadingChartData || isLoadingSchedule}
          error={chartDataError ?? scheduleError}
          units="%"
          tooltipDecimalDigits={0}
          timeAxis={timeAxis}
          valueAxis={{ include: [0, 100] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            esu_status: {
              variables: [
                {
                  name: isAggregatedOverTime ? 'SoC (mean)' : 'SoC',
                  sourceColumn: isAggregatedOverTime ? 'soc:mean' : 'soc',
                  color: theme.palette.chart.single,
                },
              ],
              longestDrawnGapMs: isAggregatedOverTime ? aggregateLongestGapMs : rawDataLongestGapMs,
            },
            schedule_history: {
              variables: [
                {
                  name: 'SoC Target Max',
                  sourceColumn: 'max_target_soc',
                  color: theme.palette.chart.gray,
                  interpolation: 'step-after',
                  strokeDasharray: '6,3',
                },
                {
                  name: 'SoC Target Min',
                  sourceColumn: 'min_target_soc',
                  color: theme.palette.chart.gray,
                  interpolation: 'step-after',
                  strokeDasharray: '6,3',
                  lowerBoundOfArea: {
                    upperBoundSeriesName: 'SoC Target Max',
                    areaColor: theme.palette.chart.gray,
                    areaOpacity: 0.2,
                  },
                },
              ],
            },
          }}
        />
      </Grid>

      <DiagnosticCellCharts esuId={esuId} atTime={atTime} />

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="Active power"
          subtitle={rangeString}
          height={280}
          data={{
            esu_power_control: chartData.esu_power_control,
            grid_meter: gridMeterData,
          }}
          isLoading={isLoadingChartData || isLoadingGridMeter}
          error={chartDataError ?? gridMeterError}
          units="kW"
          timeAxis={timeAxis}
          valueAxis={{ include: [-1, 1] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            esu_power_control: {
              variables: isAggregatedOverTime
                ? [
                    {
                      name: 'ESU power (max)',
                      sourceColumn: 'esu_realized_power:max',
                      denominator: 1000,
                      color: theme.palette.chart.categorical10[1],
                      opacity: 0.25,
                      includeInLegend: false,
                    },
                    {
                      name: 'ESU power (mean)',
                      sourceColumn: 'esu_realized_power:mean',
                      denominator: 1000,
                      color: theme.palette.chart.categorical10[1],
                      centerOfArea: {
                        name: 'ESU power',
                        lowerBoundSeriesName: 'ESU power (min)',
                        upperBoundSeriesName: 'ESU power (max)',
                        areaOpacity: 0.2,
                      },
                    },
                    {
                      name: 'ESU power (min)',
                      sourceColumn: 'esu_realized_power:min',
                      denominator: 1000,
                      color: theme.palette.chart.categorical10[1],
                      opacity: 0.25,
                      includeInLegend: false,
                    },
                  ]
                : [
                    {
                      name: 'ESU power',
                      sourceColumn: 'esu_realized_power',
                      denominator: 1000,
                      color: theme.palette.chart.categorical10[1],
                    },
                  ],
            },
            grid_meter: {
              variables: [
                {
                  name: 'Grid power (max)',
                  sourceColumn: 'grid_active_power:max',
                  denominator: 1000,
                  color: theme.palette.chart.categorical10[0],
                  opacity: 0.25,
                  includeInLegend: false,
                },
                {
                  name: 'Grid power (mean)',
                  sourceColumn: 'grid_active_power:mean',
                  denominator: 1000,
                  color: theme.palette.chart.categorical10[0],
                  centerOfArea: {
                    name: 'Grid power',
                    lowerBoundSeriesName: 'Grid power (min)',
                    upperBoundSeriesName: 'Grid power (max)',
                    areaOpacity: 0.2,
                  },
                },
                {
                  name: 'Grid power (min)',
                  sourceColumn: 'grid_active_power:min',
                  denominator: 1000,
                  color: theme.palette.chart.categorical10[0],
                  opacity: 0.25,
                  includeInLegend: false,
                },
              ],
              longestDrawnGapMs: aggregateLongestGapMs,
            },
          }}
          chartAnnotations={[
            {
              type: 'line',
              height: 0,
              color: annotationLineColor,
              strokeDasharray: annotationStrokeDasharray,
            },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="ESU power components"
          subtitle={rangeString}
          height={400}
          data={{ esu_power_control: chartData.esu_power_control }}
          isLoading={isLoadingChartData}
          error={chartDataError}
          units="kW"
          tooltipDecimalDigits={3}
          timeAxis={timeAxis}
          valueAxis={{ include: [-1, 1] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            esu_power_control: {
              variables: [
                {
                  name: isAggregatedOverTime ? 'Requested (mean)' : 'Requested',
                  sourceColumn: isAggregatedOverTime
                    ? 'esu_requested_power:mean'
                    : 'esu_requested_power',
                  denominator: 1000,
                  color: theme.palette.chart.categorical10[0],
                },
                {
                  name: isAggregatedOverTime ? 'Constant (LS/NEM) (mean)' : 'Constant (LS/NEM)',
                  sourceColumn: isAggregatedOverTime ? 'constant_power:mean' : 'constant_power',
                  denominator: 1000,
                  color: theme.palette.chart.categorical10[1],
                },
                {
                  name: isAggregatedOverTime ? 'FCR-N activated (mean)' : 'FCR-N activated',
                  sourceColumn: isAggregatedOverTime
                    ? 'fcrn_activated_power:mean'
                    : 'fcrn_activated_power',
                  denominator: 1000,
                  color: theme.palette.chart.categorical10[2],
                },
                {
                  name: isAggregatedOverTime ? 'FCR-N offset (mean)' : 'FCR-N offset',
                  sourceColumn: isAggregatedOverTime
                    ? 'fcrn_offset_power:mean'
                    : 'fcrn_offset_power',
                  denominator: 1000,
                  color: theme.palette.chart.categorical10[3],
                },
                {
                  name: isAggregatedOverTime ? 'AEM (mean)' : 'AEM',
                  sourceColumn: isAggregatedOverTime ? 'aem_power:mean' : 'aem_power',
                  denominator: 1000,
                  color: theme.palette.chart.categorical10[4],
                },
                {
                  name: isAggregatedOverTime ? 'Internal throttling (mean)' : 'Internal throttling',
                  sourceColumn: isAggregatedOverTime
                    ? 'internal_throttling:mean'
                    : 'internal_throttling',
                  denominator: 1000,
                  color: theme.palette.chart.categorical10[5],
                },
                {
                  name: isAggregatedOverTime ? 'Peak shaving (mean)' : 'Peak shaving',
                  sourceColumn: isAggregatedOverTime ? 'cbps_power:mean' : 'cbps_power',
                  denominator: 1000,
                  color: theme.palette.chart.categorical10[6],
                },
                {
                  name: isAggregatedOverTime ? 'Reserve power (mean)' : 'Reserve power',
                  sourceColumn: isAggregatedOverTime ? 'reserve_power:mean' : 'reserve_power',
                  denominator: 1000,
                  color: theme.palette.chart.categorical10[7],
                },
                {
                  name: isAggregatedOverTime ? 'FCR-D activated (mean)' : 'FCR-D activated',
                  sourceColumn: isAggregatedOverTime
                    ? 'fcrd_activated_power:mean'
                    : 'fcrd_activated_power',
                  denominator: 1000,
                  color: theme.palette.chart.categorical10[8],
                },
              ],
              longestDrawnGapMs: isAggregatedOverTime ? aggregateLongestGapMs : rawDataLongestGapMs,
              snapTooltip: true,
            },
          }}
          chartAnnotations={[
            {
              type: 'line',
              height: 0,
              color: annotationLineColor,
              strokeDasharray: annotationStrokeDasharray,
            },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="Grid current"
          subtitle={rangeString}
          height={280}
          data={{
            grid_meter: gridMeterData,
            schedule_history: extendedScheduleData,
            'schedule_history#export': scheduleData,
          }}
          isLoading={isLoadingGridMeter || isLoadingSchedule}
          error={gridMeterError ?? scheduleError}
          units="A"
          tooltipDecimalDigits={0}
          timeAxis={timeAxis}
          valueAxis={{ include: [0, 100] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            grid_meter: {
              variables: [
                {
                  name: 'L1 (max)',
                  sourceColumn: 'current_a:max',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[0],
                  opacity: 0.25,
                  includeInLegend: false,
                },
                {
                  name: 'L1 (mean)',
                  sourceColumn: 'current_a:mean',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[0],
                  centerOfArea: {
                    name: 'L1',
                    lowerBoundSeriesName: 'L1 (min)',
                    upperBoundSeriesName: 'L1 (max)',
                    areaOpacity: 0.2,
                  },
                },
                {
                  name: 'L1 (min)',
                  sourceColumn: 'current_a:min',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[0],
                  opacity: 0.25,
                  includeInLegend: false,
                },
                {
                  name: 'L2 (max)',
                  sourceColumn: 'current_b:max',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[1],
                  opacity: 0.25,
                  includeInLegend: false,
                },
                {
                  name: 'L2 (mean)',
                  sourceColumn: 'current_b:mean',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[1],
                  centerOfArea: {
                    name: 'L2',
                    lowerBoundSeriesName: 'L2 (min)',
                    upperBoundSeriesName: 'L2 (max)',
                    areaOpacity: 0.2,
                  },
                },
                {
                  name: 'L2 (min)',
                  sourceColumn: 'current_b:min',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[1],
                  opacity: 0.25,
                  includeInLegend: false,
                },
                {
                  name: 'L3 (max)',
                  sourceColumn: 'current_c:max',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[2],
                  opacity: 0.25,
                  includeInLegend: false,
                },
                {
                  name: 'L3 (mean)',
                  sourceColumn: 'current_c:mean',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[2],
                  centerOfArea: {
                    name: 'L3',
                    lowerBoundSeriesName: 'L3 (min)',
                    upperBoundSeriesName: 'L3 (max)',
                    areaOpacity: 0.2,
                  },
                },
                {
                  name: 'L3 (min)',
                  sourceColumn: 'current_c:min',
                  transform: transformAbsoluteValue,
                  color: theme.palette.chart.categorical3[2],
                  opacity: 0.25,
                  includeInLegend: false,
                },
              ],
              longestDrawnGapMs: aggregateLongestGapMs,
              snapTooltip: true,
            },
            schedule_history: {
              variables: [
                {
                  name: 'Peak shaving threshold',
                  sourceColumn: 'peak_shaving_threshold',
                  color: theme.palette.chart.gray,
                  interpolation: 'step-after',
                  strokeDasharray: '6,3',
                },
              ],
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="Cell temperature"
          subtitle={rangeString}
          height={280}
          data={{ bms_temperatures: chartData.bms_temperatures }}
          isLoading={isLoadingChartData}
          error={chartDataError}
          units="°C"
          tooltipDecimalDigits={0}
          timeAxis={timeAxis}
          valueAxis={{ include: [10, 50] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            bms_temperatures: {
              variables: [
                {
                  name: 'Temperature (max)',
                  sourceColumn: isAggregatedOverTime ? '*:max' : 'max(*)',
                  color: theme.palette.chart.coldHot.hot,
                },
                {
                  name: 'Temperature (min)',
                  sourceColumn: isAggregatedOverTime ? '*:min' : 'min(*)',
                  color: theme.palette.chart.coldHot.cold,
                  lowerBoundOfArea: {
                    upperBoundSeriesName: 'Temperature (max)',
                    areaColor: theme.palette.chart.coldHot.area,
                    areaOpacity: 0.2,
                  },
                },
              ],
              longestDrawnGapMs: isAggregatedOverTime ? aggregateLongestGapMs : rawDataLongestGapMs,
              snapTooltip: true,
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="Grid frequency"
          subtitle={rangeString}
          height={400}
          data={{ esu_power_control: chartData.esu_power_control }}
          isLoading={isLoadingChartData}
          error={chartDataError}
          units="Hz"
          tooltipDecimalDigits={3}
          timeAxis={timeAxis}
          valueAxis={{
            clipMin: 47.5,
            include: [49.8, 50.2],
          }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            esu_power_control: {
              variables: isAggregatedOverTime
                ? [
                    {
                      name: 'Frequency (max)',
                      sourceColumn: 'control_grid_f:max',
                      denominator: 1000,
                      color: theme.palette.chart.aggregate.max,
                    },
                    {
                      name: 'Frequency (mean)',
                      sourceColumn: 'control_grid_f:mean',
                      denominator: 1000,
                      color: theme.palette.chart.aggregate.mean,
                    },
                    {
                      name: 'Frequency (min)',
                      sourceColumn: 'control_grid_f:min',
                      denominator: 1000,
                      color: theme.palette.chart.aggregate.min,
                      lowerBoundOfArea: {
                        upperBoundSeriesName: 'Frequency (max)',
                        areaColor: theme.palette.chart.aggregate.area,
                        areaOpacity: 0.2,
                      },
                    },
                  ]
                : [
                    {
                      name: 'Frequency',
                      sourceColumn: 'control_grid_f',
                      denominator: 1000,
                      color: theme.palette.chart.single,
                    },
                  ],
              longestDrawnGapMs: isAggregatedOverTime ? aggregateLongestGapMs : rawDataLongestGapMs,
              snapTooltip: true,
            },
          }}
          chartAnnotations={[
            {
              type: 'area',
              y: { from: 49.9, to: 50.1 },
              color: theme.palette.chart.validity.good,
              opacity: 0.15,
            },
            {
              type: 'line',
              height: 50,
              color: annotationLineColor,
              strokeDasharray: annotationStrokeDasharray,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

function DiagnosticCellCharts(props: { esuId: string; atTime: Date | 'now' }) {
  const { data: diagnostics } = useESUDiagnosticsData(props.esuId, props.atTime);
  const bmsResources = diagnostics?.data_found ? diagnostics.bms_resources : [];
  const { data: cellData, isLoading: isLoadingCellData } = useTimeseriesLatest(
    createLatestQuery({
      resources: bmsResources.map((bms) => bms.resource_id),
      columns: { bms_cell_voltages: ['*'], bms_temperatures: ['*'] },
      atTime: props.atTime,
      start: { minutes: 15 },
    }),
    { refreshInterval: CELL_DATA_REFRESH_INTERVAL },
  );

  return (
    <>
      {bmsResources.map((bms) => {
        if (bms.battery_info == null) return null;
        return (
          <Grid key={`voltages_${bms.resource_id}`} item xs={12}>
            <ESUCellVoltageGraph
              title={`Cell voltages (${bms.name})`}
              bmsCellVoltages={cellData?.[bms.resource_id]?.bms_cell_voltages}
              isLoading={isLoadingCellData}
              batteryInfo={bms.battery_info}
            />
          </Grid>
        );
      })}
      {bmsResources.map((bms) => {
        if (bms.battery_info == null) return null;
        return (
          <Grid key={`temperatures_${bms.resource_id}`} item xs={12}>
            <ESUCellTempGraph
              title={`Cell temperatures (${bms.name})`}
              bmsTemperatures={cellData?.[bms.resource_id]?.bms_temperatures}
              isLoading={isLoadingCellData}
              batteryInfo={bms.battery_info}
            />
          </Grid>
        );
      })}
    </>
  );
}

function useChartData(resourceId: ResourceID | null, range: DateTimeRange) {
  const shouldAggregateOverTime = differenceInMinutes(range.end, range.start) > 120;
  const aggregateTimeStepSeconds = getAggregageQueryTimeStepSeconds(range);
  const resources = resourceId != null ? [resourceId] : [];
  const {
    data: aggregateData,
    isLoading: loadingAggregateData,
    error: aggregateDataError,
  } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: shouldAggregateOverTime ? resources : [],
      columns: {
        bms_temperatures: ['*:max', '*:min'],
        esu_status: ['soc:mean'],
        esu_power_control: [
          'esu_realized_power:mean',
          'esu_realized_power:min',
          'esu_realized_power:max',
          'control_grid_f:mean',
          'control_grid_f:min',
          'control_grid_f:max',
          // power components chart:
          'esu_requested_power:mean',
          'constant_power:mean',
          'fcrn_activated_power:mean',
          'fcrn_offset_power:mean',
          'aem_power:mean',
          'internal_throttling:mean',
          'cbps_power:mean',
          'reserve_power:mean',
          'fcrd_activated_power:mean',
        ],
      },
      start: range.start,
      end: range.end,
      step: `${aggregateTimeStepSeconds}s`,
    }),
  );

  const {
    data: rawData,
    isLoading: loadingRawData,
    error: rawDataError,
  } = useTimeseriesRange(
    createRangeQuery({
      resources: shouldAggregateOverTime ? [] : resources,
      columns: {
        bms_temperatures: ['max(*)', 'min(*)'],
        esu_status: ['soc'],
        esu_power_control: [
          'esu_realized_power',
          'control_grid_f',
          // power components chart:
          'esu_requested_power',
          'constant_power',
          'fcrn_activated_power',
          'fcrn_offset_power',
          'aem_power',
          'internal_throttling',
          'cbps_power',
          'reserve_power',
          'fcrd_activated_power',
        ],
      },
      start: range.start,
      end: range.end,
      fillStart: subMinutes(range.start, 1),
      fillEnd: addMinutes(range.end, 1),
    }),
  );

  if (shouldAggregateOverTime) {
    const data = resourceId != null ? aggregateData?.[resourceId] : null;
    return {
      data: {
        isAggregatedOverTime: true,
        bms_temperatures: data?.bms_temperatures ?? null,
        esu_status: data?.esu_status ?? null,
        esu_power_control: data?.esu_power_control ?? null,
      },
      isLoading: loadingAggregateData,
      error: aggregateDataError,
    };
  } else {
    const data = resourceId != null ? rawData?.[resourceId] : null;
    return {
      data: {
        isAggregatedOverTime: false,
        bms_temperatures: data?.bms_temperatures ?? null,
        esu_status: data?.esu_status ?? null,
        esu_power_control: data?.esu_power_control ?? null,
      },
      isLoading: loadingRawData,
      error: rawDataError,
    };
  }
}
