import { useMemo } from 'react';
import {
  type MRT_ColumnDef,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Alert, Box, Typography } from '@mui/material';

import type { Fleet } from '~types';

import { asCactosError } from '~http';

import { collator } from '~utils/localization';

import { ResultListContextProvider } from '~hooks/useResultListContext';
import { useMaterialTableColumnState } from '~hooks/useMaterialTableColumnState';
import { useFleetList } from '~hooks/useFleets';

import { SIDEBAR_USER_HEIGHT } from '~layouts/AppLayout/Sidebar';

import { useFleetsNavigation } from '~pages/v2/fleets/hooks/useFleetsNavigation';

import { BreadcrumbsProvider } from '~components/breadcrumbs/BreadcrumbsProvider';
import { Breadcrumbs } from '~components/breadcrumbs/Breadcrumbs';
import { SplitLayout } from '~components/SplitLayout';

const columns: MRT_ColumnDef<Fleet>[] = [
  {
    header: 'Name',
    id: 'name',
    accessorFn: (row) => row.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    Footer: NameFooter,
    size: 300,
  },
];

export function FleetsList() {
  const { data: fleets, error } = useFleetList();

  const { fleetId, tab, navigateToFleet } = useFleetsNavigation();

  const fleet = fleets?.find((fleet) => fleet.id === fleetId);

  const rowSelection = useMemo(() => (fleetId ? { [fleetId]: true } : {}), [fleetId]);

  const {
    columnSizing,
    columnFilters,
    columnVisibility,
    setColumnSizing,
    setColumnFilters,
    setColumnVisibility,
  } = useMaterialTableColumnState('fleets');

  const table = useMaterialReactTable<Fleet>({
    data: fleets ?? [],
    columns,
    initialState: {
      density: 'compact',
    },
    state: {
      rowSelection,
      columnSizing,
      columnFilters,
      columnVisibility,
      columnPinning: {
        left: ['name'],
      },
    },
    onColumnSizingChange: setColumnSizing,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableBottomToolbar: false,
    enableFacetedValues: true,
    positionToolbarAlertBanner: 'none',
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigateToFleet(row.id, tab);
      },
      sx: { cursor: 'pointer' },
    }),
    muiTableContainerProps: {
      sx: { maxHeight: 'calc(100vh - 128px)' },
    },
    muiTablePaperProps: {
      sx: { borderRadius: 0 },
    },
    muiTableFooterCellProps: {
      sx: { height: SIDEBAR_USER_HEIGHT + 1 },
    },
    renderEmptyRowsFallback: () =>
      error != null ? (
        <Alert
          severity="error"
          sx={{ borderRadius: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          Error loading data: {asCactosError(error).message}
        </Alert>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body2">
            <i>No fleets to display</i>
          </Typography>
        </Box>
      ),
  });
  const rows = useMRT_Rows(table);
  const resultListContext = useMemo(() => {
    const currentRowIndex = fleetId ? rows.findIndex((row) => row.id === fleetId) : -1;
    const totalRowCount = rows.length;
    return {
      currentRowIndex,
      totalRowCount,
      goToNextResult: () => {
        if (currentRowIndex + 1 >= totalRowCount) return;

        navigateToFleet(rows[currentRowIndex + 1].id, tab);
      },
      goToPreviousResult: () => {
        if (currentRowIndex <= 0) return;

        navigateToFleet(rows[currentRowIndex - 1].id, tab);
      },
      goToResultList: () => navigateToFleet(),
    };
  }, [rows, fleetId, tab, navigateToFleet]);

  return (
    <ResultListContextProvider value={resultListContext}>
      <BreadcrumbsProvider>
        <SplitLayout showDetails={!!fleet} renderTitle={() => <Breadcrumbs hasIcon />}>
          <MaterialReactTable table={table} />
        </SplitLayout>
      </BreadcrumbsProvider>
    </ResultListContextProvider>
  );
}

function NameFooter({ table }: { table: MRT_TableInstance<Fleet> }) {
  const rows = useMRT_Rows(table);

  return (
    <Box height="100%" display="flex" alignItems="center">
      {rows.length} fleet{rows.length > 1 ? 's' : ''}
    </Box>
  );
}
