import { ESUFaultsCard } from '~pages/fleet/cards/ESUFaultsCard';
import { FleetIssuesCard } from '~pages/fleet/cards/FleetIssuesCard';
import { BatteryMaxTemperatures } from '~pages/v2/fleets/components/BatteryMaxTemperatures';
import { BatteryMinTemperatures } from '~pages/v2/fleets/components/BatteryMinTemperatures';
import { AmbientTemperatures } from '~pages/v2/fleets/components/AmbientTemperatures';
import { CellVoltageDifference } from '~pages/v2/fleets/components/CellVoltageDifference';
import { FleetLayout } from '~pages/v2/fleets/components/FleetLayout';

export function FleetsDiagnostics() {
  return (
    <FleetLayout>
      <FleetIssuesCard />
      <ESUFaultsCard />
      <BatteryMaxTemperatures />
      <BatteryMinTemperatures />
      <AmbientTemperatures />
      <CellVoltageDifference />
    </FleetLayout>
  );
}
