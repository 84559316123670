import { type ReactElement, useCallback, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  LinearProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import dayjs from 'dayjs';
import * as _ from 'lodash-es';
import useSWR from 'swr';

import { asCactosError, fetcher } from '~http';

import {
  type LatestScheduleResponse,
  SCHEDULE_COLUMNS,
  type ScheduleRowWithTime,
  TIME_COLUMN,
  keepAsOf,
  toLatestScheduleResponse,
  toScheduleRowWithTime,
} from '~constants/schedule';

import { useCurrentESUId } from '~hooks/useCurrentESUId';
import { useESU } from '~hooks/useESUList';
import { useActiveControls } from '~hooks/useActiveControls';
import { useScheduleHistory } from '~hooks/useScheduleHistory';

import { Page } from '~components/Page';

import { ScheduleControls } from './ScheduleControls';

export function Schedule() {
  const currentEsuId = useCurrentESUId();
  const { data: esu, error: esuError, isLoading: isLoadingEsu } = useESU(currentEsuId);
  const [futureTab, setFutureTab] = useState<string>('computed');
  const [showEarlyHistory, setShowEarlyHistory] = useState<boolean>(false);

  const {
    data: scheduleHistory,
    error: scheduleHistoryError,
    isLoading: isLoadingScheduleHistory,
  } = useScheduleHistory(currentEsuId);

  const {
    data: activeControls,
    error: activeControlsError,
    isLoading: isLoadingActiveControls,
  } = useActiveControls(currentEsuId);

  const {
    data: latestScheduleResponse,
    error: latestScheduleError,
    isLoading: isLoadingLatestSchedule,
  } = useSWR(`/v1/ems/${currentEsuId}/latest_schedule`, {
    fetcher,
    refreshInterval: 60_000,
    revalidateOnFocus: false,
  });
  const latestSchedule: LatestScheduleResponse = useMemo(
    () =>
      latestScheduleResponse != null
        ? toLatestScheduleResponse(latestScheduleResponse)
        : { schedule: [] },
    [latestScheduleResponse],
  );
  const {
    data: previouslySentScheduleResponse,
    error: previouslySentScheduleError,
    isLoading: isLoadingPreviouslySentSchedule,
  } = useSWR(`/v1/ems/${currentEsuId}/previously_sent_schedule`, {
    fetcher,
    refreshInterval: 60_000,
    revalidateOnFocus: false,
  });
  const previouslySentSchedule: ScheduleRowWithTime[] = useMemo(
    () => previouslySentScheduleResponse?.data?.map(toScheduleRowWithTime) ?? [],
    [previouslySentScheduleResponse],
  );
  const {
    data: computedScheduleResponse,
    error: computedScheduleError,
    isLoading: isLoadingComputedSchedule,
  } = useSWR(`/v1/ems/${currentEsuId}/computed_schedule`, {
    fetcher,
    refreshInterval: 60_000,
    revalidateOnFocus: false,
  });
  const computedSchedule: ScheduleRowWithTime[] = useMemo(
    () => computedScheduleResponse?.data?.map(toScheduleRowWithTime) ?? [],
    [computedScheduleResponse],
  );

  const isLoading =
    isLoadingEsu ||
    isLoadingScheduleHistory ||
    isLoadingActiveControls ||
    isLoadingLatestSchedule ||
    isLoadingPreviouslySentSchedule ||
    isLoadingComputedSchedule;

  const RECENT_HISTORY_SIZE = 10;
  const earlyHistory = (history: ScheduleRowWithTime[]) =>
    _.dropRight(history, RECENT_HISTORY_SIZE);
  const recentHistory = (history: ScheduleRowWithTime[]) =>
    _.takeRight(history, RECENT_HISTORY_SIZE);
  const allColumns = SCHEDULE_COLUMNS.length + 1;

  const scheduleRows = useCallback(
    (
      schedule: ScheduleRowWithTime[],
      loadError?: unknown,
      replaceFirstTime?: string | ReactElement,
    ) => {
      if (!loadError) {
        return schedule.map((row, idx) => (
          <TableRow key={idx} tabIndex={-1}>
            <TableCell key="time" align="left">
              {(idx === 0 && replaceFirstTime) || TIME_COLUMN.formatter(row.time, false)}
            </TableCell>
            {SCHEDULE_COLUMNS.map((column) => {
              const value = row[column.id];
              return (
                <TableCell key={column.id} align="left">
                  {column.formatter ? column.formatter(value) : value}
                </TableCell>
              );
            })}
          </TableRow>
        ));
      }
      return (
        <TableRow>
          <TableCell colSpan={allColumns}>
            <Typography>{asCactosError(loadError).message}</Typography>
          </TableCell>
        </TableRow>
      );
    },
    [allColumns],
  );

  function formatCurrent(latestSchedule: LatestScheduleResponse): string | ReactElement {
    if (
      latestSchedule.schedule.length > 0 &&
      latestSchedule.created_by === 'OPTIMIZER_2' &&
      latestSchedule.detail !== undefined
    ) {
      return (
        <a href={`/dashboard/optimizer/${latestSchedule.detail}`}>
          {`Current (${latestSchedule.schedule[0].time.format('YYYY-MM-DD HH:mm')})`}
        </a>
      );
    }
    return 'Current';
  }

  function constructCsvString(rows: ScheduleRowWithTime[]): string {
    const csvRows: string[] = [];
    csvRows.push(
      'from_time,mode,min_target_soc,max_target_soc,charge_power,discharge_power,max_fcrn_charge,max_fcrn_discharge,peak_shaving_threshold,max_peak_shaving_output,fcrd_up_allocated_cap,fcrd_down_allocated_cap',
    );
    rows.forEach((row: ScheduleRowWithTime) => {
      csvRows.push(
        [
          row.time.local().format('YYYY-MM-DD HH:mm:ssZ'),
          row.mode,
          row.min_target_soc,
          row.max_target_soc,
          row.charge_power,
          row.discharge_power,
          row.max_fcrn_charge,
          row.max_fcrn_discharge,
          row.peak_shaving_threshold,
          row.max_peak_shaving_output,
          row.fcrd_up_allocated_cap ?? 0,
          row.fcrd_down_allocated_cap ?? 0,
        ].join(','),
      );
    });
    return csvRows.join('\n');
  }

  const downloadCsv = useCallback(() => {
    let schedule: ScheduleRowWithTime[];
    if (futureTab === 'latest') {
      schedule = latestSchedule.schedule;
    } else if (futureTab === 'computed') {
      schedule = computedSchedule;
    } else {
      return;
    }
    const link = document.createElement('a');
    link.id = 'download-link';
    link.download = `schedule-${futureTab}-${currentEsuId}-${dayjs(schedule[0].time).format(
      'YYYY-MM-DD-HH',
    )}.csv`;
    link.href = `data:text/plain;charset=utf-8,${encodeURIComponent(constructCsvString(schedule))}`;
    link.click();
    link.remove();
  }, [currentEsuId, futureTab, latestSchedule, computedSchedule]);

  return (
    <Page title={esu != null ? `${esu.name} Schedule` : 'Schedule'}>
      <Box paddingX={3} paddingY={2}>
        {esuError && <Alert severity="error">{asCactosError(esuError).message}</Alert>}
        {isLoading ? <LinearProgress sx={{ marginX: '8px' }} /> : <Box sx={{ height: 4 }} />}
        <Card>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell key="time" align="left" width={250}>
                    {TIME_COLUMN.label}
                  </TableCell>
                  {SCHEDULE_COLUMNS.map((column) => (
                    <TableCell key={column.id} align="left">
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={allColumns}>
                    <Box display="flex" flexDirection="row" justifyContent="center">
                      <Button onClick={() => setShowEarlyHistory(!showEarlyHistory)}>
                        Show {showEarlyHistory ? 'less' : 'more'} history
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
                {showEarlyHistory && scheduleRows(earlyHistory(scheduleHistory), undefined)}
                {scheduleRows(recentHistory(scheduleHistory), scheduleHistoryError)}
                <TableRow>
                  <TableCell colSpan={allColumns}>
                    {activeControlsError ? (
                      <Alert severity="error">
                        Unable to fetch active schedule controls:{' '}
                        {asCactosError(activeControlsError).message}
                      </Alert>
                    ) : (
                      <ScheduleControls
                        activeControls={activeControls ?? {}}
                        isLoading={isLoadingActiveControls}
                        error={activeControlsError}
                      />
                    )}
                    <Box display="flex" flexDirection="row" justifyContent="center">
                      <TabContext value={futureTab}>
                        <TabList onChange={(e, newTabValue: string) => setFutureTab(newTabValue)}>
                          <Tab label="Latest optimization result" value="latest" />
                          <Tab label="Previously sent to device" value="previously_sent" />
                          <Tab label="Planned schedule" value="computed" />
                        </TabList>
                      </TabContext>
                    </Box>
                  </TableCell>
                </TableRow>
                {futureTab === 'latest' &&
                  scheduleRows(
                    keepAsOf(_.last(scheduleHistory)?.time ?? dayjs(), latestSchedule.schedule),
                    latestScheduleError,
                    formatCurrent(latestSchedule),
                  )}
                {futureTab === 'previously_sent' &&
                  scheduleRows(previouslySentSchedule, previouslySentScheduleError, 'Current')}
                {futureTab === 'computed' && scheduleRows(computedSchedule, computedScheduleError)}
              </TableBody>
            </Table>
          </TableContainer>
          {['computed', 'latest'].includes(futureTab) && (
            <Button
              component="label"
              variant="contained"
              sx={{ margin: '1rem' }}
              onClick={downloadCsv}
            >
              Download CSV
            </Button>
          )}
        </Card>
      </Box>
    </Page>
  );
}
