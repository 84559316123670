import { useMemo } from 'react';

import { useEdgeController } from '~hooks/useEdgeControllerList';

import type { Tab } from '~pages/v2/controllers/types';
import { useControllersNavigation } from '~pages/v2/controllers/hooks/useControllersNavigation';

// Define the tabs that are available for the controller page depending on the controller type
export function useControllersAllowedTabs() {
  const { edgeControllerId, activeTab } = useControllersNavigation();
  const { data: edgeController } = useEdgeController(edgeControllerId);

  const allowedTabs = useMemo<Partial<Record<Tab, boolean>>>(
    () => ({
      events: edgeController?.type === 'ems' || edgeController?.type === 'legacy_cmu',
    }),
    [edgeController],
  );

  // An undefined allowedTabs means that the tab is allowed
  const isTabAllowed = useMemo(
    () => typeof allowedTabs[activeTab] !== 'boolean' || allowedTabs[activeTab],
    [allowedTabs, activeTab],
  );

  return {
    allowedTabs,
    isTabAllowed,
  };
}
