import { useEffect, useMemo, useState } from 'react';
import { Alert, CircularProgress, Table, TableBody, TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';

import { asCactosError } from '~http';

import { createRangeQuery, useTimeseriesRange } from '~hooks/timeseries/queries';

import { ALLOCATION_TZ, BIDDING_DOMAIN } from '~pages/v2/fleets/constants';
import { parseFiniteFloat } from '~pages/v2/fleets/utils/parseFiniteFloat';

import { DashboardCard, DashboardCardHeader } from '~components/DashboardCard';

type Props = {
  fleetId: string;
};

export function MonthlyAllocationsCard({ fleetId }: Props) {
  const [currentTimeMs, setCurrentTimeMs] = useState(new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => setCurrentTimeMs(new Date().getTime()), 60_000);
    return () => clearInterval(interval);
  }, []);

  const [start, end] = useMemo(() => {
    const now = dayjs(currentTimeMs).tz('CET');
    const start = dayjs(now).startOf('month').subtract(3, 'months').utc().toDate();
    const end = dayjs(now).endOf('month').utc().toDate();
    return [start, end];
  }, [currentTimeMs]);

  const { data, isLoading, error } = useTimeseriesRange(
    createRangeQuery({
      resources: [`region:${BIDDING_DOMAIN}`],
      columns: { fcr_allocation: ['quantity', 'price'] },
      start,
      end,
    }),
  );

  const allocationsByMonth: { [month: string]: number } = useMemo(() => {
    const rows = data?.[`region:${BIDDING_DOMAIN}`]?.fcr_allocation;
    if (rows == null) return {};

    return rows.reduce((acc: { [month: string]: number }, row) => {
      const value = (parseFiniteFloat(row.quantity) ?? NaN) * (parseFiniteFloat(row.price) ?? NaN);
      if (!Number.isFinite(value) || value === 0) return acc;
      const month = dayjs(row.time).tz(ALLOCATION_TZ).format('MMMM YYYY');
      return { ...acc, [month]: (acc[month] ?? 0) + value };
    }, {});
  }, [data]);

  if (fleetId !== 'cff-btm') {
    return null;
  }

  return (
    <DashboardCard size="medium">
      <DashboardCardHeader>
        Monthly allocation values {isLoading && <CircularProgress size={16} sx={{ ml: 1 }} />}
      </DashboardCardHeader>
      {!!error && (
        <Alert severity="error">
          Error loading allocation data: {asCactosError(error).message}
        </Alert>
      )}
      <Table
        size="small"
        sx={{
          '& td, & th': { lineHeight: '1.5rem' },
          '& td:first-of-type, & th:first-of-type': { paddingLeft: 0 },
          '& tr:last-child td': { borderBottom: 'none' },
        }}
      >
        <TableBody>
          {Object.entries(allocationsByMonth).map(([month, value]) => (
            <TableRow key={month}>
              <TableCell>{month}</TableCell>
              <TableCell align="right">{value.toFixed(2)}€</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DashboardCard>
  );
}
