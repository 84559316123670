import { type Dispatch, type SetStateAction, createContext, useContext, useEffect } from 'react';

import type { Breadcrumb, BreadcrumbType } from '~types';

export type BreadcrumbsContextType = {
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: Dispatch<SetStateAction<Breadcrumb[]>>;
  registerBreadcrumbs: (type: BreadcrumbType, label: string | null, path: string) => () => void;
};

export const BreadcrumbsContext = createContext<BreadcrumbsContextType>({
  breadcrumbs: [],
  setBreadcrumbs: () => {},
  registerBreadcrumbs: () => () => {},
});

export function useBreadcrumbs() {
  return useContext(BreadcrumbsContext);
}

export function useRegisterBreadcrumbs(type: BreadcrumbType, label: string | null, path: string) {
  const { registerBreadcrumbs } = useBreadcrumbs();

  useEffect(() => registerBreadcrumbs(type, label, path), [type, label, path, registerBreadcrumbs]);
}
