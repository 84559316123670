import { Box, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { LoadingBar } from '~layouts/AppLayout/LoadingBar';
import { SIDEBAR_MOBILE_HEIGHT, Sidebar } from '~layouts/AppLayout/Sidebar';

import { BreadcrumbsProvider } from '~components/breadcrumbs/BreadcrumbsProvider';
import { CommandPaletteLayout } from '~components/command-palette/CommandPaletteLayout';
import { StyledToaster } from '~components/StyledToaster';

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  maxHeight: '100vh',
  marginTop: SIDEBAR_MOBILE_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    marginTop: 0,
  },
}));

export function AppLayout() {
  return (
    <BreadcrumbsProvider>
      <CommandPaletteLayout />
      <LoadingBar />
      <Box display="flex">
        <Sidebar />
        <Main>
          <Outlet />
        </Main>
        <StyledToaster />
      </Box>
    </BreadcrumbsProvider>
  );
}
